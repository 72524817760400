<template>
  <div>
    <div class="lg:flex lg:mb-4 items-end">
      <div
        v-if="!isInReview"
        class="flex flex-col items-baseline lg:mr-4 flex-1"
      >
        <label class="mr-2 font-medium" for="filterByOrg"
          >{{ $t("corp") }}:</label
        >
        <el-select
          v-model="filteredCorp"
          class="min-w-64"
          filterable
          :placeholder="$t('select')"
        >
          <el-option
            v-for="(corp, i) in corpStore.corps"
            :key="'filtercorp' + i"
            :label="corp.filterDisplay"
            :value="corp.id"
          ></el-option>
        </el-select>
      </div>

      <div
        v-if="!isInReview"
        :title="$t('dateFilterSinceMouseOver')"
        class="flex flex-col lg:mr-4 flex-1"
      >
        <label class="mr-2 font-medium" for>{{ $t("dateFilterSince") }}:</label>
        <el-config-provider :locale="pickerLocale">
          <el-date-picker
            v-model="filteredDateSince"
            type="date"
            align="right"
            format="DD.MM.YYYY"
            :key="updateLangKey"
            :placeholder="$t('datePicker.pickDate')"
            :disabled-date="getDatePickerSinceDisabledDates"
            :shortcuts="getDatePickerSinceShortcuts"
          />
        </el-config-provider>
      </div>

      <div
        v-if="!isInReview"
        :title="$t('dateFilterUntilMouseOver')"
        class="flex flex-col lg:mr-4 flex-1"
      >
        <label class="mr-2 font-medium" for>{{ $t("dateFilterUntil") }}:</label>
        <el-config-provider :locale="pickerLocale">
          <el-date-picker
            v-model="filteredDateUntil"
            type="date"
            align="right"
            format="DD.MM.YYYY"
            :key="updateLangKey"
            :placeholder="$t('datePicker.pickDate')"
            :disabled-date="getDatePickerUntilDisabledDates"
            :shortcuts="getDatePickerUntilShortcuts"
            :default-value="new Date()"
          />
        </el-config-provider>
      </div>

      <!-- hide the parent toggler if we are in the main corp (schweiz) as these dont have parents -->
      <div
        v-if="
          corpStore.mainCorp &&
          corpStore.mainCorp.id !== filteredCorp &&
          !isInReview
        "
        class="flex flex-col items-baseline lg:mr-4 flex-1"
      >
        <label class="mr-2 font-medium" for="filterByOrg"
          >{{
            filteredCorpParentLevelDisplay
              ? $t(filteredCorpParentLevelDisplay)
              : ""
          }}:</label
        >
        <div style="margin-top: 10px; text-align: center">
          <el-switch v-model="showParent"></el-switch>
        </div>
      </div>
    </div>

    <el-collapse v-model="notCollapsedSections" class="filters-collapse">
      <el-collapse-item name="filters">
        <template #title>
          <div class="flex">
            <span class="text-[#808080] hover:text-black">
              <span v-if="notCollapsedSections.length">{{
                $t("showLessFilter")
              }}</span>
              <span v-else>{{ $t("showMoreFilter") }}</span>
            </span>
            <span
              v-if="hasHiddenFilterActive"
              class="flex"
              :title="$t('ActiveHiddenFilter')"
            >
              &nbsp;<SvgIcon name="warning"></SvgIcon>
            </span>
          </div>
        </template>
        <div class="lg:flex lg:mb-4 items-end">
          <div
            v-if="!isInReview"
            :title="$t('authorityFilterMouseOver')"
            class="flex flex-col lg:mr-4 flex-1"
          >
            <label class="mr-2 font-medium" for="filterByExecAuth"
              >{{ $t("authorityFilter") }}:</label
            >
            <el-select
              v-model="filteredAuthorities"
              multiple
              clearable
              filterable
              class="flex-1"
              :placeholder="$t('select')"
            >
              <el-option
                v-for="(authority, i) in authorityStore.authorityData"
                :key="'filterauthority' + i"
                :label="authority.name"
                :value="authority.id"
              ></el-option>
            </el-select>
          </div>

          <div
            v-if="!isInReview"
            :title="$t('legalBasesFilterMouseOver')"
            class="flex flex-col lg:mr-4 flex-1"
          >
            <label class="mr-2 font-medium" for="filterByLegalBases"
              >{{ $t("legalBases") }}:</label
            >
            <!-- Use custom "multiline" class and custom html for entries. Keep label to be able to search in. -->
            <el-select
              v-model="filteredLegalBases"
              multiple
              clearable
              filterable
              collapse-tags
              class="flex-1"
              :placeholder="$t('select')"
              popper-class="multiline"
            >
              <el-option
                v-for="(legalBasis, i) in legalBasisStore.legalBases"
                :key="'filterlegalBases' + i"
                :value="legalBasis.id"
                :label="`
              ${legalBasis.code}${
                  legalBasis.name ? ' - ' + legalBasis.name : ''
                }
            `"
              >
                <p>
                  {{
                    `${legalBasis.code}${
                      legalBasis.name ? " - " + legalBasis.name : ""
                    }`
                  }}
                </p>
              </el-option>
            </el-select>
          </div>

          <div
            v-if="!isInReview"
            :title="$t('accessFilterMouseOver')"
            class="flex flex-col lg:mr-4 flex-1"
          >
            <label class="mr-2 font-medium" for="filterByAccess"
              >{{ $t("access") }}:</label
            >
            <el-select
              v-model="filteredAccessLevels"
              multiple
              clearable
              filterable
              class="flex-1"
              :placeholder="$t('select')"
            >
              <el-option
                v-for="(accessLevel, i) in [
                  'public',
                  'public_restricted',
                  'private',
                ]"
                :key="'filteraccess' + i"
                :label="$t(`accessLabel.${accessLevel}`)"
                :value="accessLevel"
              ></el-option>
            </el-select>
          </div>
        </div>

        <div class="lg:flex lg:mb-4 items-end">
          <div
            v-if="!isInReview"
            :title="$t('geoCategoryFilterMouseOver')"
            class="flex flex-col lg:mr-4 flex-1"
          >
            <label class="mr-2 font-medium" for="filterByGeoCategory"
              >{{ $t("geocategory") }}:</label
            >
            <el-select
              v-model="filteredGeoCategories"
              multiple
              clearable
              filterable
              class="flex-1"
              :placeholder="$t('select')"
            >
              <el-option
                v-for="(geoCategory, i) in geoCategoryStore.geoCategoryData"
                :key="'geoCategory' + i"
                :label="` ${geoCategory.notation} - ${geoCategory.name}`"
                :value="geoCategory.id"
              ></el-option>
            </el-select>
          </div>

          <div
            v-if="!isInReview"
            class="flex flex-col items-baseline lg:mr-4 flex-1"
          >
            <label class="mr-2 font-medium" for="filterByDelegation"
              >{{ $t("filterWithDelegation") }}:</label
            >
            <el-select
              v-model="withDelegation"
              class="text-center"
              :placeholder="$t('select')"
              clearable
              filterable
              :title="$t('filterWithDelegationMouseOver')"
            >
              <el-option
                v-for="item in booleanOptions"
                :key="item.value"
                :label="$t(item.label)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div
            v-if="!isInReview"
            class="flex flex-col items-baseline lg:mr-4 flex-1"
          >
            <label class="mr-2 font-medium" for="filterOereb"
              >{{ $t("filterWithOereb") }}:</label
            >
            <el-select
              v-model="withOereb"
              class="text-center"
              :placeholder="$t('select')"
              clearable
              filterable
              :title="$t('filterWithOerebMouseOver')"
            >
              <el-option
                v-for="item in booleanOptions"
                :key="item.value"
                :label="$t(item.label)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="lg:flex items-end">
          <div
            v-if="!isInReview"
            class="flex flex-col items-baseline lg:mr-4 flex-1"
          >
            <label class="mr-2 font-medium" for="filterDownload"
              >{{ $t("filterWithDownload") }}:</label
            >
            <el-select
              v-model="withDownload"
              class="text-center"
              :placeholder="$t('select')"
              clearable
              filterable
              :title="$t('filterWithDownloadMouseOver')"
            >
              <el-option
                v-for="item in booleanOptions"
                :key="item.value"
                :label="$t(item.label)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div
            v-if="!isInReview"
            class="flex flex-col items-baseline lg:mr-4 flex-1"
          >
            <label class="mr-2 font-medium" for="filterGeoRefOnly"
              >{{ $t("filterWithGeoReference") }}:</label
            >
            <el-select
              v-model="withGeoReference"
              class="text-center"
              :placeholder="$t('select')"
              clearable
              filterable
              :title="$t('filterWithGeoReferenceMouseOver')"
            >
              <el-option
                v-for="item in booleanOptions"
                :key="item.value"
                :label="$t(item.label)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div
            v-if="!isInReview"
            class="flex flex-col items-baseline lg:mr-4 flex-1"
          >
            <el-button size="small" class="has-icon mr-2 mt-2 lg:mt-0" @click="resetAllFilters">
              <SvgIcon name="delete-forever"></SvgIcon>
              <span>{{ $t('resetFilter') }}</span>
            </el-button>
          </div>
          <!-- Placeholder to have a nice regular filter panel-->
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { sub } from "date-fns";

import {
  useParentFilter,
  useCorpFilter,
  useDateFilter,
  useAuthorityFilter,
  useLegalBasisFilter,
  useAccessLevelFilter,
  useGeoCategoryFilter,
  useDelegationFilter,
  useOerebFilter,
  useDownloadFilter,
  useGeoReferenceFilter,
  useFiltersFacade
} from "@/store/Filters";
import { useCorpStore } from "@/store/Corp";
import { useAuthorityStore } from "@/store/Authority";
import { useLegalBasisStore } from "@/store/LegalBasis";
import { useGeoCategoryStore } from "@/store/GeoCategory";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import de from "element-plus/dist/locale/de.mjs";
import fr from "element-plus/dist/locale/fr.mjs";
import it from "element-plus/dist/locale/it.mjs";

const dateFilterStore = useDateFilter();
const authorityFilterStore = useAuthorityFilter();
const legalBasisFilterStore = useLegalBasisFilter();
const accessLevelFilterStore = useAccessLevelFilter();
const geoCategoryFilterStore = useGeoCategoryFilter();
const corpFilterStore = useCorpFilter();
const corpStore = useCorpStore();
const parentFilterStore = useParentFilter();
const delegationFilterStore = useDelegationFilter();
const oerebFilterStore = useOerebFilter();
const downloadFilterStore = useDownloadFilter();
const geoReferenceFilterStore = useGeoReferenceFilter();
const authorityStore = useAuthorityStore();
const legalBasisStore = useLegalBasisStore();
const geoCategoryStore = useGeoCategoryStore();
const filtersFacadeStore = useFiltersFacade();

const route = useRoute();
const router = useRouter();
const { t, locale } = useI18n();

const notCollapsedSections = ref([]);
const booleanOptions = ref([
  { value: true, label: "yes" },
  { value: false, label: "no" },
]);

const updateLangKey = ref(0);

const pickerLocale = computed(() => {
  if (locale.value == "fr") {
    return fr;
  } else if (locale.value == "it") {
    return it;
  } else {
    return de;
  }
});

watch(locale, () => {
  updateLangKey.value++;
});

// Computed
const isInReview = computed(() => {
  return !!route.params.reviewid;
});

const getDatePickerShortcuts = computed(() => {
  const today = new Date();
  return [
    {
      text: t("datePicker.oneWeekAgo"),
      value: () => {
        return sub(today, { weeks: 1 });
      },
    },
    {
      text: t("datePicker.oneMonthAgo"),
      value: () => {
        return sub(today, { months: 1 });
      },
    },
    {
      text: t("datePicker.sixMonthsAgo"),
      value: () => {
        return sub(today, { months: 6 });
      },
    },
    {
      text: t("datePicker.oneYearAgo"),
      value: () => {
        return sub(today, { years: 1 });
      },
    },
    {
      text: t("datePicker.sixYearsAgo"),
      value: () => {
        return sub(today, { years: 6 });
      },
    },
  ];
});

const getDatePickerSinceDisabledDates = (date) => {
  const dateUntil = new Date(dateFilterStore.dateUntil);
  return date >= dateUntil;
};

const getDatePickerSinceShortcuts = computed(() => {
  return [
    {
      text: t("datePicker.clear"),
      value: () => {
        filteredDateSince.value = null;
        return null;
      },
    },
    ...getDatePickerShortcuts.value,
  ];
});

const getDatePickerUntilShortcuts = computed(() => {
  const today = new Date();
  return [
    {
      text: t("datePicker.today"),
      value: today,
    },
    ...getDatePickerShortcuts.value,
  ];
});

const getDatePickerUntilDisabledDates = (date) => {
  const today = new Date();
  return date > today;
};

const filteredDateSince = computed({
  get() {
    return dateFilterStore.dateSince;
  },
  set(value) {
    dateFilterStore.changeDate(router, { date: value, isSince: true });
  },
});

const filteredDateUntil = computed({
  get() {
    return dateFilterStore.dateUntil;
  },
  set(value) {
    dateFilterStore.changeDate(router, { date: value, isSince: false });
  },
});

const filteredAuthorities = computed({
  get() {
    return authorityFilterStore.authorityIds || [];
  },
  set(value) {
    authorityFilterStore.changeAuthorityIds(router, value);
  },
});

const filteredLegalBases = computed({
  get() {
    return legalBasisFilterStore.legalBasisIds || [];
  },
  set(value) {
    legalBasisFilterStore.changeLegalBasisIds(router, value);
  },
});

const filteredAccessLevels = computed({
  get() {
    return accessLevelFilterStore.accessLevelFilters || [];
  },
  set(value) {
    accessLevelFilterStore.changeAccessLevelFilters(router, value);
  },
});

const filteredGeoCategories = computed({
  get() {
    return geoCategoryFilterStore.geoCategoryIds || [];
  },
  set(value) {
    geoCategoryFilterStore.changeGeoCategoryIds(router, value);
  },
});

const filteredCorp = computed({
  get() {
    return corpFilterStore.corpFilter;
  },
  set(value) {
    corpFilterStore.changeCorpFilter(router, value);
  },
});

const filteredCorpParentLevelDisplay = computed(() => {
  const level = corpStore.getCorpParentLevel(corpFilterStore.corpFilter);
  if (!level) {
    return;
  }
  return `show${level}`;
});

const showParent = computed({
  get() {
    return parentFilterStore.showParent;
  },
  set(value) {
    parentFilterStore.changeShowParent(router, value);
  },
});

const withDelegation = computed({
  get() {
    return delegationFilterStore.withDelegation;
  },
  set(value) {
    delegationFilterStore.changeWithDelegation(router, value);
  },
});

const withOereb = computed({
  get() {
    return oerebFilterStore.withOereb;
  },
  set(value) {
    oerebFilterStore.changeWithOereb(router, value);
  },
});

const withDownload = computed({
  get() {
    return downloadFilterStore.withDownload;
  },
  set(value) {
    downloadFilterStore.changeWithDownload(router, value);
  },
});

const withGeoReference = computed({
  get() {
    return geoReferenceFilterStore.withGeoReference;
  },
  set(value) {
    geoReferenceFilterStore.changeWithGeoReference(router, value);
  },
});

const hasHiddenFilterActive = computed(() => {
  if (notCollapsedSections.value.length) {
    return false;
  }
  return (
    authorityFilterStore.authorityIds.length ||
    legalBasisFilterStore.legalBasisIds.length ||
    accessLevelFilterStore.accessLevelFilters.length ||
    geoCategoryFilterStore.geoCategoryIds.length ||
    delegationFilterStore.withDelegation !== undefined ||
    oerebFilterStore.withOereb !== undefined ||
    downloadFilterStore.withDownload !== undefined ||
    geoReferenceFilterStore.withGeoReference !== undefined
  );
});

const resetAllFilters = () => {
  authorityFilterStore.setAuthorityIds([])
  legalBasisFilterStore.setLegalBasisIds([])
  accessLevelFilterStore.setAccessLevelFilters([])
  geoCategoryFilterStore.setGeoCategoryIds([])
  parentFilterStore.setShowParent(false)
  delegationFilterStore.setWithDelegation(null)
  oerebFilterStore.setShowOerebOnly(null)
  downloadFilterStore.setShowDownloadOnly(null)
  geoReferenceFilterStore.setWithGeoReference(null)
  dateFilterStore.setDate({date: null, isSince: true})
  dateFilterStore.setDate({date: null, isSince: false})

  filtersFacadeStore.resetAllFilters(router)
}

// Mounted
onMounted(async () => {
  if (!corpStore.corps) {
    await corpStore.retrieveCorpList();
  }

  if (!corpStore.corps) {
    return;
  }

  if (corpStore.corps.find((c) => c.id === parseInt(route.query.corp))) {
    filteredCorp.value = parseInt(route.query.corp);
  }

  if (route.query.corpAbbr) {
    let corp = corpStore.corps.find((c) => c.abbr === route.query.corpAbbr);
    if (corp) {
      filteredCorp.value = corp.id;
    }
  }
});
</script>

<style lang="postcss" scoped>
.filters-collapse {
  border: none;
}
.filters-collapse div[role="button"] {
  min-height: 2rem;
  border: none;
  color: grey;
}
.filters-collapse div[role="button"]:hover {
  color: black;
}
</style>
