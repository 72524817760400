import api from "./api";
import { Contactperson } from "../models/Contactperson.model";

/**
 * Get JSON data to build the contact dropdown
 *
 *
 */
const getContactPersons = async () => {
  const response = await api.get("contact-person/");
  let cleanedData = response.data.map(contact => {
    return new Contactperson(contact);
  });
  return cleanedData;
};

const createNewContactPerson = async () => {
  //call legislative-entry API based on filters
  let response = await api.post(`contact-person/`).catch(error => {
    return error;
  });
  return new Contactperson(response.data);
};

const deleteContactPerson = async pk => {
  //call legislative-entry API based on filters
  return api.delete(`contact-person/${pk}/`);
};

const saveContactPerson = async (pk, data) => {
  const payload = {
    firstName: data.firstName,
    lastName: data.lastName,
    tel: data.tel,
    email: data.email,
    corp: data.corp ? data.corp.id : null,
    authority: data.authority ? data.authority.id : null
  };

  const url = `contact-person/${pk}/`;
  return api.put(url, payload);
};

export {
  getContactPersons,
  saveContactPerson,
  deleteContactPerson,
  createNewContactPerson
};
