import i18n from "@/i18n.js";
import axios from "axios";
import router from "@/router/";

import { useUserStore } from "@/store/User";
import { useLanguageStore } from '@/store/Language';

import { ElNotification } from 'element-plus';

let userStore;
let languageStore;

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL
});

/**
 * Include the JWT token in the request when the user is logged in
 */
api.interceptors.request.use(config => {
  userStore = useUserStore();
  languageStore = useLanguageStore();

  if (userStore.isLoggedIn) {
    config.headers["Authorization"] = `Bearer ${userStore.token}`;
  }

  config.headers["Accept-Language"] = languageStore.getLanguage;
  config.headers["Content-Language"] = languageStore.getLanguage;

  return config;
});

/**
 * Determine if the URL is a token reqeust
 *
 * Determine by checking if the request URL ends with "token"
 * @returns Boolean
 */
const requestIsToken = url => {
  const cleanedUrl = url.replace(/\//g, "");
  const tokenStrLen = "token".length;
  const stringEnd = cleanedUrl.substring(cleanedUrl.length - tokenStrLen);

  const isLogin = stringEnd === "token";

  return isLogin;
};

api.interceptors.response.use(
  response => response,
  error => {
    userStore = useUserStore()
    // handle 401 unauthorized errors
    if (error.response && error.response.status === 401) {
      const responseUrl = error.request.responseURL;
      userStore.doLogout();

      if (!requestIsToken(responseUrl)) {
        ElNotification.error({
          title: "Logout",
          message: i18n.global.t("loggedout")
        });
      }

      router.push({ name: "home" });
    }

    throw error;
  }
);

export default api;
