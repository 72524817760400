<template>
  <div class="max-w-2xl flex-1 mb-4">
    <div v-if="!isInReview" class="flex flex-row items-baseline">
      <el-autocomplete
        v-model="searchString"
        :fetch-suggestions="fetchSuggestions"
        :trigger-on-focus="false"
        clearable
        class="mb-4"
        value-key="id"
        :title="$t('searchHover')"
        :placeholder="$t('searchPlaceholder')"
        @clear="clearSearch"
        @select="handleSelect"
        @input="onSearchChange"
      >
        <template #default="{ item }">
          <span class="el-autocomplete-value flex text-black">
            <div v-for="substring in item.label" :key="substring.text">
              <span>
                <span v-if="substring.match">
                  <strong style="white-space: pre;">{{ substring.text }}</strong></span
                >
                <span v-else style="white-space: pre;" >{{ substring.text }}</span>
              </span>
            </div>
          </span>
          <span class="el-autocomplete-link">{{ item.link }}</span>
        </template>
      </el-autocomplete>
      <el-button
        :title="$t('searchHover')"
        size="default"
        class="ml-4 has-icon"
        @click="onSearch"
      >
        <SvgIcon name="search" />
        {{ $t("search") }}
      </el-button>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";
import { useSearchFilter } from "@/store/Filters";
import { useLegislativeCatalogStore } from "@/store/LegislativeCatalog";
import { useI18n } from "vue-i18n";

const searchFilterStore = useSearchFilter()
const legislativeCatalogStore = useLegislativeCatalogStore();

const route = useRoute();
const router = useRouter();
const { locale } = useI18n();
const isInReview = computed(() => (route.params.reviewid ? true : false));

const searchString = computed({
  get() {
    return searchFilterStore.searchInputValue
  },
  // setter
  set(value) {
    searchFilterStore.setSearchInputValue(value);
  }
})

const onSearch = () => {
  searchFilterStore.changeSearchFilter(router, { value: searchString.value });
};

const clearSearch = () => {
  searchString.value = "";
  if (searchString.value !== searchFilterStore.searchFilter) {
    searchFilterStore.changeSearchFilter(router, { value: "" });
  }
};

const onSearchChange = () => {
  if (!searchString.value) {
    clearSearch();
  }
};

const fetchSuggestions = async (queryString, callback) => {
  searchFilterStore.setSearchInputValue(queryString)
  // Retrieve max. 50 suggestions
  let suggestions = await legislativeCatalogStore.retrieveSearchSuggestions(
    searchFilterStore.searchInputValue,
    50
  );
  suggestions = markSearchStringInResults(
    searchFilterStore.searchInputValue,
    suggestions
  );
  callback(suggestions);
};

const handleSelect = (item) => {
  // Clear search bar and navigate to detail view
  searchString.value = "";
  router.push({
    name: "detail",
    params: { legislativeEntryId: item.id },
    query: { lang: locale.value }
  });
};

const markSearchStringInResults = (queryString, suggestions) => {
  // Find which parts of the result matches the search string, in order to display them in bold
  const regexp = new RegExp(queryString.replace(" ", "|"), "ig");
  return suggestions.map((suggestion) => {
    const label = [];
    let start = 0;
    [...suggestion.value.matchAll(regexp)].map((match) => {
      label.push({
        text: suggestion.value.substring(start, match.index),
        match: false,
      });
      start = match.index;
      label.push({
        text: suggestion.value.substr(start, match[0].length),
        match: true,
      });
      start += match[0].length;
    });
    if (start < suggestion.value.length) {
      label.push({
        text: suggestion.value.substring(start),
        match: false,
      });
    }
    suggestion.label = label;
    return suggestion;
  });
};
</script>
