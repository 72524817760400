export function serialize(technicalEntry) {
  let data = {
    geoCategory: technicalEntry.geoCategoryId,
    customFields: technicalEntry.customFields,
    customFieldValues: technicalEntry.customFields.map(field => {
      if (field.type === "date") {
        let dateObject = {};
        dateObject.id = field.values.id || null;
        dateObject.customFieldId = field.id;
        dateObject.value = field.values.value || null;
        dateObject.valueDe = field.values.valueDe || null;
        dateObject.valueFr = field.values.valueFr || null;
        dateObject.valueIt = field.values.valueIt || null;
        dateObject.valueRm = field.values.valueRm || null;
        return dateObject;
      }
      return field.values;
    }),
    description: technicalEntry.description,
    descriptionDe: technicalEntry.descriptionDe,
    descriptionFr: technicalEntry.descriptionFr,
    descriptionIt: technicalEntry.descriptionIt,
    dataModelUrlLabel: technicalEntry.dataModelUrlLabel,
    dataModelUrlLabelDe: technicalEntry.dataModelUrlLabelDe,
    dataModelUrlLabelFr: technicalEntry.dataModelUrlLabelFr,
    dataModelUrlLabelIt: technicalEntry.dataModelUrlLabelIt,
    dataModelUrl: technicalEntry.dataModelUrl,
    dataModelUrlDe: technicalEntry.dataModelUrlDe,
    dataModelUrlFr: technicalEntry.dataModelUrlFr,
    dataModelUrlIt: technicalEntry.dataModelUrlIt,
    dataModelChanges: technicalEntry.dataModelChanges,
    dataModelChangesDe: technicalEntry.dataModelChangesDe,
    dataModelChangesFr: technicalEntry.dataModelChangesFr,
    dataModelChangesIt: technicalEntry.dataModelChangesIt,
    modelDocumentationUrlLabel: technicalEntry.modelDocumentationUrlLabel,
    modelDocumentationUrlLabelDe: technicalEntry.modelDocumentationUrlLabelDe,
    modelDocumentationUrlLabelFr: technicalEntry.modelDocumentationUrlLabelFr,
    modelDocumentationUrlLabelIt: technicalEntry.modelDocumentationUrlLabelIt,
    modelDocumentationUrl: technicalEntry.modelDocumentationUrl,
    modelDocumentationUrlDe: technicalEntry.modelDocumentationUrlDe,
    modelDocumentationUrlFr: technicalEntry.modelDocumentationUrlFr,
    modelDocumentationUrlIt: technicalEntry.modelDocumentationUrlIt,
    contactPerson: technicalEntry.contactPersonId,
    metadataUrls: technicalEntry.metadataUrls
  };

  return data;
}
