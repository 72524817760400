/*----------------------------------------*\
  Filters
\*----------------------------------------*/
import {
  asFilterObject,
  filterValidArrayFilterValues,
  parseCommaSeparatedValues,
  updateUrlParams
} from "./utils";
import { UrlParamKey } from "../utils";
import { isBoolean } from "lodash";

import { defineStore } from "pinia";
import { useAuthorityStore } from "./Authority";
import { useLegalBasisStore } from "./LegalBasis";
import { useLegislativeCatalogStore } from "./LegislativeCatalog";


let authStore;
let legalBasisStore;
let legislativeCatalogStore;
let filtersFacade;

export const useCorpFilter = defineStore('CorpFilter', {
  state: () => {
    return {
      corpFilter: null
    }
  },

  actions: {
    setCorpFilter(corpId) {
      this.corpFilter = corpId;
    },
    async changeCorpFilter(router, corpId, replaceUrl = true) {
      authStore = useAuthorityStore();
      legalBasisStore = useLegalBasisStore();
      legislativeCatalogStore = useLegislativeCatalogStore();
      filtersFacade = useFiltersFacade();

      this.setCorpFilter(corpId);
      await authStore.retrieveAuthorityData();
      await legalBasisStore.retrieveLegalBasisList();
      if (replaceUrl) {
        filtersFacade.updateUrl(router);
      }
      legislativeCatalogStore.retrieveLegislativeList();
    }
  }
});

const today = new Date();
export const useDateFilter = defineStore('DateFilter', {
  state: () => {
    return {
      dateSince: null, // start date, published since
      dateUntil: today // end date, published until
    };
  },

  actions: {
    setDate({ date, isSince: isSince }) {
      if (isSince) {
        this.dateSince = date && date < this.dateUntil ? date : null;
      } else {
        this.dateUntil = date && date < today ? date : today;
        if (this.dateSince >= this.dateUntil) {
          this.dateSince = null;
        }
      }
    },
    changeDateSilent(payload) {
      this.setDate(payload);
    },
    async changeDate(router, payload) {
      legislativeCatalogStore = useLegislativeCatalogStore();
      filtersFacade = useFiltersFacade();

      this.setDate(payload);
      filtersFacade.updateUrl(router);
      await legislativeCatalogStore.retrieveLegislativeList();
    }
  }
});

export const useAuthorityFilter = defineStore('AuthorityFilter', {
  state: () => {
    return {
      authorityIds: []
    }
  },

  actions: {
    setAuthorityIds(authorityIds) {
      this.authorityIds = authorityIds;
    },
    async changeAuthorityIds(router, authorityIds) {
      legislativeCatalogStore = useLegislativeCatalogStore();
      filtersFacade = useFiltersFacade();

      this.setAuthorityIds(authorityIds);
      filtersFacade.updateUrl(router);
      await legislativeCatalogStore.retrieveLegislativeList();
    },
    assertIsValid(data) {
      filterValidArrayFilterValues(
        this,
        "changeAuthorityIds",
        this.authorityIds,
        data
      );
    }
  }
});

export const useLegalBasisFilter = defineStore('LegalBasisFilter', {

  state: () => {
    return {
      legalBasisIds: []
    };
  },

  actions: {
    setLegalBasisIds(legalBasisIds) {
      this.legalBasisIds = legalBasisIds;
    },
    async changeLegalBasisIds(router, legalBasisIds) {
      legislativeCatalogStore = useLegislativeCatalogStore();
      filtersFacade = useFiltersFacade();

      this.setLegalBasisIds(legalBasisIds);
      filtersFacade.updateUrl(router);
      await legislativeCatalogStore.retrieveLegislativeList();
    },
    assertIsValid(data) {
      filterValidArrayFilterValues(
        this,
        "changeLegalBasisIds",
        this.legalBasisIds,
        data
      );
    }
  }
});

export const useAccessLevelFilter = defineStore('AccessLevelFilter', {

  state: () => {
    return {
      accessLevelFilters: []
    };
  },

  actions: {
    setAccessLevelFilters(accessLevelFilters) {
      this.accessLevelFilters = accessLevelFilters;
    },
    async changeAccessLevelFilters(router, accessLevelIds) {
      legislativeCatalogStore = useLegislativeCatalogStore();
      filtersFacade = useFiltersFacade();

      this.setAccessLevelFilters(accessLevelIds);
      filtersFacade.updateUrl(router);
      await legislativeCatalogStore.retrieveLegislativeList();
    }
    // Not possible to assert the validity of static values.
  }
});

export const useGeoCategoryFilter = defineStore('GeoCategoryFilter', {
  state: () => {
    return {
      geoCategoryIds: []
    };
  },

  actions: {
    setGeoCategoryIds(geoCategoryIds) {
      this.geoCategoryIds = geoCategoryIds;
    },
    async changeGeoCategoryIds(router, geoCategoryIds) {
      legislativeCatalogStore = useLegislativeCatalogStore();
      filtersFacade = useFiltersFacade();

      this.setGeoCategoryIds(geoCategoryIds);
      filtersFacade.updateUrl(router);
      await legislativeCatalogStore.retrieveLegislativeList();
    },
    assertIsValid(data) {
      filterValidArrayFilterValues(
        this,
        "changeGeoCategoryIds",
        this.geoCategoryIds,
        data
      );
    }
  }
});

export const useParentFilter = defineStore('ParentFiler', {
  state: () => {
    return {
      showParent: false
    };
  },

  actions: {
    setShowParent(value) {
      this.showParent = value;
    },
    async changeShowParent(router, value) {
      legislativeCatalogStore = useLegislativeCatalogStore();
      filtersFacade = useFiltersFacade();

      this.setShowParent(value);
      filtersFacade.updateUrl(router);
      await legislativeCatalogStore.retrieveLegislativeList();
    }
  }
});

export const useDelegationFilter = defineStore('DelegationFilter', {
  state: () => {
    return {
      withDelegation: undefined
    }
  },

  actions: {
    setWithDelegation(value) {
      if (!isBoolean(value)) {
        value = undefined;
      }
      this.withDelegation = value;
    },
    async changeWithDelegation(router, value) {
      legislativeCatalogStore = useLegislativeCatalogStore();
      filtersFacade = useFiltersFacade();

      this.setWithDelegation(value);
      filtersFacade.updateUrl(router);
      await legislativeCatalogStore.retrieveLegislativeList();
    }
  }
});

export const useOerebFilter = defineStore('OerebFilter', {
  state: () => {
    return {
      withOereb: undefined
    };
  },

  actions: {
    setShowOerebOnly(value) {
      if (!isBoolean(value)) {
        value = undefined;
      }
      this.withOereb = value;
    },
    async changeWithOereb(router, value) {
      legislativeCatalogStore = useLegislativeCatalogStore();
      filtersFacade = useFiltersFacade();

      this.setShowOerebOnly(value);
      filtersFacade.updateUrl(router);
      await legislativeCatalogStore.retrieveLegislativeList();
    }
  }
});

export const useDownloadFilter = defineStore('DownloadFilter', {
  state: () => {
    return {
      withDownload: undefined
    };
  },

  actions: {
    setShowDownloadOnly(value) {
      if (!isBoolean(value)) {
        value = undefined;
      }
      this.withDownload = value;
    },
    async changeWithDownload(router, value) {
      legislativeCatalogStore = useLegislativeCatalogStore();
      filtersFacade = useFiltersFacade();

      this.setShowDownloadOnly(value);
      filtersFacade.updateUrl(router);
      await legislativeCatalogStore.retrieveLegislativeList();
    }
  }
});

export const useGeoReferenceFilter = defineStore('GeoReferenceFilter', {
  state: () => {
    return {
      withGeoReference: undefined
    };
  },

  actions: {
    setWithGeoReference(value) {
      if (!isBoolean(value)) {
        value = undefined;
      }
      this.withGeoReference = value;
    },
    async changeWithGeoReference(router, value) {
      legislativeCatalogStore = useLegislativeCatalogStore();
      filtersFacade = useFiltersFacade();

      this.setWithGeoReference(value);
      filtersFacade.updateUrl(router);
      await legislativeCatalogStore.retrieveLegislativeList();
    }
  }
});

export const useSearchFilter = defineStore('SearchFilter', {
  state: () => {
    return {
      searchFilter: "",
      searchInputValue: ""
    }
  },

  actions: {
    setSearchFilter(value) {
      this.searchFilter = value;
    },
    setSearchInputValue(value) {
      this.searchInputValue = value;
    },
    changeSearchInputValue(value) {
      this.setSearchInputValue(value);
    },
    async changeSearchFilter(router, { value, loading = true }) {
      legislativeCatalogStore = useLegislativeCatalogStore();
      filtersFacade = useFiltersFacade();

      this.setSearchFilter(value);
      filtersFacade.updateUrl(router);
      if (loading) {
        await legislativeCatalogStore.retrieveLegislativeList();
      }
    }
  }
});

/**
 * Special filter that have no state but allows to get common filters values.
 */
export const useFiltersFacade = defineStore('FiltersFacade', {
  actions: {
    async updateUrl(router) {
      await updateUrlParams(router, this.filtersValues);
    },
    async resetAllFilters(router) {
      legislativeCatalogStore = useLegislativeCatalogStore();
      
      this.updateUrl(router)
      await legislativeCatalogStore.retrieveLegislativeList();
    },
    updateFiltersFromUrl(route) {
      const searchFilterStore = useSearchFilter();
      const corpFilterStore = useCorpFilter()
      const dateFilterStore = useDateFilter()
      const authorityFilterStore = useAuthorityFilter()
      const legalBasisFilterStore = useLegalBasisFilter()
      const accessLevelFilterStore = useAccessLevelFilter()
      const geoCategoryFilterStore = useGeoCategoryFilter();
      const parentFilterStore = useParentFilter()
      const delegationFilterStore = useDelegationFilter()
      const oerebFilterStore = useOerebFilter()
      const downloadFilterStore = useDownloadFilter()
      const geoReferenceFilterStore = useGeoReferenceFilter()

      const params = route.query;
      const searchText = params[UrlParamKey.Search];
      const corpId = parseFloat(params[UrlParamKey.Corp]);
      const dateSince = new Date(params[UrlParamKey.DateSince]);
      const dateUntil = new Date(params[UrlParamKey.DateUntil]);
      const authorityIds = parseCommaSeparatedValues(
        params[UrlParamKey.Authority]
      );
      const legalBasisIds = parseCommaSeparatedValues(
        params[UrlParamKey.LegalBases]
      );
      const accessLevels = parseCommaSeparatedValues(
        params[UrlParamKey.AccessLevel]
      );
      const geoCategoryIds = parseCommaSeparatedValues(
        params[UrlParamKey.GeoCategory]
      );
      const showParent = params[UrlParamKey.Parent];
      const withDelegation = params[UrlParamKey.Delegation];
      const withOereb = params[UrlParamKey.Oereb];
      const withDownload = params[UrlParamKey.Download];
      const withGeoReference = params[UrlParamKey.GeoReference];

      if (searchText) {
        searchFilterStore.searchFilter = searchText;
      }
      if (corpId) {
        corpFilterStore.corpFilter = corpId;
      }
      if (dateSince) {
        dateFilterStore.changeDateSilent({ date: dateSince, isSince: true });
      }
      if (dateUntil) {
        dateFilterStore.changeDateSilent({ date: dateUntil, isSince: false });
      }
      if (authorityIds) {
        authorityFilterStore.authorityIds = authorityIds;
      }
      if (legalBasisIds) {
        legalBasisFilterStore.legalBasisIds = legalBasisIds;
      }
      if (accessLevels) {
        accessLevelFilterStore.accessLevelFilters = accessLevels;
      }
      if (geoCategoryIds) {
        geoCategoryFilterStore.geoCategoryIds = geoCategoryIds;
      }
      if (showParent !== undefined) {
        parentFilterStore.showParent = showParent === "true";
      }
      if (withDelegation !== undefined) {
        delegationFilterStore.withDelegation = withDelegation === "true";
      }
      if (withOereb !== undefined) {
        oerebFilterStore.withOereb = withOereb === "true";
      }
      if (withDownload !== undefined) {
        downloadFilterStore.withDownload = withDownload === "true";
      }
      if (withGeoReference !== undefined) {
        geoReferenceFilterStore.withGeoReference = withGeoReference === "true";
      }
    }
  },
  getters: {
    /**
     * @returns {object} a filter object with value coming from the given rootState.
     */
    filtersValues: () => {
      const searchFilterStore = useSearchFilter();
      const corpFilterStore = useCorpFilter()
      const dateFilterStore = useDateFilter()
      const authorityFilterStore = useAuthorityFilter()
      const legalBasisFilterStore = useLegalBasisFilter()
      const accessLevelFilterStore = useAccessLevelFilter()
      const geoCategoryFilterStore = useGeoCategoryFilter();
      const parentFilterStore = useParentFilter()
      const delegationFilterStore = useDelegationFilter()
      const oerebFilterStore = useOerebFilter()
      const downloadFilterStore = useDownloadFilter()
      const geoReferenceFilterStore = useGeoReferenceFilter()

      return asFilterObject(
        searchFilterStore.searchFilter,
        corpFilterStore.corpFilter,
        dateFilterStore.dateSince,
        dateFilterStore.dateUntil,
        authorityFilterStore.authorityIds,
        legalBasisFilterStore.legalBasisIds,
        accessLevelFilterStore.accessLevelFilters,
        geoCategoryFilterStore.geoCategoryIds,
        parentFilterStore.showParent,
        delegationFilterStore.withDelegation,
        oerebFilterStore.withOereb,
        downloadFilterStore.withDownload,
        geoReferenceFilterStore.withGeoReference,
      );
    }
  }
});
