import api from "./api";
import { Authority } from "@/models/Authority.model";

import { UrlParamKey } from "../utils";

/**
 * Get JSON data about the Authorities, based on the corp
 */
const getAuthorityData = async corpId => {
  let params = {};
  if (corpId) {
    params[UrlParamKey.Corp] = corpId;
  }

  const response = await api.get("authority/", {
    params: params
  });

  return response.data.map(authorityEntry => {
    return new Authority(authorityEntry);
  });
};

const createAuthorityEntry = async () => {
  //call legislative-entry API based on filters
  let response = await api.post(`authority/`).catch(error => {
    return error;
  });
  return new Authority(response.data);
};

const saveAuthorityEntry = async (pk, data) => {
  const payload = {
    name: data.name || "",
    nameDe: data.nameDe,
    nameFr: data.nameFr,
    nameIt: data.nameIt,
    abbr: data.abbr,
    abbrDe: data.abbrDe,
    abbrFr: data.abbrFr,
    abbrIt: data.abbrIt,
    url: data.url,
    urlDe: data.urlDe,
    urlFr: data.urlFr,
    urlIt: data.urlIt
  };
  const url = `authority/${pk}/`;
  return api.put(url, payload);
};

const deleteAuthorityEntry = async pk => {
  const url = `authority/${pk}/`;
  return api.delete(url);
};

export {
  getAuthorityData,
  createAuthorityEntry,
  saveAuthorityEntry,
  deleteAuthorityEntry
};
