import { formatToStandardTextDate } from "./services/utils";
/**
 * Routes parameter's keys to have a consistent naming through the application and views
 */
export const UrlParamKey = {
  Newest: "newest",
  DateSince: "date_since",
  DateUntil: "date_until",
  Search: "search",
  Corp: "legislative_corp",
  Authority: "authority",
  LegalBases: "legal_bases",
  AccessLevel: "access",
  GeoCategory: "geo_category",
  Parent: "parent",
  Delegation: "delegation",
  Oereb: "oereb",
  Download: "download_service",
  GeoReference: "geo_reference",
  MaxEntries: "max_entries"
};

/**
 * Build params from filters.
 * The searchText is combined with other filters
 */
export const filtersToParams = (filters = {}, params = {}) => {
  if (filters.searchText) {
    params[UrlParamKey.Search] = filters.searchText;
  }
  if (filters.corpId) {
    params[UrlParamKey.Corp] = filters.corpId;
  }
  if (filters.newest !== undefined) {
    params[UrlParamKey.Newest] = filters.newest;
  }
  if (filters.dateSince) {
    params[UrlParamKey.DateSince] = formatToStandardTextDate(filters.dateSince);
  }
  if (filters.dateUntil) {
    params[UrlParamKey.DateUntil] = formatToStandardTextDate(filters.dateUntil);
  }
  if (filters.authorityIds && filters.authorityIds.length) {
    params[UrlParamKey.Authority] = filters.authorityIds.toString();
  }
  if (filters.legalBasisIds && filters.legalBasisIds.length) {
    params[UrlParamKey.LegalBases] = filters.legalBasisIds.toString();
  }
  if (filters.accessLevels && filters.accessLevels.length) {
    params[UrlParamKey.AccessLevel] = filters.accessLevels.join(",");
  }
  if (filters.geoCategoryIds && filters.geoCategoryIds.length) {
    params[UrlParamKey.GeoCategory] = filters.geoCategoryIds.toString();
  }
  if (filters.showParent === true) {
    params[UrlParamKey.Parent] = filters.showParent;
  }
  if (filters.withDelegation !== undefined) {
    params[UrlParamKey.Delegation] = filters.withDelegation;
  }
  if (filters.withOereb !== undefined) {
    params[UrlParamKey.Oereb] = filters.withOereb;
  }
  if (filters.withDownload !== undefined) {
    params[UrlParamKey.Download] = filters.withDownload;
  }
  if (filters.withGeoReference !== undefined) {
    params[UrlParamKey.GeoReference] = filters.withGeoReference;
  }
  return params;
};
