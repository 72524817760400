<template>
  <div class="">
    <div class="catalog-grid catalog-grid--technical-entries">
      <div>
        <div class="border-gray-400 seperator-x">
          <catalog-attribute :title="$t('metaData')">
            <div
              v-if="item.metadataUrls.length"
              :href="`${
                item['modelDocumentationUrl' + languageUppercase] ||
                item.modelDocumentationUrl
              }`"
            >
              <a
                v-for="(metaDataUrl, i) in item.metadataUrls"
                :key="'metadata' + i"
                class="block"
                :title="metaDataUrl"
                :href="`${
                  metaDataUrl['url' + languageUppercase] || metaDataUrl.url
                }`"
              >
                {{
                  metaDataUrl["urlLabel" + languageUppercase] ||
                  metaDataUrl.urlLabel ||
                  $t("link")
                }}
              </a>
            </div>
            <div v-else>&mdash;</div>
          </catalog-attribute>
        </div>
      </div>

      <div>
        <div class="border-gray-400 seperator-x">
          <catalog-attribute :title="$t('dataModel')">
            <span
              v-if="item['dataModelUrl' + languageUppercase]"
              class="inline-flex items-center"
            >
              <a
                :title="item['dataModelUrl' + languageUppercase]"
                :href="item['dataModelUrl' + languageUppercase]"
              >
                {{
                  item["dataModelUrlLabel" + languageUppercase] ||
                  item.dataModelUrlLabel ||
                  $t("link")
                }}
              </a>
              <el-tooltip
                v-if="item.dataModelChanges"
                :content="
                  item['dataModelChanges' + languageUppercase] ||
                  item.dataModelChanges
                "
                placement="right"
                effect="light"
                class="ml-2"
              >
                <button>
                  <SvgIcon name="info" />
                </button>
              </el-tooltip>
            </span>
            <span v-else>&mdash;</span>
          </catalog-attribute>
        </div>
      </div>

      <div>
        <div class="border-gray-400 seperator-x">
          <catalog-attribute :title="$t('modelDocumentation')">
            <div class="flex">
              <span class="inline-block">
                <a
                  v-if="item['modelDocumentationUrl' + languageUppercase]"
                  :title="item['modelDocumentationUrl' + languageUppercase]"
                  :href="item['modelDocumentationUrl' + languageUppercase]"
                >
                  {{
                    item["modelDocumentationUrlLabel" + languageUppercase] ||
                    item.modelDocumentationUrlLabel ||
                    $t("link")
                  }}
                </a>
                <span v-else>&mdash;</span>
              </span>
            </div>
          </catalog-attribute>
        </div>
      </div>
      <div>
        <div class="border-gray-400 seperator-x">
          <catalog-attribute
            v-if="item.geoCategory.name"
            :title="$t('geocategory')"
          >
            {{ item.geoCategory.notation }} -
            {{
              item.geoCategory["name" + languageUppercase] ||
              item.geoCategory.name
            }}
          </catalog-attribute>
          <catalog-attribute v-else :title="$t('geocategory')">
            -
          </catalog-attribute>
        </div>
      </div>
      <div></div>
      <catalog-attribute
        v-for="(field, customFieldIndex) in item.customFields"
        :key="'customField' + customFieldIndex"
        :title="field['label' + languageUppercase] || field.label"
        class="mt-0"
      >
        <div v-if="field.type === 'date'" class="flex">
          <span class="inline-block">
            {{ formatDateToDDMMYYYY(field.values.value) }}
          </span>
        </div>
        <div v-else-if="field.type !== 'bool'" class="flex">
          <span class="inline-block">
            {{
              field.values["value" + languageUppercase] || field.values.value
            }}
          </span>
        </div>
        <div v-else class="flex">
          <span v-if="field.values.value === 'False'" class="inline-block">
            {{ $t("no") }}
          </span>
          <span v-if="field.values.value === 'True'" class="inline-block">
            {{ $t("yes") }}
          </span>
          <span v-else class="inline-block"> - </span>
        </div>
      </catalog-attribute>

      <catalog-attribute
        v-if="item.startDate"
        :title="$t('publicationDate')"
        class="mt-0"
      >
        <div class="flex">
          <SvgIcon name="calendar"></SvgIcon>
          <span class="inline-block ml-2">{{
            formatDateToDDMMYYYY(item.startDate)
          }}</span>
        </div>
      </catalog-attribute>

      <catalog-attribute
        v-if="item.isHistorised"
        :title="$t('endDate')"
        class="mt-0"
      >
        <div v-if="item.endDate" class="flex">
          <SvgIcon name="calendar"></SvgIcon>
          <span class="inline-block ml-2">{{
            formatDateToDDMMYYYY(item.endDate)
          }}</span>
        </div>
        <span v-else>&mdash;</span>
      </catalog-attribute>
    </div>
    <slot></slot>
  </div>
</template>

<script setup>
import CatalogAttribute from "@/components/catalog/CatalogAttribute.vue";
import { computed } from "vue";
import { useLanguageStore } from "@/store/Language";
import { formatDateToDDMMYYYY } from "@/composables/customFormatDate.js";

const languageStore = useLanguageStore();

defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const languageUppercase = computed(() => {
  return (
    languageStore.language.charAt(0).toUpperCase() +
    languageStore.language.slice(1)
  );
});
</script>
