import { serializerLegalBases } from "./LegalEntry";

export function serialize(data) {
  let serialized_data = {};
  serialized_data.labelNumber = data.labelNumber;
  serialized_data.title = data.title;
  serialized_data.titleDe = data.titleDe;
  serialized_data.titleFr = data.titleFr;
  serialized_data.titleIt = data.titleIt;
  serialized_data.editLanguage = data.editLanguage;
  serialized_data.downloadService = data.downloadService;
  serialized_data.customFields = data.customFields;
  serialized_data.customFieldValues = data.customFields.map(field => {
    if (field.type === "date") {
      let dateObject = {};
      dateObject.id = field.values.id || null;
      dateObject.customFieldId = field.id;
      dateObject.value = field.values.value || null;
      dateObject.valueDe = field.values.valueDe || null;
      dateObject.valueFr = field.values.valueFr || null;
      dateObject.valueIt = field.values.valueIt || null;
      dateObject.valueRm = field.values.valueRm || null;
      return dateObject;
    }
    return field.values;
  });
  // only send ID's
  serialized_data.executiveAuthorities = data.executiveAuthorities.map(data => {
    return data.id;
  });
  // only send ID's
  serialized_data.legislativeAuthorities = data.legislativeAuthorities.map(
    data => {
      return data.id;
    }
  );
  serialized_data.geoReference = data.geoReference;
  serialized_data.access = data.access;
  serialized_data.oereb = data.oereb;
  serialized_data.delegation = data.delegation || "none";
  serialized_data.legalBases = serializerLegalBases(data.legalBases);
  return serialized_data;
}
