import { format, isValid, parse } from "date-fns";
import { isEqual } from "lodash";

const getCookieByName = name => {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) {
    return parts
      .pop()
      .split(";")
      .shift();
  }
};

/**
 * @param {string}  value a text-date like 'yyyy-MM-dd'
 * @return {Date} a new Date.
 */
const parseDateFromServer = value => {
  return new Date(value);
};

/**
 * @param {Date} value
 * @returns {string} the date under the format yyyy-MM-dd
 */
const formatToStandardTextDate = value => {
  if (!isValid(value)) {
    let formatted = parse(value, "dd.mm.yyyy", new Date());
    return format(formatted, "yyyy-MM-dd");
  };
  return format(value, "yyyy-MM-dd");
};

/**
 * @param {unknown} value a date or date-text value
 * @returns {string} the date under the format dd.MM.yyyy
 */
const formatToDDMMYYYY = value => {
  // if value is a valid date just format and return
  if (value instanceof Date) {
    return format(value, "dd.MM.yyyy");
  }
  // return value when it is empty or most likely matches our date format
  if (!value || value.split(".").length === 3) {
    return value;
  }
  // format value to date if it isnt yet
  if (!(value instanceof Date)) {
    value = new Date(value);
  }
  return format(value, "dd.MM.yyyy");
};

const hasEndDate = data => {
  return data.endDate !== null;
};

const isPublished = data => {
  return data.status.toLowerCase() === "published";
};

const isDraft = data => {
  return data.status.toLowerCase() === "draft";
};

const canBeRenumbered = data => {
  if (!isDraft(data)) {
    return false;
  }

  if (data.parent !== null || data.isParent || data.historyReference) {
    return false;
  }

  return !(data.delegation !== null && data.delegation !== "none");
};

const isFeedback = data => {
  return data.status.toLowerCase() === "feedback";
};

const isEqualEditObject = (obj1, obj2) => {
  // create copies of the objects so we can remove unneeded data in our check
  let object1 = Object.assign({}, obj1);
  let object2 = Object.assign({}, obj2);
  // delete unused state properties on the objects -> isEditing
  delete object1.isEditing;
  delete object2.isEditing;
  // compare and return boolean
  return isEqual(object1, object2);
};

const buildLinkedDeletionError = (err, linkedDeletionError) => {
  let errormessage = err
    .map(el => (el.identifier || el.id) + " - " + (el.title || el.description))
    .join("\n\n");
  let error = { response: {} };
  error.response.data = linkedDeletionError + "\n\n" + errormessage;
  return error;
};

export {
  getCookieByName,
  parseDateFromServer,
  formatToStandardTextDate,
  formatToDDMMYYYY,
  isPublished,
  hasEndDate,
  canBeRenumbered,
  isDraft,
  isFeedback,
  isEqualEditObject,
  buildLinkedDeletionError
};
