<template>
  <transition name="modal-fade">
    <div
      ref="modal"
      class="modal-overlay fixed bg-gray-750"
      tabindex="0"
      @click="$emit('close-modal')"
      @keydown.esc="$emit('close-modal')"
    >
      <div
        class="modal w-screen overflow-y-auto relative bg-white rounded p-4 m-8"
        @click.stop
      >
        <div
          class="close text-gray-500 text-xl absolute top-4 right-0 pr-4 cursor-pointer hover:text-black"
          @click="$emit('close-modal')"
        >
          <span>x</span>
        </div>
        <slot name="main"></slot>
      </div>
    </div>
  </transition>
</template>
<script setup>
import { onUpdated, ref } from "vue";
const props = defineProps({
  /** Set me to true to focus the modal next time the component is updated */
  mustFocus: {
    type: Boolean,
  },
});

const modal = ref(null);

defineEmits(["close-modal"])

onUpdated(() => {
  if (props.mustFocus) {
    modal.value.focus();
  } else {
    // Give back the focus to the parent
    modal.value.parentElement.focus();
  }
});
</script>
<style scoped lang="postcss">
.modal-overlay {
  z-index: var(--z-index-site-notification);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.modal {
  height: fit-content;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.25s ease;
}
</style>
