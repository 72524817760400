import { defineStore } from "pinia";
import { getAuthorityData } from "@/services/authority.js";
import { useLoadingStore } from "./Loading";
import { useCorpFilter, useAuthorityFilter } from "./Filters";

export const useAuthorityStore = defineStore('Authority', {
  state: () => {
    return {
      authorityData: null
    }
  },
  actions: {
    setAuthorityData(authorityData) {
      this.authorityData = authorityData;
    },
    async retrieveAuthorityData() {
      const loadingStore = useLoadingStore();
      const corpFilterStore = useCorpFilter();
      const authorityFilterStore = useAuthorityFilter();

      loadingStore.setLoading(true);
      const authorityData = await getAuthorityData(
        corpFilterStore.corpFilter
      );
      this.setAuthorityData(authorityData);
      authorityFilterStore.assertIsValid(authorityData);
      loadingStore.setLoading(false);
    }
  }
});
