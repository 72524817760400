import { formatToDDMMYYYY } from "@/services/utils";
// MODEL
/**
 * catalog base model
 * @returns {object} JSON objects containing
 *  id {number}
 *  code {string}
 *  name {string}
 *  url {string}
 *  corpId {string}
 *  abbr {string}
 *  startDate {date}
 *  endDate {date}
 */
export function LegalBase(legalBaseEntry) {
  this.isEditing = false;
  this.id = legalBaseEntry.id || null;
  this.legislativeEntryLegalBasisId =
    legalBaseEntry.legislativeEntryLegalBasisId || null;
  this.code = legalBaseEntry.code || null;
  this.codeIt = legalBaseEntry.codeIt || null;
  this.codeFr = legalBaseEntry.codeFr || null;
  this.codeDe = legalBaseEntry.codeDe || null;
  this.codeRm = legalBaseEntry.codeRm || null;
  this.example = legalBaseEntry.example || null;
  this.exampleIt = legalBaseEntry.exampleIt || null;
  this.exampleFr = legalBaseEntry.exampleFr || null;
  this.exampleDe = legalBaseEntry.exampleDe || null;
  this.exampleRm = legalBaseEntry.exampleRm || null;
  this.name = legalBaseEntry.name || null;
  this.nameIt = legalBaseEntry.nameIt || null;
  this.nameFr = legalBaseEntry.nameFr || null;
  this.nameDe = legalBaseEntry.nameDe || null;
  this.nameRm = legalBaseEntry.nameRm || null;
  this.url = legalBaseEntry.url || null;
  this.urlIt = legalBaseEntry.urlIt || null;
  this.urlFr = legalBaseEntry.urlFr || null;
  this.urlDe = legalBaseEntry.urlDe || null;
  this.urlRm = legalBaseEntry.urlRm || null;
  this.corpId = legalBaseEntry.corp ? legalBaseEntry.corp.id : null;
  this.corp = legalBaseEntry.corp || null;
  this.abbr = legalBaseEntry.abbr || null;
  this.abbrIt = legalBaseEntry.abbrIt || null;
  this.abbrFr = legalBaseEntry.abbrFr || null;
  this.abbrDe = legalBaseEntry.abbrDe || null;
  this.abbrRm = legalBaseEntry.abbrRm || null;
  this.startDate = formatToDDMMYYYY(legalBaseEntry.startDate) || null;
  this.endDate = formatToDDMMYYYY(legalBaseEntry.endDate) || null;
}
