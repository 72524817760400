import { defineStore } from "pinia";
import {
  getLegislativeList,
  getReviewEntry,
  getDetailEntry,
  getDetailEntryById,
  getDetailPublishedParentEntryByChildId,
  createNewLegalEntry
} from "@/services/legislative-entry.js";
import { serialize as serializeTechnicalEntry } from "@/serializers/TechnicalEntry.js";
import { LegislativeEntry } from "@/models/LegislativeEntry.model";
import {
  createNewTechnicalEntry,
  publishTechnicalRecord,
  updateTechnicalRecord
} from "@/services/technical-entry.js";
import {
  publishRecord,
  updateRecord,
  splitRecord,
  getSearchSuggestions
} from "../services/legislative-entry";
import { serialize } from "../serializers/LegislativeEntry";
import { TechnicalEntry } from "@/models/TechnicalEntry.model";
import { isNil } from "lodash";
import { useLoadingStore } from "./Loading";
import { useFiltersFacade } from "./Filters";

let loadingStore;
let filterFacadeStore;

export const useLegislativeCatalogStore = defineStore('LegislativeCatalog', {
  state: () => {
    return {
      mustExpand: [],
      mustCollapse: [],
      legislativeList: [],
      unpackedListWithParents: [],
      detailedParentEntries: [],
      addCatalogRecordDisabled: false,
      reviewId: null,
      newest: false,
      corpBfs: null,
      legislativeEntryId: null,
      detailId: null,
      entryAndPublishedParentComparison: null
    };
  },

  actions: {
    mustExpandList(legislativeId) {
      this.mustExpand = [...this.mustExpand, legislativeId];
    },
    mustCollapse(legislativeId) {
      this.mustCollapse = [...this.mustCollapse, legislativeId];
    },
    clearMustExpand() {
      this.mustExpand = [];
    },
    clearMustCollapse() {
      this.mustCollapse = [];
    },
    clearLegislativeList() {
      this.legislativeList = [];
      this.unpackedListWithParents = [];
    },
    setLegislativeList(legislativeList) {
      loadingStore = useLoadingStore();
      this.legislativeList = legislativeList;
      this.unpackedListWithParents = [];
      if (legislativeList && legislativeList.length) {
        this.updateUnpackedListWithParents();
        loadingStore.setLoading(false);
      }
    },
    addDetailedParentEntry(detailedParentEntry) {
      if (
        !this.detailedParentEntries.find(
          entry => entry.id === detailedParentEntry
        )
      ) {
        this.detailedParentEntries = [
          ...this.detailedParentEntries,
          detailedParentEntry
        ];
      }
    },
    removeDetailedParentEntry(detailedParentEntry) {
      const index = this.detailedParentEntries.findIndex(
        id => id === detailedParentEntry.id
      );
      if (index > -1) {
        this.detailedParentEntries.splice(index, 1);
      }
    },
    updateUnpackedListWithParents() {
      const unpackedListWithParents = [];
      this.legislativeList.forEach(row => {
        if (row.parent) {
          let parentElem = row.parent;
          parentElem.isParent = true;
          // only add parents which are not already in the list, so if the order
          // is parent -> published -> parent -> draft, the second parent is skipped
          if (!unpackedListWithParents.find(e => e.id === row.parent.id)) {
            unpackedListWithParents.push(new LegislativeEntry(parentElem));
          }
        }
        unpackedListWithParents.push(new LegislativeEntry(row));
      });
      // Keep detailed parent entry as details are maybe loaded.
      // Don't do that with children because the entry could have changed.
      this.unpackedListWithParents = unpackedListWithParents.map(entry => {
        const previousDetailedEntry = this.detailedParentEntries.find(
          detailEntry => detailEntry.id === entry.id
        );
        return previousDetailedEntry ? previousDetailedEntry : entry;
      });
    },
    switchLegislativeEntry(entry) {
      let replacementIndex = this.legislativeList.findIndex(
        el => el.id === entry.id
      );

      this.legislativeList.splice(replacementIndex, 1, entry);

      // if there is a published version of our entry, we need to also update the
      // technical entries there, as they are always identical
      let publishedIndex = this.legislativeList.findIndex(
        el => el.identifier === entry.identifier && el.status === "Published"
      );
      if (publishedIndex === -1) {
        return;
      }
      this.legislativeList[publishedIndex].technicalEntries =
        entry.technicalEntries;
    },
    removeLegislativeEntry(entryId) {
      let replacementIndex = this.legislativeList.findIndex(
        el => el.id === entryId
      );
      this.legislativeList.splice(replacementIndex, 1);
    },
    addPublishedLegalRowEntry(entry) {
      // if there is a published version of our entry, we need to remove it, as
      // it is now archived, due to the old draft being published
      let publishedIndex = this.legislativeList.findIndex(
        el => el.identifier === entry.identifier && el.status === "Published"
      );
      if (publishedIndex > -1) {
        this.legislativeList.splice(publishedIndex, 1);
      }

      // replace the published entry with itself, so the status is updated
      let replacementIndex = this.legislativeList.findIndex(
        el => el.id === entry.id
      );
      this.legislativeList.splice(replacementIndex, 1, entry);
    },
    addDraftLegalRowEntry(entry) {
      // if there is a published version of our entry, we need to remove it, as
      // it is now archived, due to the old draft being published
      let publishedIndex = this.legislativeList.findIndex(
        el => el.identifier === entry.identifier && el.status === "Published"
      );
      if (publishedIndex > -1) {
        this.legislativeList.splice(publishedIndex + 1, 0, entry);
      }
    },
    setReviewId(reviewId) {
      this.reviewId = reviewId;
    },
    setCorpBfs(corpBfs) {
      this.corpBfs = corpBfs;
    },
    setDetailId(detailId) {
      this.detailId = detailId;
    },
    setNewest(newest) {
      this.newest = newest;
    },
    clearReviewId() {
      this.reviewId = null;
    },
    clearNewest() {
      this.newest = false;
    },
    setLegislativeEntryId(legislativeEntryId) {
      this.legislativeEntryId = legislativeEntryId;
    },
    clearLegislativeEntryId() {
      this.legislativeEntryId = null;
    },
    clearCorpBfs() {
      this.corpBfs = null;
    },
    clearDetailId() {
      this.detailId = null;
    },
    addCatalogRecordToLegislativeList(catalogElement) {
      // add the new element to the catalog
      catalogElement.isEditing = true;
      this.legislativeList.push(catalogElement);

      // scroll to bottom of page where the new item is (if supported: scroll smoothly)
      if ("scroll-behavior" in document.documentElement.style) {
        window.scrollTo({
          top: document.body.scrollHeight,
          left: 0,
          behavior: "smooth"
        });
      } else {
        window.scrollTo(0, document.body.scrollHeight);
      }
      this.addCatalogRecordDisabled = false;
    },
    setAddCatalogRecordDisabled(payload) {
      this.addCatalogRecordDisabled = payload;
    },
    async addTechnicalEntryRow(payload) {
      // get the affected row
      const updatedRowId = payload.legRowId;
      const legislativeEntries = this.unpackedListWithParents;
      const updatedRow = legislativeEntries.find(
        item => item.id === updatedRowId
      );
      // push our object into the affected row
      const newTechnicalEntry = payload.technicalEntry;
      newTechnicalEntry.description = updatedRow.title;
      newTechnicalEntry.descriptionDe = updatedRow.titleDe;
      newTechnicalEntry.descriptionFr = updatedRow.titleFr;
      newTechnicalEntry.descriptionIt = updatedRow.titleIt;

      const record = await updateTechnicalRecord(
        newTechnicalEntry.id,
        serializeTechnicalEntry(newTechnicalEntry)
      );
      updatedRow.technicalEntries.push(new TechnicalEntry(record.data));
    },
    async retrieveLegislativeList() {
      loadingStore = useLoadingStore();
      filterFacadeStore = useFiltersFacade();

      loadingStore.setLoading(true);
      this.clearLegislativeList();
      let legislativeList = [];
      try {
        if (this.legislativeEntryId) {
          // In detail route we get a one item list.
          legislativeList = await getDetailEntryById(
            this.legislativeEntryId,
            this.newest,
          );
        } else if (
          this.corpBfs && this.detailId
        ) {
          // In detail/corp mode we only get a one-item list.
          legislativeList = await getDetailEntry(
            this.corpBfs,
            this.detailId
          );
        } else if (this.reviewId) {
          // In review route we only get the item needed.
          const result = await getReviewEntry(
            this.reviewId
          );
          legislativeList.push(result);
        } else {
          // In standard (catalogue) route we request the catalogue
          // items regarding the filters.
          legislativeList = await getLegislativeList(
            filterFacadeStore.filtersValues
          );
        }
        this.setLegislativeList(legislativeList);
      } finally {
        loadingStore.setLoading(false);
      }

      /*  catch (error) {
        throw error;
      } */

    },
    async retrieveSearchSuggestions(maxEntries) {
      filterFacadeStore = useFiltersFacade();

      return await getSearchSuggestions(
        filterFacadeStore.filtersValues,
        maxEntries
      );
    },

    async addCatalogRecord(catalogId) {
      const legalEntry = await createNewLegalEntry(catalogId);
      this.addCatalogRecordToLegislativeList(legalEntry);
      this.updateUnpackedListWithParents();
      return legalEntry;
    },

    async addTechnicalEntry(legRowId) {
      let technicalEntry = await createNewTechnicalEntry(legRowId);
      this.addTechnicalEntryRow(
        {
          technicalEntry: technicalEntry,
          legRowId: legRowId
        });
    },

    clearCatalog() {
      this.clearLegislativeList();
    },

    async publishRecord({ pk, startDate }) {
      return publishRecord(pk, startDate);
    },

    async publishTechnicalRecord({ id, payload }) {
      await publishTechnicalRecord(id, payload);
    },

    saveRecord(data) {
      const id = data.id;
      return updateRecord(id, serialize(data));
    },

    splitRecord(data) {
      const id = data.id;
      return splitRecord(id);
    },
    replaceLegalRow(payload) {
      // leave editmode when we saved and are about to replace the row
      if (payload.isEditing) {
        payload.isEditing = false;
      }
      this.switchLegislativeEntry(payload)
      this.updateUnpackedListWithParents();
    },
    removeLegalRow(entryId) {
      this.removeLegislativeEntry(entryId);
      this.updateUnpackedListWithParents();
    },
    addPublishedLegalRow(entry) {
      this.addPublishedLegalRowEntry(entry);
      this.updateUnpackedListWithParents();
    },
    addDraftLegalRow(entry) {
      this.addDraftLegalRowEntry(entry);
      this.updateUnpackedListWithParents();
    },
    async saveTechnicalRecord({ id, data, allLang }) {
      return updateTechnicalRecord(id, data, allLang);
    },
    async setPublishedParentToCompare(childLegislativeEntry) {
      let parentEntry = null;
      if (!isNil(childLegislativeEntry)) {
        parentEntry = await getDetailPublishedParentEntryByChildId(
          childLegislativeEntry.id
        );
      }
      this.entryAndPublishedParentComparison = parentEntry ? [childLegislativeEntry, parentEntry] : null;
    }
  }
});
