import api from "./api";
import { Catalog } from "@/models/Catalog.model";
/**
 * Get JSON data to build the legislative-entry table
 * @param {string} corpFilters string with the id of the corp we want to filter
 * @param {string} authorityFilter string with the id of the departement we want to filter
 *
 * @returns {object} array of json objects
 *
 */
const getCatalogList = async () => {
  //call legislative-entry API based on filters
  const response = await api.get(`catalog/`);
  // map the used data and discard unneeded data
  let cleanedList = response.data.map(catalogEntry => {
    let cleanedCatalogEntry = new Catalog(catalogEntry);
    return cleanedCatalogEntry;
  });
  return cleanedList;
};

export { getCatalogList };
