import api from "./api";

/**
 * Get JSON data to build the contacts view
 *
 * @returns {object} JSON objects containing
 * firstName {string}
 * lastName {string}
 * email {string}
 * phone {string}
 * corp {string}
 * authority {string}
 */
const getContactData = async () => {
  // params
  let params = {};
  //call contact
  const response = await api.get(`contact/`, {
    params: params
  });
  // map the used data and discard unneeded data
  let cleanedData = response.data.map((contact, index) => {
    let cleanedEntry = {};
    //
    cleanedEntry.id = index;
    cleanedEntry.firstName = contact.firstName;
    cleanedEntry.lastName = contact.lastName;
    cleanedEntry.email = contact.email;
    cleanedEntry.corpDisplay = contact.corpDisplay;
    cleanedEntry.phone = contact.phone;
    cleanedEntry.corp = contact.corp || "";
    cleanedEntry.authority = contact.authority || "";
    return cleanedEntry;
  });
  return cleanedData;
};

export { getContactData };
