import { filtersToParams } from "../utils";
import i18n from "@/i18n.js";

/**
 * @param {string} searchText the search text.
 * @param {string} corpId the id of the corp we want to filter.
 * @param {Date} dateSince the start date we want to filter.
 * @param {Date} dateUntil the end date we want to filter.
 * @param {Array<number>} authorityIds the ids of the departments we want to filter.
 * @param {Array<number>} legalBasisIds the ids of the legal bases we want to filter.
 * @param {Array<string>} accessLevels the texts of the access levels we want to filter.
 * @param {Array<number>} geoCategoryIds the ids of the geo-category we want to filter.
 * @param {boolean} showParent With or without parents.
 * @param {boolean} withDelegation With or with and without delegations.
 * @param {boolean} withOereb With or with and without oereb.
 * @param {boolean} withDownload With or with and without download_service.
 * @param {boolean} withGeoReference With or with and without geo_reference.
 * @return {object} with filters key-values.
 */
export const asFilterObject = (
  searchText,
  corpId,
  dateSince,
  dateUntil,
  authorityIds,
  legalBasisIds,
  accessLevels,
  geoCategoryIds,
  showParent,
  withDelegation,
  withOereb,
  withDownload,
  withGeoReference
) => {
  return {
    searchText,
    corpId,
    dateSince,
    dateUntil,
    authorityIds,
    legalBasisIds,
    accessLevels,
    geoCategoryIds,
    showParent,
    withDelegation,
    withOereb,
    withDownload,
    withGeoReference
  };
};

/**
 * Update the url from a filterObject (see asFilterObject).
 */
export const updateUrlParams = async (router, filterObject) => {
  const objQuery = {
    ...filtersToParams(filterObject),
    lang: i18n.global.locale.value
  }
  await router.replace({ query: objQuery });
};

/**
 * @param val a string representing list of comma separated values.
 * @returns {(string|number)[]|string} f.i. ["a",2] from a string value like "'a',2" or
 * the original value if the parsing was not possible.
 */
export const parseCommaSeparatedValues = val => {
  if (typeof val !== "string") {
    return val;
  }
  return val.split(",").map(item => {
    const parsed = parseFloat(item);
    return isNaN(parsed) ? item : parsed;
  });
};

/**
 * Validate filter state ids regarding new data.
 * Dispatch the given action name (in the caller context, not in root) with
 * an empty array if the given data is nullish or with a new state with only
 * the existing ids if the given filter ids are different of the
 * existing ids in the provided data.
 */
export const filterValidArrayFilterValues = (
  store,
  actionName,
  currentFilterIds,
  data
) => {
  if (!data) {
    store[actionName]([]);
    return;
  }
  const existingIds = [...currentFilterIds].filter(id =>
    data.some(item => item.id === id)
  );

  if (existingIds.length !== currentFilterIds.length) {
    store[actionName](existingIds);
  }
};
