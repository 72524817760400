import api from "./api";
import { LegislativeEntryLight } from "@/models/LegislativeEntryLight.model";
import { LegislativeEntry } from "@/models/LegislativeEntry.model";
import { LegislativeEntrySearchSuggestion } from "@/models/LegislativeEntrySearchSuggestion.model";

import { filtersToParams, UrlParamKey } from "../utils";
import { parseDateFromServer, formatToStandardTextDate } from "./utils";

/**
 * Get JSON data to build the legislative-entry table
 * @param {object} filters object to build params with.
 * @returns {object} array of json objects
 *
 */
const getLegislativeList = async filters => {
  const response = await requestLegislativeEntries(filters);

  // map the used data and discard unneeded data
  return response.data.map(legislativeEntry => {
    return new LegislativeEntryLight(legislativeEntry);
  });
};

/**
 * Get JSON data to build the detail view of a legislative entry
 * @param {number} id legislative identifier.
 * @param {object} filters object to build params with.
 * @returns {object} array of json objects
 *
 */
const getLegislativeDetail = async (id, filters) => {
  // conditional params only if they exist
  const params = filtersToParams(filters);
  const response = await api.get(`legislative-entry/${id}/`, {
    params
  });
  return new LegislativeEntry(response.data);
};

/**
 * Get JSON data to build the legislative-entry table containign only one element that will be reviewed
 * @param {string} reviewId string with the id of the entry we want to review
 *
 * @returns {object} array of json objects
 *
 */
const getReviewEntry = async reviewId => {
  // Call legislative-entry API based on filters
  const response = await api.get(`legislative-entry/${reviewId}/`, {});
  return new LegislativeEntryLight(response.data);
};
/**
 * Get JSON data to build the legislative-entry table containign only one element in its detail
 * @param {string} corpBFS string with the id of the corps in which the entry we want to review exists
 * @param {string} detailId string with the id of the entry we want to review
 *
 * @returns {object} array of json objects
 *
 */
const getDetailEntry = async (corpBFS, detailId) => {
  if (!corpBFS || !detailId) return [];
  let entries = [];
  const isCorpInt = !isNaN(corpBFS);
  let bfsLabel = "corp_bfs_abbr";
  //call legislative-entry API based on filters
  if (isCorpInt) {
    bfsLabel = "corp_bfs_number";
  }
  // prettier-ignore
  const response = await api.get(`data/?identifier=${detailId.split(".")[0]}&${bfsLabel}=${corpBFS}&parent=true`,{});
  let entry = new LegislativeEntry(response.data[0]);
  if (entry.parent) {
    let parent = await api.get(`legislative-entry/${entry.parent.id}/`, {});
    if (parent.data.parent) {
      let parentObject = new LegislativeEntryLight(parent.data.parent);
      parentObject.isGrandParent = true;
      entries.push(parentObject);
    }
  }
  entries.push(entry);
  return entries;
};
/**
 * Get JSON data to build the legislative-entry table containing only one element in its detail
 * @param {string} legislativeEntryId string with the id of the entry in which the entry we want to review exists
 * @param {boolean} newest boolean which controls if we want the newest version
 *
 * @returns {object} array of json objects
 *
 */
const getDetailEntryById = async (legislativeEntryId, newest = false) => {
  let entries = [];
  //call legislative-entry API based on filters
  const response = await api.get(
    `legislative-entry/${legislativeEntryId}/?${UrlParamKey.Newest}=${newest}`,
    {}
  );
  let entry = new LegislativeEntry(response.data);
  if (entry.parent) {
    let parent = await api.get(`legislative-entry/${entry.parent.id}/`, {});
    if (parent.data.parent) {
      let parentObject = new LegislativeEntryLight(parent.data.parent);
      parentObject.isGrandParent = true;
      entries.push(parentObject);
    }
  }
  entries.push(entry);
  return entries;
};

/** @returns the published parent id of the given legislative entry */
const getDetailPublishedParentEntryByChildId = async legislativeEntryId => {
  const response = await api
    .get(
      `legislative-entry/${legislativeEntryId}/retrieve_published_parent/`,
      {}
    )
    .catch(error => {
      console.error(error);
      return null;
    });
  return response ? new LegislativeEntry(response.data) : null;
};

/**
 * set legislative entry to review
 * @param {object} row object to set into review
 * @returns {object} json object of the row
 */
const setReviewState = async row => {
  // Call legislative-entry API based on filters
  return api.put(`legislative-entry/${row.id}/`, {
    status: "feedback"
  });
};

/**
 * set legislative entry to draft
 * @param {object} row object to set into review
 * @returns {object} json object of the row
 */
const setDraftState = async row => {
  // Call legislative-entry API based on filters
  return api.put(`legislative-entry/${row.id}/`, {
    status: "draft"
  });
};

/**
 * post to api to suspend a legal entry
 */
const suspendLegalEntry = async (rowId, suspendDate) => {
  if (!rowId) {
    return;
  }
  const data = {
    endDate: formatToStandardTextDate(parseDateFromServer(suspendDate))
  };
  return api.put(`legislative-entry/suspend/${rowId}/`, data);
};

const deleteLegalEntry = async rowId => {
  if (!rowId) {
    return;
  }
  return api.put(`legislative-entry/delete/${rowId}/`);
};

/**
 * post to api to create a new legal entry
 * @param {string} catalogId string with the id of the catalog we want to create it in
 * @returns {object} json object
 *
 */
const createNewLegalEntry = async catalogId => {
  // Call legislative-entry API based on filters
  const response = await api
    .post(`legislative-entry/`, {
      catalog: catalogId || 1
    })
    .catch(error => {
      return error;
    });
  return new LegislativeEntry(response.data);
};

const publishRecord = async (pk, startDate) => {
  const data = {
    startDate: formatToStandardTextDate(parseDateFromServer(startDate))
  };
  return api.put(`legislative-entry/publish/${pk}/`, data);
};

const splitRecord = async id => {
  await api.put(`legislative-entry/split/${id}/`);
};

const updateRecord = async (pk, data) => {
  return api.put(`legislative-entry/${pk}/`, data);
};

const getExportableLegalEntryList = async (filters, type) => {
  const params = filtersToParams(filters);
  const response = await api.get(`legislative-entry/export-${type}`, {
    params,
    responseType: "blob"
  });
  const url = window.URL.createObjectURL(
    new Blob([response.data], {
      type: "text/plain; charset=Windows-1252",
      encoding: "Windows-1252"
    })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `export_geobasisdatench.${type}`);
  document.body.appendChild(link);
  link.click();
};

/**
 * Get JSON data to build the autocomplete suggestions for the search bar
 * @param {object} filters object to build params with.
 * @returns {object} array of json objects
 *
 */
const getSearchSuggestions = async (filters, searchInputValue, maxEntries) => {
  filters.searchText = searchInputValue;
  const response = await requestLegislativeEntries(filters, maxEntries);
  // Do not keep search filter in current URL
  filters.searchText = "";
  // Map the used data
  return response.data.map(legislativeEntry => {
    return new LegislativeEntrySearchSuggestion(legislativeEntry);
  });
};

/**
 * Get API response for endpoint /legislative-entry/
 * @param {object} filters object to build params with.
 * @returns {Promise<AxiosResponse<any>>} server response
 *
 */
const requestLegislativeEntries = async (filters, maxEntries) => {
  // Conditional params only if matching filters exist
  const params = filtersToParams(filters);
  if (maxEntries) {
    params[UrlParamKey.MaxEntries] = maxEntries;
  }

  //call legislative-entry API based on filters
  return await api.get(`legislative-entry/`, {
    params
  });
};

export {
  getLegislativeList,
  publishRecord,
  createNewLegalEntry,
  suspendLegalEntry,
  getReviewEntry,
  getDetailEntry,
  getDetailEntryById,
  getDetailPublishedParentEntryByChildId,
  deleteLegalEntry,
  splitRecord,
  setReviewState,
  setDraftState,
  updateRecord,
  getExportableLegalEntryList,
  getLegislativeDetail,
  getSearchSuggestions
};
