// MODEL
/**
 * Geocategory base model
 * @returns {object} JSON objects containing
 *  id {number}
 *  name {string}
 *  shortName {string}
 *
 */
export function Geocategory(geocategory) {
  if (!geocategory) return null;
  this.id = geocategory.id || null;
  this.name = geocategory.name || null;
  this.nameIt = geocategory.nameIt || null;
  this.nameFr = geocategory.nameFr || null;
  this.nameDe = geocategory.nameDe || null;
  this.nameRm = geocategory.nameRm || null;
  this.notation = geocategory.notation || null;
  this.shortName = geocategory.shortName || null;
}
