<template>
  <footer class="site-footer bg-gray-300 pt-12 pb-4">
    <div class="container">
      <div class="site-footer__layout">
        <div class="mb-8">
          <router-link class="block mt-8 hidden" to="/impressum">
            {{ $t("impressum") }}
          </router-link>
          <a :href="privacyPolicyUrl">{{ $t("privacyPolicy") }}</a>
          <p class="mt-8">All rights reserved {{ currentYear }}</p>
        </div>
        <div class="mb-8">
          <ul>
            <li>
              <strong> {{ $t("operationCentralKGK") }}</strong>
            </li>
            <li>Haus der Kantone</li>
            <li>Speichergasse 6</li>
            <li>Postfach</li>
            <li>CH-3001 Bern</li>
            <li class="mt-4">Tel. +41 31 300 09 20</li>
            <li><a href="mailto:info@kgk-cgc.ch">info@kgk-cgc.ch</a></li>
          </ul>
        </div>
        <div>
          <ul>
            <li>
              <strong>{{ $t("ministeryOfTopography") }}</strong>
            </li>
            <li>swisstopo</li>
            <li>Seftigenstrasse 264</li>
            <li>Postfach</li>
            <li>CH-3084 Wabern</li>
            <li class="mt-4">Tel. +41 58 469 01 11</li>
            <li><a href="mailto:info@swisstopo.ch">info@swisstopo.ch</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();

const currentYear = computed(() => new Date().getFullYear());

const privacyPolicyUrl = computed(() => {
  let lang = locale.value;
  let domain = window.location.hostname + "/";
  let protocol = window.location.protocol;
  return protocol + "//docs." + domain + lang + "/privacy_policy/policy.html";
});
</script>
<style scoped lang="postcss">
a {
  @apply font-bold text-black;
}

@screen sm {
  .site-footer__layout {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
</style>
