<template>
  <Default>
    <div class="container">
      <div class="flex items-start justify-between">
        <catalog-search-box v-if="!route.params.reviewid" />
        <div class="flex items-start">
          <el-dropdown trigger="click" @command="getExportData">
            <el-button size="default" class="mr-2 has-icon">
              <SvgIcon name="vertical-align-bottom" />
              <span>{{ $t("exportAction") }}</span>
              <SvgIcon name="arrow-down" class="el-icon--right !mr-0" />
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="xlsx">{{
                  $t("exportXlsx")
                }}</el-dropdown-item>
                <el-dropdown-item v-if="userStore.isLoggedIn" command="docx">{{
                  $t("exportDocx")
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-dropdown
            v-if="editModeIsEnabled && catalogListStore.catalogList.length > 1"
            :disabled="!isToday(dateFilter.dateUntil)"
            :title="addRecordDisabledText"
            split-button
            size="default"
            trigger="click"
            class="mr-2"
            @click="
              handleAddCatalogRecordClick(catalogListStore.catalogList[0].id)
            "
            @command="handleAddCatalogRecordClick"
          >
            <span class="flex items-center">
              <SvgIcon name="add-circle" class="mr-2 w-auto h-auto" />
              {{ $t("addLegalCatalogRecord") }}
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="(catalog, i) in catalogListStore.catalogList"
                  :key="'catalog' + i"
                  :command="catalog.id"
                  >{{ catalog.name || "-" }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-button
            v-if="
              editModeIsEnabled && catalogListStore.catalogList.length === 1
            "
            :disabled="!isToday(dateFilter.dateUntil)"
            :title="addRecordDisabledText"
            size="default"
            class="mr-2"
            @click="
              handleAddCatalogRecordClick(catalogListStore.catalogList[0].id)
            "
          >
            <SvgIcon name="add-circle" class="mr-2 w-auto h-auto" />
            {{ $t("addLegalCatalogRecord") }}
          </el-button>

          <el-dropdown
            trigger="click"
            :title="$t('followRssFeed')"
            @command="handleRssFeedClick"
          >
            <el-button size="default" class="!py-5 px-4 is-icon">
              <SvgIcon name="rss" class="pl-1" />
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="(feed, i) in rssFeeds"
                  :key="'feed' + i"
                  :command="feed.url"
                  :title="$t('copyRssFeedUrl')"
                  class="flex justify-start"
                  ><SvgIcon name="copy-link" class="p-1 mt-1 mr-2" />{{
                    feed.name || "-"
                  }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <div class="flex items-end items-baseline justify-between">
        <catalog-filters
          :key="
            userStore.isLoggedIn
              ? 'logged-in-filterkey'
              : 'logged-out-filterkey'
          "
          class="w-full"
        />

        <div class="flex-col items-end hidden mt-4 md:flex lg:flex-row">
          <take-over-toggler class="ml-2 min-w-64" />
        </div>
      </div>

      <catalog-table
        :key="
          route.params.reviewid
            ? `${route.params.reviewid}-table-key`
            : 'default-table-key'
        "
        class="mt-6"
      />
    </div>
    <modal
      v-show="modalShown !== null"
      :must-focus="modalShown !== null"
      @close-modal="closeModal"
    >
      <template #main>
        <differences-from-parent
          v-if="modalShown === modalComponents.DifferencesFromParent"
        ></differences-from-parent>
      </template>
    </modal>
  </Default>
</template>

<script setup>
import Default from "@/layouts/Default.vue";
import CatalogSearchBox from "@/components/catalog/CatalogSearchBox.vue";
import CatalogFilters from "@/components/catalog/CatalogFilters.vue";
import TakeOverToggler from "@/components/TakeOverToggler.vue";
import CatalogTable from "@/components/catalog/CatalogTable.vue";
import Modal from "@/components/Modal.vue";
import DifferencesFromParent from "@/components/catalog/DifferencesFromParent.vue";
import { ElMessageBox, ElNotification } from "element-plus";
import { useUserStore } from "@/store/User";
import { useCatalogListStore } from "@/store/CatalogList";
import { useLegislativeCatalogStore } from "@/store/LegislativeCatalog";
import { useLanguageStore } from "@/store/Language";
import { useCorpStore } from "@/store/Corp";
import { useSearchFilter } from "@/store/Filters";
import { useGeoCategoryStore } from "@/store/GeoCategory";
import { getExportableLegalEntryList } from "@/services/legislative-entry";
import { computed, onMounted, ref } from "vue";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useFiltersFacade } from "@/store/Filters";
import { useDateFilter } from "@/store/Filters";
import { useCorpFilter } from "@/store/Filters";
import { isToday } from "date-fns";
import { notifyError } from "@/composables/notification.js";

const MODAL_COMPONENTS = {
  DifferencesFromParent: "DifferencesFromParent",
};

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const catalogListStore = useCatalogListStore();
const legislativeCatalogStore = useLegislativeCatalogStore();
const languageStore = useLanguageStore();
const corpStore = useCorpStore();
const filtersFacade = useFiltersFacade();
const dateFilter = useDateFilter();
const corpFilter = useCorpFilter();
const searchFilterStore = useSearchFilter();
const geoCategoryStore = useGeoCategoryStore();

const { t } = useI18n();
const modalComponents = ref(MODAL_COMPONENTS);

const feedBaseURL = ref(
  new URL(import.meta.env.VITE_API_URL.replace(/\/$/, ""))
);

const editModeIsEnabled = computed(() => {
  return (
    catalogListStore.catalogList &&
    userStore.isLoggedIn &&
    !route.params.reviewid &&
    userStore.crudCorpsIds.includes(corpFilter.corpFilter)
  );
});

const addRecordDisabledText = computed(() =>
  !isToday(dateFilter.dateUntil) ? t("disabledAddRecordText") : ""
);

const modalShown = computed(() => {
  if (legislativeCatalogStore.entryAndPublishedParentComparison !== null) {
    return modalComponents.value.DifferencesFromParent;
  }
  return null;
});

const rssFeeds = computed(() => getI18nRssFeeds());

const handleAddCatalogRecordClick = async (catalogId) => {
  if (!legislativeCatalogStore.addCatalogRecordDisabled) {
    try {
      await legislativeCatalogStore.setAddCatalogRecordDisabled(true);
      const newEntry = await legislativeCatalogStore.addCatalogRecord(
        catalogId
      );
      legislativeCatalogStore.mustExpandList(newEntry.id);
    } catch (error) {
      notifyError(error);
      await legislativeCatalogStore.setAddCatalogRecordDisabled(false);
    }
  }
};

const getExportData = async (type) => {
  ElNotification({
    title: t("downloadStarted"),
    message: t("downloadStartedMsg"),
  });
  await getExportableLegalEntryList(filtersFacade.filtersValues, type);
  ElNotification({
    title: t("downloadEnded"),
  });
};

const closeModal = () => {
  if (modalShown.value === modalComponents.value.DifferencesFromParent) {
    return legislativeCatalogStore.setPublishedParentToCompare(null);
  }
};

const handleRssFeedClick = async (link) => {
  if (!window.isSecureContext) {
    console.error("Can't copy link on http. Link is: ", link);
    return;
  }
  await navigator.clipboard.writeText(link);
  ElNotification({
    title: t("rssFeedUrlCopied"),
    message: link,
  });
};
const getI18nRssFeeds = () => {
  return getRssFeeds().map((feed) => {
    feed.url.searchParams.append("lang", languageStore.language);
    return feed;
  });
};

const getRssFeeds = () => {
  let rssFeeds = [];
  // Add the general catalog link
  const mainCatalogUrl = new URL(
    feedBaseURL.value.pathname + "/feed/catalog/rss",
    feedBaseURL.value
  );
  rssFeeds.push({
    name: t("catalog"),
    url: mainCatalogUrl,
  });
  if (!corpStore.corps || !corpFilter.corpFilter) {
    return rssFeeds;
  }
  // Add link for current corp
  const corpName = corpStore.corps.find(
    (corp) => corp.id === corpFilter.corpFilter
  ).name;
  const corpCatalogUrl = new URL(
    feedBaseURL.value.pathname + `/feed/${corpFilter.corpFilter}/rss`,
    feedBaseURL.value
  );
  rssFeeds.push({
    name: corpName,
    url: corpCatalogUrl,
  });
  return rssFeeds;
};

onMounted(async () => {
  if (!userStore.loggingIn && !userStore.isLoggedIn) {
    try {
      // Await the end of this call because there is relevant corp data in
      // it for the following requests
      await corpStore.retrieveCorpList();
      // Set main corp if the user is not authenticated. This is
      // crucial to ensure that the following requests fetch the
      // correct data.
      await corpFilter.changeCorpFilter(
        router,
        corpFilter.corpFilter || corpStore.mainCorp.id
      );
      await geoCategoryStore.retrieveGeoCategoryData();
    } catch (error) {
      notifyError(error);
    }
  }
});

onBeforeRouteLeave((to, from, next) => {
  if (searchFilterStore.searchFilter !== "") {
    searchFilterStore.changeSearchFilter(router, "");
  }
  if (catalogListStore.unsavedChanges.length > 0) {
    ElMessageBox.confirm(t("warningEdit"), t("warning"), {
      confirmButtonText: t("ok"),
      cancelButtonText: t("cancel"),
      type: "warning",
    })
      .then(() => {
        catalogListStore.clearUnsavedChanges();
        next();
      })
      .catch(() => {
        next(false);
      });
  } else {
    next();
  }
});
</script>
