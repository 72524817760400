import { Geocategory } from "./Geocategory.model";
import { Contactperson } from "./Contactperson.model";
import { CustomField } from "./CustomField.model";
import { cloneDeep } from "lodash";
// MODEL
/**
 * technical entry base model
 * @returns {object} JSON objects containing
 *  id {num}
 *  geoCategory {object}
 *  personInCharge {object}
 *  description {string}
 *  dataModelUrl {string}
 *  dataModelChanges {string}
 *  modelDocumentationUrl {string}
 *  startDate {string}
 *  endDate {string}
 *  metadataUrls {array}
 *  status {string}
 */
export function TechnicalEntry(responseData) {
  const clonedOriginal = cloneDeep(responseData);
  this.hideLabel = responseData.hideLabel || false;
  if (responseData) {
    this.isHistorised = responseData.isHistorised || false;
    if (this.isHistorised && responseData.histoData) {
      this.histoData = responseData.histoData;
    }
    this.isEditing = false;
    this.id = responseData.id || null;
    if (responseData.customFields && responseData.customFieldValues) {
      this.customFields = responseData.customFields.map(field => {
        let value = responseData.customFieldValues.find(
          d => d.customFieldId === field.id
        ) || {
          id: null,
          customFieldId: field.id,
          value: "",
          valueDe: "",
          valueFr: "",
          valueIt: "",
          valueRm: ""
        };
        field.values = value;
        return new CustomField(field);
      });
    } else {
      this.customFields = [];
      this.customFieldValues = [];
    }
    this.label = responseData.label || ""; // 7>A.1
    this.labelNumber = responseData.labelNumber || null; // 1
    const labelParts = this.label.split(">");
    this.partialLabel = labelParts[0].split(".")[0]; // 7
    this.labelDelegationAndNumber = // >A.1
      labelParts.length > 1 ? `>${labelParts[1]}` : `.${this.labelNumber}`;
    this.geoCategory = new Geocategory(responseData.geoCategory) || null;
    this.geoCategoryId = this.geoCategory.id || null;
    this.contactPerson = responseData.contactPerson
      ? new Contactperson(responseData.contactPerson)
      : null;
    this.contactPersonId = responseData.contactPerson
      ? responseData.contactPerson.id
      : null;
    this.personInCharge = responseData.personInCharge
      ? new Contactperson(responseData.personInCharge)
      : null;
    this.description = responseData.description || "";
    this.descriptionDe = responseData.descriptionDe || "";
    this.descriptionFr = responseData.descriptionFr || "";
    this.descriptionIt = responseData.descriptionIt || "";

    this.dataModelUrlLabel = responseData.dataModelUrlLabel || "";
    this.dataModelUrlLabelDe = responseData.dataModelUrlLabelDe || "";
    this.dataModelUrlLabelFr = responseData.dataModelUrlLabelFr || "";
    this.dataModelUrlLabelIt = responseData.dataModelUrlLabelIt || "";

    this.historyReference = responseData.historyReference || null;
    this.oldReplacedDataModelUrlDe =
      responseData.oldReplacedDataModelUrlDe || "";
    this.oldReplacedDataModelUrlFr =
      responseData.oldReplacedDataModelUrlFr || "";
    this.oldReplacedDataModelUrlIt =
      responseData.oldReplacedDataModelUrlIt || "";

    this.dataModelUrl = responseData.dataModelUrl || "";
    this.dataModelUrlDe = responseData.dataModelUrlDe || "";
    this.dataModelUrlFr = responseData.dataModelUrlFr || "";
    this.dataModelUrlIt = responseData.dataModelUrlIt || "";
    this.dataModelChanges = responseData.dataModelChanges || "";
    this.dataModelChangesDe = responseData.dataModelChangesDe || "";
    this.dataModelChangesFr = responseData.dataModelChangesFr || "";
    this.dataModelChangesIt = responseData.dataModelChangesIt || "";

    this.modelDocumentationUrlLabel =
      responseData.modelDocumentationUrlLabel || "";
    this.modelDocumentationUrlLabelDe =
      responseData.modelDocumentationUrlLabelDe || "";
    this.modelDocumentationUrlLabelFr =
      responseData.modelDocumentationUrlLabelFr || "";
    this.modelDocumentationUrlLabelIt =
      responseData.modelDocumentationUrlLabelIt || "";

    this.modelDocumentationUrl = responseData.modelDocumentationUrl || "";
    this.modelDocumentationUrlDe = responseData.modelDocumentationUrlDe || "";
    this.modelDocumentationUrlFr = responseData.modelDocumentationUrlFr || "";
    this.modelDocumentationUrlIt = responseData.modelDocumentationUrlIt || "";
    this.startDate = responseData.startDate || null;
    this.endDate = responseData.endDate || null;
    this.metadataUrls = responseData.metadataUrls || null;
    this.status = clonedOriginal.status || null;
  }
}
