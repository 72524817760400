// MODEL
/**
 * Authority base model
 * @returns {object} JSON objects containing
 *  isEditing {bool}
 *  id {string}
 *  name {string}
 */
export function Authority(authority) {
  this.isEditing = false || null;
  this.id = authority.id || null;
  this.corp = authority.corp || null;
  this.name = authority.name || null;
  this.nameDe = authority.nameDe || null;
  this.nameFr = authority.nameFr || null;
  this.nameIt = authority.nameIt || null;
  this.abbr = authority.abbr || null;
  this.abbrDe = authority.abbrDe || null;
  this.abbrFr = authority.abbrFr || null;
  this.abbrIt = authority.abbrIt || null;
  this.url = authority.url || null;
  this.urlDe = authority.urlDe || null;
  this.urlFr = authority.urlFr || null;
  this.urlIt = authority.urlIt || null;
}
