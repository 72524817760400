// MODEL
/**
 * contactperson base model
 * @returns {object} JSON objects containing
 *  id {number}
 *  firstName {string}
 *  lastName {string}
 *  email {string}
 *  tel {string}
 *  corp {string}
 *  authority {string}
 */
export function Contactperson(contactPerson) {
  if (!contactPerson) return null;
  this.isEditing = false;
  this.id = contactPerson.id || null;
  this.firstName = contactPerson.firstName || null;
  this.lastName = contactPerson.lastName || null;
  // template strings can be pretty random with spaces and breaks, this forces spaces
  this.fullName = `${this.firstName || ""}\u00A0${this.lastName || ""}`;
  this.email = contactPerson.email || null;
  this.tel = contactPerson.tel || null;
  this.corp = contactPerson.corp || null;
  this.authority = contactPerson.authority || null;
}
