import { defineStore } from "pinia";
import { getCatalogList } from "@/services/catalogs";
import { useLoadingStore } from "./Loading";

export const useCatalogListStore = defineStore('CatalogList', {
  state: () => {
    return {
      catalogList: null,
      unsavedChanges: []
    }
  },
  actions: {
    setCatalogList(catalogList) {
      this.catalogList = catalogList;
    },
    addIdToUnsavedChanges(id) {
      if (this.unsavedChanges.indexOf(id) === -1) {
        this.unsavedChanges.push(id);
      }
    },
    removeIdFromUnsavedChanges(id) {
      const index = this.unsavedChanges.findIndex(el => el === id);
      this.unsavedChanges.splice(index, 1);
    },
    clearUnsavedChanges() {
      this.unsavedChanges = [];
    },
    async retrieveCatalogList() {
      const loadingStore = useLoadingStore();

      loadingStore.setLoading(true);
      const result = await getCatalogList();
      this.setCatalogList(result);
      loadingStore.setLoading(false);
    }
  }
});
