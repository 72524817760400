import api from "./api";
import { TechnicalEntry } from "@/models/TechnicalEntry.model";
import { formatToStandardTextDate, parseDateFromServer } from "./utils";

/**
 * post to api to create a new technical entry
 * @param {string} technicalEntryId string with the id of the technical entry we want to create it in
 *
 * @returns {object} json object
 *
 */

const createNewTechnicalEntry = async rowId => {
  //call technical-entry API based on filters
  const response = await api
    .post(`technical-entry/`, {
      legRowId: rowId
    })
    .catch(error => {
      return error;
    });
  let technicalEntry = new TechnicalEntry(response.data);

  return technicalEntry;
};

/**
 * post to api to suspend a legal entry
 * @param {string} catalogId string with the id of the catalog we want to create it in
 */
const suspendTechnicalEntry = async (rowId, suspendDate) => {
  if (!rowId) {
    return;
  }
  const data = {
    endDate: formatToStandardTextDate(parseDateFromServer(suspendDate))
  };
  const response = api.put(`technical-entry/suspend/${rowId}/`, data);
  return response;
};

/**
 * put to api to delete a legal entry
 * @param {string} string with the id of the legal entry we want to delete
 */
const deleteTechnicalEntry = async rowId => {
  if (!rowId) {
    // eslint-disable-next-line no-use-before-define
    console.error("No Row ID provided");
    return;
  }
  const response = api.put(`technical-entry/delete/${rowId}/`);
  return response;
};

/**
 * PUT to api to update a legal entry
 * @param {integer} id The id of the record to update
 * @param {object} data containing technical record
 * @param {boolean} all_lang must apply all lang or not (for metadataUrls).
 */
const updateTechnicalRecord = async (id, data, allLang = false) => {
  const forAllLang = allLang ? "?all_lang=true" : "";
  return api.put(`technical-entry/${id}/${forAllLang}`, data);
};

/**
 * PUT to api to publish a legal entry
 * Use all_lang parameter to keep all set lang and not only the current one (for metadataUrls).
 * @param {integer} id The id of the record to update
 * @param {object} payload containing technical record
 */
const publishTechnicalRecord = async (id, payload) => {
  await api.put(`technical-entry/publish/${id}/?all_lang=true`, payload);
};

export {
  createNewTechnicalEntry,
  deleteTechnicalEntry,
  publishTechnicalRecord,
  suspendTechnicalEntry,
  updateTechnicalRecord
};
