export const getTechnicals = legislativeEntry => {
  return legislativeEntry.technicalEntries.filter(
    technical => technical.status === "Published"
  );
};

export const getParentTechnical = (parentLegislativeEntry, technical) => {
  return parentLegislativeEntry.technicalEntries.find(
    parentTech => technical.labelNumber === parentTech.labelNumber
  );
};

export const getMatchingDraftTechnical = (legislativeEntry, technical) => {
  return legislativeEntry.technicalEntries.find(
    tech =>
      technical.labelNumber === tech.labelNumber && tech.status === "Draft"
  );
};
