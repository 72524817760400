import { login, refreshToken, refreshData } from "@/services/user.js";
import { getUserCorpList } from "@/services/corp.js";
import { getCookieByName } from "@/services/utils";
import { defineStore } from "pinia";
import { useLoadingStore } from "./Loading";
import { useLegislativeCatalogStore } from "./LegislativeCatalog";
import { useCorpStore } from "./Corp";
import { useContactPersonStore } from "./ContactPersons";
import { useLegalBasisStore } from "./LegalBasis";
import { useAuthorityStore } from "./Authority";
import { useGeoCategoryStore } from "./GeoCategory";
import { useCatalogListStore } from "./CatalogList";
import { useCorpFilter, useFiltersFacade } from "./Filters";

let loadingStore;
let legislativeCatalogStore;
let corpStore;
let contactPersonStore;
let legalBasisStore;
let authStore;
let geoCategoryStore;
let catalogListStore;

const setCookie = token => {
  //set cookie expiry date
  let expiryMinutes = 480;
  let tokenExpiry = new Date();

  tokenExpiry.setTime(tokenExpiry.getTime() + expiryMinutes * 60000);

  //set cookie with expiry date
  document.cookie = `kkgeoAuthToken=${token};expires=${tokenExpiry.toGMTString()};path=/`;
};

export const useUserStore = defineStore('User', {
  state: () => {
    return {
      isLoggingIn: false,
      loginFailed: false,
      token: null,
      // id of token refresh interval
      tokenRefreshInterval: null,
      refreshToken: null,
      firstName: null,
      lastName: null,
      corp: null,
      id: null,
      initialCorp: null,
      userCorpList: null,
      corpLevel: null,
      crudCorpsIds: []
    }
  },

  getters: {
    isLoggedIn: (state) => {
      return state.token !== null;
    },
    usersName: (state) => {
      return state.firstName + " " + state.lastName;
    }
  },

  actions: {
    setIsLoggingIn(data) {
      this.isLoggingIn = data;
    },
    setLoginToken(token) {
      this.token = token;
    },
    doLogout() {
      this.token = null;
      this.corp = null;
      this.initialCorp = null;
      this.id = null;
      this.lastName = null;
      this.firstName = null;
      this.corpLevel = null;
      this.userCorpList = null;
      window.clearInterval(this.tokenRefreshInterval);
    },
    setToken(token) {
      this.token = token;
    },
    setUserCorpList(data) {
      this.userCorpList = data;
    },
    setLoginError(errorState) {
      this.loginFailed = errorState;
    },
    setTokenRefreshInterval(interval) {
      this.tokenRefreshInterval = interval;
    },
    setRefreshToken(token) {
      this.refreshToken = token;
    },
    setUserData(data) {
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.id = data.id;
      this.corp = data.takeoverCorp || data.corp;
      this.initialCorp = data.corp;
      this.corpLevel = data.corpLevel;
      this.crudCorpsIds = data.crudCorpsIds || [];
    },
    /**
     * set user corp
     *
     * @param {int} data
     */
    setUserCorp(data) {
      this.corp = data;
    },
    resetUserCorp() {
      this.corp = null;
      this.corpLevel = null;
    },
    /**
     * Do some initialisation stuff after login
     * @param {Store} param0
     */
    async reloadInitialData(route, router) {
      loadingStore = useLoadingStore();
      corpStore = useCorpStore();
      contactPersonStore = useContactPersonStore();
      legislativeCatalogStore = useLegislativeCatalogStore();
      legalBasisStore = useLegalBasisStore();
      authStore = useAuthorityStore();
      geoCategoryStore = useGeoCategoryStore();
      catalogListStore = useCatalogListStore();
      const filtersFacadeStore = useFiltersFacade();
      const corpFilterStore = useCorpFilter();

      this.handleTokenRefresh();

      //if the user had a problem in the first attempt we want to remove the error message
      this.setLoginError(false);

      try {
        loadingStore.setLoading(true);
        legislativeCatalogStore.clearCatalog();

        // Fetch the necessary data with the bearer token gotten from the Backend
        // All fetch operations initialized from mounted hooks or from language watchers
        // are now here or in the Home.vue view. This ensures that the data is only loaded once
        // on page load. Either here for logged-in users or in mounted hook of the Home.vue view for
        // non-authenticated Users.
        // All initializations done in the Admin components were also removed as the user needs to be
        // authenticated to see data (expect the ContactPersonTable.vue as it has data that is only used there).
        await corpStore.retrieveCorpList(true);
        await this.retrieveUserCorpData();

        // Set user corp if existing to ensure correct data loading
        // Set only the corp, then only retrieve manually linked data on the detail or home route.
        const corp = this.corp || corpStore.mainCorp.id;
        corpFilterStore.setCorpFilter(corp);
        contactPersonStore.retrieveContactPersonsData();

        // Only load the filter items if we are in the home view
        if (route.name === "home") {
          legalBasisStore.retrieveLegalBasisList();
          authStore.retrieveAuthorityData();
          legalBasisStore.retrieveLegalBasisList();
          geoCategoryStore.retrieveGeoCategoryData();
          // And update the url with the new filter state
          filtersFacadeStore.updateUrl(router);
        }

        // Only load the catalogList if we are in the home or detail-list view
        if (
          route.name === "home" || route.name === "detail-list"
        ) {
          catalogListStore.retrieveCatalogList();
          legislativeCatalogStore.retrieveLegislativeList();
        }
      } catch (error) {
        this.setLoginError(true)
        // eslint-disable-next-line no-use-before-define
        console.error(error);
      } finally {
        loadingStore.setLoading(false);
      }
    },
    /**
     * retrieve and set user corp options
     *
     */
    async retrieveUserCorpData() {
      const corpData = await getUserCorpList();
      this.setUserCorpList(corpData);
    },
    /**
     * Do a login
     *
     * @param {Object} param0 The Store
     * @param {Object} param1 User Data
     */
    async login({ username, password }, route, router) {
      loadingStore = useLoadingStore();
      try {
        this.setIsLoggingIn(true);
        const { token, refresh, ...userData } = await login(username, password);
        //write token into store
        this.setLoginToken(token);
        this.setUserData(userData);
        this.setUserCorp(userData.takeoverCorp || userData.corp)
        this.setRefreshToken(refresh);

        setCookie(refresh);
        this.reloadInitialData(route, router);
        this.setIsLoggingIn(false);
      } catch (error) {
        // eslint-disable-next-line no-use-before-define
        console.error(error);
        this.setLoginError(true);
        this.setIsLoggingIn(false);
        loadingStore.setLoading(true);
      }
    },
    logout(route, router) {
      //delete cookie by expiration
      document.cookie =
        "kkgeoAuthToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
      //logout/wipe store
      this.doLogout();
      this.resetUserCorp();
      this.reloadInitialData(route, router);
    },

    /**
     * Refresh the token every x minutes
     */
    handleTokenRefresh() {
      // it shouldn't happen, but let's make sure we only ever start one refresh interval
      if (this.tokenRefreshInterval) {
        return;
      }
      const intervalInMins = import.meta.env.TOKEN_REFRESH_INTERVAL || 1;
      const refreshInterval = intervalInMins * 60 * 1000;

      const interval = window.setInterval(async () => {
        const token = await refreshToken(this.refreshToken);
        // if this fails we won't handle the error, it just means the user will be
        // logged out after 10mins
        if (token) {
          // use the new token from now on
          this.setToken(token);
        }
      }, refreshInterval);
      this.setTokenRefreshInterval(interval);
    },

    /**
     * Login from the cookie
     */
    async loginFromCookie(route, router) {
      this.setIsLoggingIn(true);
      //Authentication token stored in cookie used to persist login
      let refresh = getCookieByName("kkgeoAuthToken");

      if (refresh) {
        this.setRefreshToken(refresh);

        //dispatch login into store before mounting app to render as logged in
        const token = await refreshToken(refresh);
        this.setLoginToken(token);

        await this.refreshUserData();
        this.reloadInitialData(route, router);
      }
      this.setIsLoggingIn(false);
    },

    /**
     * Refresh the additional user data
     */
    async refreshUserData() {
      const data = await refreshData();

      return this.setUserData(data);
    }
  }
});
