<template>
  <div>
    <p class="mt-0 text-xl font-bold">
      {{
        $t("differencesModalTitle", {
          parent: getI18nCorpParentLevelDisplay(),
        })
      }}.
    </p>

    <p class="text-sm italic leading-none">
      {{ $t("mandatoryFieldDesignedByStar") }}&nbsp;(<span
        class="text-orange-500"
        >*</span
      >)
    </p>

    <p class="text-sm italic leading-none">
      {{ $t("sameContentDifferences") }}
    </p>

    <div class="overflow-y-auto">
      <div v-if="legislativeHasChanges">
        <p>{{ $t("changedLegislative") }}</p>
        <div class="p-2 bg-gray-200 border-2 border-gray-300">
          <p class="max-w-full">
            {{ legislativeEntry.identifier }} -
            {{ legislativeEntry.title }}
          </p>
          <div v-for="property in getLegislativeSimpleProps()" :key="property">
            <div
              v-if="
                legislativeEntry[property] !== parentLegislativeEntry[property]
              "
              class="flex items-center mb-2"
            >
              <div class="pr-4">
                <input
                  v-model="legislativeCheckState[property]"
                  type="checkbox"
                />
              </div>
              <div class="w-full">
                <span class="text-orange-500">*</span>
                <span>{{ $t(property) }}:&nbsp;</span>
                <span>{{ $t(`${legislativeEntry[property]}`) }}</span>
                <span>&nbsp;->&nbsp;</span>
                <span class="font-bold">{{
                  $t(`${parentLegislativeEntry[property]}`)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="changedTechnicals.length">
        <p>{{ $t("changedTechnicals") }}</p>
        <p class="text-sm italic">{{ $t("changedTechnicalsLabelInfo") }}</p>
        <div
          v-for="changedTechItem in changedTechnicals"
          :key="changedTechItem.technicalEntry.id"
          class="p-2 mb-2 bg-gray-200 border-2 border-gray-300"
        >
          <p>
            {{ changedTechItem.technicalEntry.label }} -
            {{ changedTechItem.technicalEntry.description }}
          </p>
          <div
            v-for="changesItem in changedTechItem.changes"
            :key="changesItem.columnName"
            class="w-full"
          >
            <span class="pr-4">
              <input v-model="changesItem.checked" type="checkbox" />
            </span>
            <span v-if="!changesItem.isSoftChange" class="text-orange-500"
              >*</span
            >
            <span>{{ $t(changesItem.columnName) }}:&nbsp;</span>
            <template v-if="changesItem.needTranslation">
              <span>{{ $t(changesItem.textValue) }}</span>
              <span>&nbsp;->&nbsp;</span>
              <span class="font-bold"
                >{{ $t(changesItem.parentTextValue) }}
              </span>
            </template>
            <template v-else>
              <span>{{ changesItem.textValue }}</span>
              <span>&nbsp;->&nbsp;</span>
              <span class="font-bold">{{ changesItem.parentTextValue }} </span>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2">
      <div class="flex justify-end py-3 pt-4 pb-0 border-t">
        <div class="pr-8">
          <el-button
            size="small"
            class="align-middle"
            @click.prevent.stop="cancel()"
          >
            <span class="flex items-center">
              <span>{{ $t("abort") }}</span>
            </span>
          </el-button>
        </div>
        <div class="pr-8">
          <el-button
            size="small"
            class="text-white align-middle bg-cyan-600 hover:bg-cyan-600 hover:text-white"
            @click.prevent.stop="save()"
          >
            <span class="flex items-center">
              <span>{{ $t("save") }}</span>
            </span>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref, h } from "vue";
import { serialize as serializeTechnicalEntry } from "@/serializers/TechnicalEntry.js";
import {
  hasLegislativeChanges,
  legislativeSimpleProps,
  getTechnicalWithChanges,
} from "./parentDelegationChanges.js";
import {
  getMatchingDraftTechnical,
  getParentTechnical,
  getTechnicals,
} from "@/store/legislativeCatalogUtils";
import { LegislativeEntry } from "@/models/LegislativeEntry.model";
import { TechnicalEntry } from "@/models/TechnicalEntry.model";
import { LANG_SUFFIXES } from "@/store/Language";
import { useI18n } from "vue-i18n";
import { useCorpStore } from "@/store/Corp";
import { useCorpFilter } from "@/store/Filters";
import { useLanguageStore } from "@/store/Language";
import { useLegislativeCatalogStore } from "@/store/LegislativeCatalog";
import { ElNotification } from "element-plus";
import { computed } from "vue";

const { t } = useI18n();
const corpStore = useCorpStore();
const corpFilterStore = useCorpFilter();
const languageStore = useLanguageStore();
const legislativeCatalogStore = useLegislativeCatalogStore();

const legislativeEntry = ref(null);
const parentLegislativeEntry = ref(null);
const legislativeHasChanges = ref(false);
const legislativeCheckState = ref({
  access: true,
  oereb: true,
  downloadService: true,
  geoReference: true,
});
const changedTechnicals = ref([]);

const language = computed(() => languageStore.language);

const getLegislativeSimpleProps = () => {
  return legislativeSimpleProps;
};

const getI18nCorpParentLevelDisplay = () => {
  return t(corpStore.getCorpParentLevel(corpFilterStore.corpFilter));
};

const cancel = () => {
  legislativeCatalogStore.setPublishedParentToCompare(null);
};

const save = async () => {
  // Fetch legislative response after, because it will contain the technical changes as well.
  const techResponses = await saveAllCheckedTechnicalsChanges();
  const legResponse = await saveLegislativeChanges();
  if (legResponse) {
    if (legResponse.data) {
      const new_entry = new LegislativeEntry(legResponse.data);
      if (legislativeEntry.value.status.toLowerCase() === "published") {
        legislativeCatalogStore.addDraftLegalRow(new_entry);
      } else {
        legislativeCatalogStore.replaceLegalRow(new_entry);
        legislativeCatalogStore.mustCollapse(new_entry.id);
      }
      notifySuccess(new_entry);
    } else {
      notifyError(legResponse);
    }
  }
  if (!legResponse && techResponses) {
    if (techResponses[0].data) {
      const technicalEntries = techResponses.map(
        (techResponse) => new TechnicalEntry(techResponse.data)
      );
      // Replaces with new technical entries
      legislativeEntry.value.technicalEntries = [
        ...legislativeEntry.value.technicalEntries.filter(
          (techEntry) =>
            !technicalEntries.some(
              (newTechEntry) => techEntry.id === newTechEntry.id
            )
        ),
        ...technicalEntries,
      ];
      legislativeCatalogStore.replaceLegalRow(legislativeEntry.value);
      legislativeCatalogStore.mustExpandList(legislativeEntry.value.id);
      notifySuccess(legislativeEntry.value);
    } else {
      notifyError(techResponses[0].data);
    }
  }
  legislativeCatalogStore.setPublishedParentToCompare(null);
};

const saveLegislativeChanges = async () => {
  if (!legislativeHasChanges.value) {
    return;
  }
  const legislativeEntryNew = { ...legislativeEntry.value };
  let mustBeUpdated = false;
  legislativeSimpleProps.forEach((prop) => {
    if (legislativeCheckState.value[prop]) {
        legislativeEntryNew[prop] = parentLegislativeEntry.value[prop];
      mustBeUpdated = true;
    }
  });
  return mustBeUpdated
    ? legislativeCatalogStore.saveRecord(legislativeEntryNew)
    : undefined;
};
const saveAllCheckedTechnicalsChanges = async () => {
  if (!changedTechnicals.value.length) {
    return [];
  }
  const promises = getTechnicals(legislativeEntry.value)
    .filter((technicalEntry) => {
      const changedTechnical = changedTechnicals.value.find(
        (changedTechItem) =>
          changedTechItem.technicalEntry.id === technicalEntry.id
      );
      return (
        changedTechnical &&
        changedTechnical.changes.some((changesItem) => changesItem.checked)
      );
    })
    .map(
      async (technicalEntry) => await saveOneTechnicalChanges(technicalEntry)
    );
  return Promise.all(promises);
};

const saveOneTechnicalChanges = async (technicalEntry) => {
  const changedTechnical = changedTechnicals.value.find(
    (changedTechItem) => changedTechItem.technicalEntry.id === technicalEntry.id
  );
  const matchingDraft = getMatchingDraftTechnical(
    legislativeEntry.value,
    technicalEntry
  );
  const techEntry = matchingDraft ? matchingDraft : { ...technicalEntry };
  changedTechnical.changes
    .filter((changesItem) => changesItem.checked)
    .forEach((changesItem) => {
      changesItem.restoreFromParentFn(techEntry);
    });
  fixMandatoryFieldsFromParent(techEntry);
  return await legislativeCatalogStore.saveTechnicalRecord({
    id: techEntry.id,
    data: serializeTechnicalEntry(techEntry),
    allLang: true,
  });
};

/**
 * Rare legacy case where the draft has no description at all, but the published one has the correct title
 * In this case, add the description from the parent.
 */
const fixMandatoryFieldsFromParent = (techEntry) => {
  const parentTechEntry = getParentTechnical(
    parentLegislativeEntry.value,
    techEntry
  );
  const hasDescription = techEntry.description && techEntry.description.length;
  if (!parentTechEntry || hasDescription) {
    return;
  }
  techEntry.description = parentTechEntry.description;
  LANG_SUFFIXES.forEach((lang) => {
    techEntry[`description${lang}`] = parentTechEntry[`description${lang}`];
  });
};

const notifySuccess = (legislativeEntry) => {
  // Display the success message in form of a notification.
  ElNotification({
    title: t("legalEntryDataset") + " " + legislativeEntry.identifier,
    message: h("span", { style: "color: teal" }, t("legalEntrySuccess")),
  });
};

const notifyError = (error) => {
  ElNotification.error({
    title: t("saveError"),
    message: error.message,
  });
  // eslint-disable-next-line no-use-before-define
  console.error(error.message);
};
onBeforeMount(() => {
  setupData();
});

const setupData = () => {
  legislativeEntry.value =
    legislativeCatalogStore.entryAndPublishedParentComparison[0];
  parentLegislativeEntry.value =
    legislativeCatalogStore.entryAndPublishedParentComparison[1];
  legislativeHasChanges.value = hasLegislativeChanges(
    legislativeEntry.value,
    parentLegislativeEntry.value
  );
  changedTechnicals.value = getTechnicalWithChanges(
    legislativeEntry.value,
    parentLegislativeEntry.value,
    language.value
  );
};
</script>

<style lang="postcss" scoped>
</style>