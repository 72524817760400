import api from "./api";
import { Geocategory } from "../models/Geocategory.model";

/**
 * Get JSON data containing geo-categories
 */
const getGeoCategoryData = async () => {
  const response = await api.get("geo-category/");
  return response.data.map(geoCategory => {
    return new Geocategory(geoCategory);
  });
};

export { getGeoCategoryData };
