import { get } from "lodash";
import { ElNotification } from 'element-plus'

export function notifyError(error) {
    ElNotification.error({
    title: get(error, "response.status", "Error"),
    message: get(error, "response.data", ""),
  });
}

export function notifySuccess(payload) {
  ElNotification.success({
    title: get(payload, "response.status", "Success"),
    message: get(payload, "response.data", ""),
  });
};