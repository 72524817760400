<template>
  <div>
    <p
      v-if="userStore.loginFailed"
      class="text-sm text-orange-500 break-normal"
    >
      {{ $t("FormLogin.loginError") }}
    </p>
    <p v-if="userStore.isLoggedIn" class="text-sm text-orange-500 break-normal">
      {{ $t("FormLogin.loginSuccess") }}
    </p>
    <el-form v-if="!userStore.isLoggedIn" label-position="top">
      <el-form-item class="mt-12">
        <template #label>
          <label>
            {{ $t("FormLogin.username") }}
            <el-input
              ref="userNameInput"
              v-model="username"
              autofocus
              :placeholder="$t('FormLogin.userName')"
              @keyup.enter="doLogin"
            />
            <span
              v-if="!username.length && triedToLogin"
              class="inline-block text-sm text-orange-500 break-normal"
            >
              {{ $t("FormLogin.userValidation") }}
            </span>
          </label>
        </template>
      </el-form-item>

      <el-form-item>
        <template #label>
          <label>
            {{ $t("FormLogin.password") }}
            <el-input
              v-model="password"
              :placeholder="$t('FormLogin.password')"
              type="password"
              @keyup.enter="doLogin"
            />
            <span
              v-if="!password.length && triedToLogin"
              class="inline-block text-sm text-orange-400 break-normal"
            >
              {{ $t("FormLogin.passwordValidation") }}
            </span>
          </label>
        </template>
      </el-form-item>

      <div class="flex justify-end mt-16">
        <el-button
          class="has-icon"
          :disabled="!username.length || !password.length"
          size="default"
          @click="doLogin"
        >
          <SvgIcon name="check-circle" />
          {{ $t("FormLogin.login") }}
        </el-button>
      </div>
    </el-form>
    <p v-if="userStore.isLoggedIn">{{ $t("FormLogin.loginSuccess") }}</p>
  </div>
</template>
<script setup>
import { onMounted, ref, h, nextTick } from "vue";
import { useUserStore } from "@/store/User";
import { ElNotification } from "element-plus";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const userStore = useUserStore();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const triedToLogin = ref(false);
const username = ref("");
const password = ref("");
const userNameInput = ref(null);

const doLogin = async () => {
  triedToLogin.value = true;
  const loginData = {
    username: username.value,
    password: password.value,
  };
  if (username.value.length && password.value.length) {
    await userStore.login(loginData, route, router);
    if (userStore.isLoggedIn) {
      ElNotification({
        title: t("FormLogin.login"),
        message: h(
          "span",
          { style: "color: teal" },
          t("FormLogin.loginSuccess")
        ),
      });
    }
  }
};

onMounted(() => {
  //only trigger when we are sure the html is applied
  nextTick(() => {
    //grab the username input and focus it
    userNameInput.value.$el.getElementsByTagName("input")[0].focus();
  });
});
</script>
