import { ElMessageBox } from "element-plus";

export function confirm(
  t,
  text,
  title,
  yesCallback = () => {},
  noCallback = () => {}
) {
  ElMessageBox.confirm(text, title, {
    confirmButtonText: t("yes"),
    cancelButtonText: t("no"),
    type: "warning",
  })
    .then(yesCallback)
    .catch(noCallback);
}
