import { cloneDeep } from "lodash";

/**
 * Legislative entry light model.
 * @returns {object} JSON objects containing:
 * histoData {object}
 * isHistorized {boolean}
 * hideLabel {boolean}
 * tableLabel {string}
 * isEditing {boolean}
 * id {number}
 * identifier {string}
 * partialIdentifier {string}
 * identifierDelegation {string}
 * delegation {string}
 * differsFromParent {boolean}
 * softDiffersFromParent {boolean}
 * executiveCorp {object}
 * legislativeCorp {object}
 * legislativeAuthorities {array}
 * executiveAuthorities {array}
 * status {string}
 * parent {object}
 * isParent {boolean}
 * isGrandParent {boolean}
 * title {string}
 * titleDe {string}
 * titleFr {string}
 * titleIt {string}
 * titleRm {string}
 */
export function LegislativeEntryLight(legislativeEntryData) {
  const legislativeEntryCopy = cloneDeep(legislativeEntryData);
  if (this.isHistorised) {
    // This is an ugly bugfix. It seems that the legislativeCorp is not fully serialized in
    // the histodata, so we overtake the data from the entry instead of he historized data
    legislativeEntryData = legislativeEntryData.histoData;
    legislativeEntryData.histoData.isParent = legislativeEntryData.isParent;
    if (legislativeEntryCopy.parent) {
      legislativeEntryData.parent.legislativeCorp =
        legislativeEntryCopy.parent.legislativeCorp;
    }
  }
  this.histoData = legislativeEntryData.histoData || null;
  this.isHistorised = legislativeEntryData.isHistorised || null;
  this.hideLabel = legislativeEntryData.hideLabel || false;
  this.tableLabel =
    Math.floor(Math.random() * 10000000) + "-" + legislativeEntryData.id ||
    null;
  this.isEditing = false;
  this.id = legislativeEntryData.id || "";
  this.identifier = legislativeEntryData.identifier || "";
  const identifierParts = this.identifier.split(">");
  this.partialIdentifier = identifierParts[0];
  this.identifierDelegation =
    identifierParts.length > 1 ? `>${identifierParts[1]}` : "";
  this.delegation = legislativeEntryData.delegation || null;
  this.differsFromParent = legislativeEntryData.differsFromParent === true;
  this.softDiffersFromParent =
    legislativeEntryData.softDiffersFromParent === true;
  this.decoupledFromParent = legislativeEntryData.decoupledFromParent === true;
  this.executiveCorp = legislativeEntryData.executiveCorp || null;
  this.legislativeCorp = legislativeEntryData.legislativeCorp || null;
  this.legislativeAuthorities =
    legislativeEntryData.legislativeAuthorities || [];
  this.executiveAuthorities = legislativeEntryData.executiveAuthorities || [];
  this.status = legislativeEntryCopy.status || "";
  this.parent = legislativeEntryData.parent || null;
  this.isParent = legislativeEntryData.isParent;
  this.isGrandParent = legislativeEntryData.isGrandParent;
  this.title = legislativeEntryData.title || "";
  this.titleDe = legislativeEntryData.titleDe || null;
  this.titleFr = legislativeEntryData.titleFr || null;
  this.titleIt = legislativeEntryData.titleIt || null;
  this.titleRm = legislativeEntryData.titleRm || null;
}
