import api from "./api";

/**
 * Get JSON data to build the corp dropdown
 *
 * This retrieves *all* the available corps!
 */
const getCorpList = async () => {
  const response = await api.get("corp/", {});
  let cleanedData = response.data.map(corpEntry => {
    let cleanedEntry = {};
    corpEntry.level === "federal"
      ? (cleanedEntry.mainCorp = true)
      : (cleanedEntry.mainCorp = false);
    cleanedEntry.id = corpEntry.id;
    cleanedEntry.name = corpEntry.name;
    cleanedEntry.level = corpEntry.level;
    cleanedEntry.filterDisplay = corpEntry.filterDisplay;
    cleanedEntry.abbr = corpEntry.abbr;
    return cleanedEntry;
  });

  return cleanedData;
};
/**
 * Get JSON data to build the corp dropdown
 *
 * This retrieves *all* the available corps!
 */
const getUserCorpList = async () => {
  let params = {};
  params.takeover = true;
  const response = await api.get("corp/", {
    params: params
  });
  let cleanedData = response.data.map(corpEntry => {
    let cleanedEntry = {};
    cleanedEntry.id = corpEntry.id;
    cleanedEntry.name = corpEntry.name;
    cleanedEntry.filterDisplay = corpEntry.filterDisplay;
    return cleanedEntry;
  });

  return cleanedData;
};

export { getCorpList, getUserCorpList };
