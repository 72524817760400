import { createApp } from "vue";
import { createPinia } from 'pinia';

import App from "./App.vue";
import router from './router/index.js'

import i18n from './i18n.js';
import SvgIcon from '@/components/SvgIcon.vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import de from 'element-plus/es/locale/lang/de'

import "@/assets/css/main.postcss";

import "./polyfills/array";
import "svgxuse";

let app = null;
const setupApp = (app) => {
  app.config.productionTip = false;
  app.use(router);
  app.use(i18n);
  app.use(createPinia());
  app.use(ElementPlus, { locale: de });

  app.component('SvgIcon', SvgIcon);

  app.mount("#app");
}

app = createApp(App)
setupApp(app)
