<template>
  <header
    class="inset-x-0 top-0 py-2 bg-white shadow-lg site-header sm:pb-0"
    :class="[isMobileOpen ? 'absolute' : 'sticky']"
    role="banner"
  >
    <div class="container flex items-center justify-between sm:justify-center">
      <SiteLogo class="sm:hidden" />

      <button class="sm:hidden" @click="toggleNavMobile">
        <SvgIcon name="menu" />
      </button>

      <el-dialog
        v-model="isMobileOpen"
        :append-to-body="true"
        custom-class="mobile-menu"
        fullscreen
      >
        <NavMobile />
      </el-dialog>

      <nav class="hidden sm:block" role="navigation">
        <ul class="flex items-end">
          <li>
            <router-link class="navbar__link" :to="{ name: 'home', query: { lang: locale }}">
              {{ $t("views.catalog") }}
            </router-link>
          </li>
          <li>
            <router-link class="navbar__link" :to="{ name: 'contacts', query: { lang: locale }}">
              {{ $t("views.contacts") }}
            </router-link>
          </li>
          <li v-if="userStore.isLoggedIn">
            <router-link class="navbar__link" :to="{ name: 'administration', query: { lang: locale }}">
              {{ $t("views.admin") }}
            </router-link>
          </li>
          <li>
            <a href="/api/v1/redoc/" target="_blank" class="navbar__link">
              {{ $t("api") }}
            </a>
          </li>
          <li v-if="userStore.isLoggedIn">
            <a :href="documentationURL" target="_blank" class="navbar__link">
              {{ $t("documentation") }}
            </a>
          </li>
          <li v-else>
            <el-popover
              placement="bottom-end"
              trigger="click"
              @after-enter="openLoginMask"
              @after-leave="closeLoginMask"
            >
              <FormLogin v-if="isLoginOpen && !userStore.isLoggedIn" />
              <template #reference>
                <button class="navbar__link">
                  {{ $t("login") }}
                </button>
              </template>
            </el-popover>
          </li>
          <li v-if="userStore.isLoggedIn">
            <button class="navbar__link" @click="logout">
              {{ $t("logout") }}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script setup>
import SiteLogo from "@/components/SiteLogo.vue";
import NavMobile from "@/components/NavMobile.vue";
import FormLogin from "@/components/form/FormLogin.vue";
import { ElNotification, ElMessageBox } from 'element-plus'
import { useRouter, useRoute } from "vue-router";
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useCatalogListStore } from "@/store/CatalogList";
import { useUserStore } from "@/store/User";
import { useLanguageStore } from "@/store/Language";
import api from "@/services/api";
import { h } from "vue";

const { locale, t } = useI18n();
const router = useRouter();
const route = useRoute();
const catalogListStore = useCatalogListStore();
const userStore = useUserStore();
const languageStore = useLanguageStore();

const isMobileOpen = ref(false);
const isLoginOpen = ref(false);

const documentationURL = computed(() => {
  let lang = locale.value;
  let domain = window.location.hostname + "/";
  let protocol = window.location.protocol;
  return protocol + "//docs." + domain + lang + "/manual/index.html";
});

const unsavedChanges = computed(() => {
  return catalogListStore.unsavedChanges;
});

const toggleNavMobile = () => {
  isMobileOpen.value = !isMobileOpen.value;
}

const openLoginMask = () => {
  isLoginOpen.value = true;
}

const closeLoginMask = () => {
  isLoginOpen.value = false;
}

const logout = () => {
  // prevent if unsaved
  if (unsavedChanges.value.length) {
    // ask if you are sure
    ElMessageBox.confirm(t("warningEdit"), t("warning"), {
      confirmButtonText: t("OK"),
      cancelButtonText: t("cancel"),
      type: "warning"
    }).then(() => {
      catalogListStore.clearUnsavedChanges();
      doLogout();
    });
  } else {
    doLogout();
  }
}

const doLogout = () => {
  userStore.logout(route, router)
  router.push({ path: "/" });
  //display the successmessage in form of a notification
  ElNotification({
    title: t("FormLogin.logout"),
    message: h(
      "span",
      { style: "color: teal" },
      t("FormLogin.logoutSuccess")
    )
  });
}

onMounted(async () => {
  const head = document.head || document.getElementsByTagName("head")[0];
  const rssLink = document.createElement("link");

  // Add the general catalog link
  rssLink.rel = "alternate";
  rssLink.type = "application/rss+xml";
  let feedBaseURL = new URL(import.meta.env.VITE_API_URL.replace(/\/$/, ""));
  feedBaseURL.pathname += "/feed/catalog/rss";
  feedBaseURL.searchParams.append("lang", languageStore.language);
  rssLink.href = feedBaseURL.toString();
  rssLink.title = t("catalog");
  head.appendChild(rssLink);

  // Add links from specific catalogs
  let response = await api.get("corp/", {});
  response.data.forEach(feedElement => {
    const rssLink = document.createElement("link");
    rssLink.rel = "alternate";
    rssLink.type = "application/rss+xml";
    let feedBaseURL = new URL(import.meta.env.VITE_API_URL.replace(/\/$/, ""));
    feedBaseURL.pathname += `/feed/${feedElement.id}/rss`;
    feedBaseURL.searchParams.append("lang", languageStore.language);
    rssLink.href = feedBaseURL.toString();
    rssLink.title = feedElement.filterDisplay;
    head.appendChild(rssLink);
  });
});

</script>

<style lang="postcss">
.site-header {
  z-index: var(--z-index-site-header);
}

.navbar__link {
  @apply px-2 mx-2 py-2;
  @apply text-base font-regular leading-none tracking-4xl;
  @apply border-b-8 border-transparent;
  display: block;
  color: inherit;
}

@screen md {
  .navbar__link {
    @apply mx-4 py-3;
    @apply text-lg;
  }
}

.navbar__link:hover,
.navbar__link:focus {
  @apply text-black;
}

.navbar__link.is-hidden {
  display: none;
}

.navbar__link.router-link-exact-active {
  @apply font-medium;
  @apply border-orange-400;
}
</style>
