<template>
  <div class="container pt-8 pb-12 hidden sm:block">
    <div class="flex justify-between items-center">
      <div class="hidden lg:block flex-1">&nbsp;</div>

      <div v-if="isChristmas" class="text-black" to="/">
        <div @click="snow">
          <SiteBrand />
        </div>
      </div>

      <router-link v-else class="text-black" to="/">
        <SiteBrand />
      </router-link>

      <NavLang class="flex-1 flex justify-end is-wide" />
    </div>
  </div>
</template>

<script setup>
import SiteBrand from "@/components/SiteBrand.vue";
import NavLang from "@/components/NavLang.vue";
import Snowflakes from "magic-snowflakes";
import { computed } from "vue";
import { useDateFilter } from "@/store/Filters";

const dateFilter = useDateFilter();

const isChristmas = computed(() => {
  const date = dateFilter.dateUntil;
  return date.getDate() === 24 && date.getMonth() + 1 === 12;
});

const snow = () => {
  new Snowflakes();
};
</script>
