import api from "./api";
import { LegalBase } from "@/models/LegalBasis.model.js";

import { UrlParamKey } from "../utils";
import { formatToStandardTextDate } from "./utils";

/**
 * Get JSON data about the legal basis, based on the corp
 */
const getLegalBasisList = async corpId => {
  // conditional params only if they exist
  let params = {};
  if (corpId) {
    params[UrlParamKey.Corp] = corpId;
  }
  const response = await api.get("legalbasis/", { params });

  let cleanedData = response.data.map(legalBaseEntry => {
    return new LegalBase(legalBaseEntry);
  });

  return cleanedData;
};

const saveLegalBasis = async (pk, newData) => {
  const data = {
    code: newData.code,
    codeDe: newData.codeDe,
    codeFr: newData.codeFr,
    codeIt: newData.codeIt,
    name: newData.name,
    nameDe: newData.nameDe,
    nameFr: newData.nameFr,
    nameIt: newData.nameIt,
    url: newData.url,
    urlDe: newData.urlDe,
    urlFr: newData.urlFr,
    urlIt: newData.urlIt,
    abbr: newData.abbr,
    abbrDe: newData.abbrDe,
    abbrFr: newData.abbrFr,
    abbrIt: newData.abbrIt,
    startDate: newData.startDate
      ? formatToStandardTextDate(newData.startDate)
      : ""
    // endDate: newData ? formatToStandardTextDate(newData.endDate) : null
  };

  const url = `legalbasis/${pk}/`;
  const response = await api.put(url, data);
  return new LegalBase(response.data);
};

const deleteLegalBase = async pk => {
  const url = `legalbasis/${pk}/`;
  return api.delete(url);
};

const createLegalBasis = async () => {
  const url = `legalbasis/`;
  let response = await api.post(url);
  return new LegalBase(response.data);
};

export { getLegalBasisList, saveLegalBasis, deleteLegalBase, createLegalBasis };
