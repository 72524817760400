import { defineStore } from "pinia";
import { useContactStore } from "./Contacts";
import { useUserStore } from "./User";
import { useCorpStore } from "./Corp";
import { useGeoCategoryStore } from "./GeoCategory";
import { useCorpFilter } from "./Filters";

export const LANG_SUFFIXES = ["De", "Fr", "It", "Rm"];

export const useLanguageStore = defineStore('Language', {
  state: () => {
    return {
      language: localStorage.getItem("lang") || "de"
    }
  },

  getters: {
    getLanguage: (state) => {
      return state.language;
    }
  },

  actions: {
    setLanguageInternal(lang) {
      this.language = lang;
    },
    async setLanguage(lang, route, router, updateRoute = true) {
      const contactStore = useContactStore();
      const userStore = useUserStore();
      const corpStore = useCorpStore();
      const corpFilterStore = useCorpFilter();
      const geoCategoryStore = useGeoCategoryStore();

      this.setLanguageInternal(lang);
      localStorage.setItem("lang", lang);
      if (route.name === "contacts") {
        await contactStore.retrieveContactData();
        return;
      }
      if (userStore.isLoggedIn) {
        await userStore.reloadInitialData(route, router);
        return;
      }
      await corpStore.retrieveCorpList(true);
      const corp = corpFilterStore.corpFilter || corpStore.mainCorp.id
      if (updateRoute) {
        await corpFilterStore.changeCorpFilter(router, corp);
      }
      await geoCategoryStore.retrieveGeoCategoryData();
    }
  },
});
