import { defineStore } from "pinia";
import {
  getLegalBasisList,
  saveLegalBasis,
  deleteLegalBase,
  createLegalBasis
} from "@/services/legalBasis.js";
import { useLoadingStore } from "./Loading";
import { useCorpFilter, useLegalBasisFilter } from "./Filters";
import { cloneDeep } from "lodash";

export const useLegalBasisStore = defineStore('LegalBasis', {
  state: () => {
    return {
      legalBases: null 
    }
  },

  actions: {
    setLegalBases(legalBasesList) {
      this.legalBases = legalBasesList;
    },
    addLegalBases(legalBase) {
      this.legalBases.push(legalBase);
    },
    updateLegalBase(legalBase) {
      let index = this.legalBases.findIndex(item => item.id === legalBase.id);
      this.legalBases.splice(index, 1, legalBase);
    },
    removeLegalBase(legalBaseId) {
      // Replace the item by index.
      let index = this.legalBases.findIndex(item => item.id === legalBaseId);
      this.legalBases.splice(index, 1);
    },
    async retrieveLegalBasisList() {
      const loadingStore = useLoadingStore();
      const corpFilterStore = useCorpFilter();
      const legalBasisFilterStore = useLegalBasisFilter();

      loadingStore.setLoading(true);
      const legalBasesData = await getLegalBasisList(
        corpFilterStore.corpFilter
      );
      legalBasisFilterStore.assertIsValid(legalBasesData)
      this.setLegalBases(legalBasesData);
      loadingStore.setLoading(false);
    },
    async addRecord() {
      let legalBase = await createLegalBasis();
      this.addLegalBases(legalBase);
    },
    deleteRecordFromStore(pk) {
      this.removeLegalBase(pk);
    },
    async deleteRecord(pk) {
      let deletePromise = new Promise((resolve, reject) => {
        deleteLegalBase(pk)
          .then(() => {
            // use this to create a new stack, so we are sure that the resolve happens before we unmount the component
            setTimeout(() => {
              this.removeLegalBase(pk);
            }, 0);
            resolve();
          })
          .catch(err => {
            reject(err.response.data);
          });
      });
      return deletePromise;
    },

    async saveRecord({ pk, data }) {
      let payload = cloneDeep(data);
      const result = await saveLegalBasis(pk, payload);
      this.updateLegalBase(result);
      return result;
    }
  }
});
