<template>
  <div
    v-if="
      userStore.isLoggedIn &&
      userStore.userCorpList &&
      userStore.userCorpList.length &&
      !route.params.reviewid
    "
    class="flex flex-col items-center items-baseline lg:flex lg:mr-4"
  >
    <label class="ml-2 font-medium" for="">{{ $t("editAs") }}:</label>
    <el-select
      v-model="assignedUserCorp"
      class="ml-2"
      filterable
      clearable
      placeholder=""
      @change="changeUserCorp"
    >
      <el-option
        v-for="(corp, i) in userStore.userCorpList"
        :key="'specialUserCorp' + i"
        :label="corp.filterDisplay"
        :value="corp.id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script setup>
import { setTakeOverCorp } from "@/services/user.js";
import { computed, ref, watch } from "vue";
import { notifyError } from "@/composables/notification.js";
import { useCorpStore } from "@/store/Corp";
import { useUserStore } from "@/store/User";
import { useGeoCategoryStore } from "@/store/GeoCategory";
import { useCatalogListStore } from "@/store/CatalogList";
import { useContactPersonStore } from "@/store/ContactPersons";
import { useCorpFilter, useSearchFilter } from "@/store/Filters";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const userStore = useUserStore();
const corpStore = useCorpStore();
const geoCategoryStore = useGeoCategoryStore();
const catalogListStore = useCatalogListStore();
const contactPersonStore = useContactPersonStore();
const corpFilter = useCorpFilter();
const searchFilter = useSearchFilter();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const doneFetchinguserCorp = ref(false);
const assignedUserCorp = ref(userStore.corp);

const initialCorp = computed(() => userStore.initialCorp);

const userCorp = computed(() => userStore.corp);

watch(
  userCorp,
  () => {
    if (userCorp.value && !doneFetchinguserCorp.value) {
      assignedUserCorp.value = userCorp.value;
      doneFetchinguserCorp.value = true;
    }
  },
  { immediate: true }
);

const changeUserCorp = (filterValue = null) => {
  if (filterValue === initialCorp.value) {
    filterValue = null;
  }
  // Prepare the new corp thats set, if its empty set initial corp, if thats empty set null
  let newCorp = filterValue;
  if (!filterValue) {
    newCorp = initialCorp.value || null;
    assignedUserCorp.value = initialCorp.value || null;
  }
  setTakeOverCorp(filterValue)
    .then(() => {
      searchFilter.changeSearchFilter(router, { value: "", loading: false });
      userStore.setUserCorp(newCorp);
      // this change needs to reflect on the filter as well
      corpFilter.changeCorpFilter(router, newCorp);
      corpStore.retrieveCorpList();
      geoCategoryStore.retrieveGeoCategoryData();
      catalogListStore.retrieveCatalogList();
      contactPersonStore.retrieveContactPersonsData();
    })
    .catch((err) => {
      notifyError(t("genericError"));
      // eslint-disable-next-line no-use-before-define
      console.error(err);
    });
};
</script>

<style lang="scss" scoped></style>
