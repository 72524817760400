import Home from "@/views/Home.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import Contacts from "@/views/Contacts.vue";

export default [
  {
    path: "/contacts",
    name: "contacts",
    component: Contacts,
  },
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/review/:reviewid",
    name: "review",
    component: Home,
  },
  {
    path: "/detail",
    name: "detail-list",
    component: () => import("@/views/Detail.vue"),
  },
  {
    path: "/detail/:legislativeEntryId/",
    name: "detail",
    component: () => import("@/views/Detail.vue"),
  },
  {
    path: "/impressum",
    name: "impressum",
    component: () => import("@/views/Impressum.vue"),
  },
  {
    path: "/profile/:id",
    name: "profile",
    component: () => import("@/views/Profile.vue"),
  },
  {
    path: "/administration",
    name: "administration",
    component: () => import("@/views/Admin.vue"),
  },
  // and finally the default route, when none of the above matches:
  { path: "/:pathMatch(.*)*", component: PageNotFound },
];
