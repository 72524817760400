import { createWebHistory, createRouter } from "vue-router";
import { useUserStore } from "../store/User.js";
import { useContactStore } from "@/store/Contacts";
import { notifyError } from "@/composables/notification.js";
import { useLanguageStore } from "../store/Language.js";
import { useFiltersFacade } from "@/store/Filters.js";
import i18n from "@/i18n.js";

import routes from "./routes.js";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

const PERMITTED_LANGS = ["de", "fr", "it", "rm"];

router.beforeEach(async (to, from) => {
  const userStore = useUserStore();
  const contactStore = useContactStore();
  const filtersFacade = useFiltersFacade();

  const languageStore = useLanguageStore();

  const { lang } = to.query;
  if (
    lang &&
    i18n.global.locale.value !== lang &&
    PERMITTED_LANGS.includes(lang)
  ) {
    i18n.global.locale.value = lang;
    languageStore.setLanguage(lang, to, router, false);
  }

  // log in the user if cookie exists
  if (to.name != from.name) {
    userStore.loginFromCookie(to, router);
  }

  if (to.name === "contacts") {
    try {
      await contactStore.retrieveContactData();
    } catch (error) {
      notifyError(error);
    }
  }

  if (to.name == "home") {
    filtersFacade.updateFiltersFromUrl(to);
  }
});

router.onError((error, to) => {
  if (error.message.includes("Failed to fetch dynamically imported module")) {
    window.location = to.fullPath;
  }
});

export default router;
