<template>
  <FormField :label="$t('delegation')">
    <el-select
      v-model="delegation"
      :placeholder="$t('delegationOptions.none')"
      :disabled="!delegatable"
      @change="emitChange"
    >
      <el-option :label="$t('delegationOptions.none')" value="none" />

      <el-option
        v-for="option in delegationOptions"
        :key="option.value"
        :label="option.label"
        :value="option.value"
      />
    </el-select>
  </FormField>
</template>

<script setup>
import FormField from "@/components/form/FormField.vue";
import { map } from "lodash";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
  delegatable: {
    type: Boolean,
    required: true,
  },
  delegationChoices: {
    type: Array,
    required: true,
  },
  modelValue: {
    required: true,
    validator(value) {
      return (
        value instanceof String || typeof value === "string" || value === null
      );
    },
  },
});

const emit = defineEmits(["update:modelValue"]);

const delegation = ref(props.modelValue || "none");

/**
 * Return the options for the delegation
 *
 * Determine based on the corp level, what options are available for the
 * delegation
 */

const delegationOptions = computed(() => {
  let options = map(props.delegationChoices, (delegation) => {
    return {
      label: t("delegationOptions." + delegation),
      value: delegation,
    };
  });

  return options;
});

const emitChange = () => {
  emit("update:modelValue", delegation.value);
};
</script>
