// MODEL
/**
 * catalog base model
 * @returns {object} JSON objects containing
 *  id {number}
 *  name {string}
 *  shorthand {string}
 *  corp (url) {string}
 */
export function Catalog(catalogEntry) {
  this.id = catalogEntry.id || null;
  this.name = catalogEntry.name || null;
  this.shorthand = catalogEntry.shorthand || null;
  this.corp = catalogEntry.corp || null;
}
