<template>
  <div>
    <!-- bool -->
    <el-form-item v-if="type === 'bool'" class="block">
      <template #label>
        <span class="leading-none block">{{ label }}</span>
        <el-radio v-model="localValue" label="True">
          {{ $t("yes") }}
        </el-radio>
        <el-radio v-model="localValue" label="False">
          {{ $t("no") }}
        </el-radio>
      </template>
    </el-form-item>
    <!-- text -->
    <el-form-item v-if="type === 'text'" class="block">
      <template #label>
        <span class="leading-none block">{{ label }}</span>
        <el-input v-model="localValue" minlength="2" />
      </template>
    </el-form-item>
    <!-- date -->
    <el-form-item v-if="type === 'date'" class="block">
      <template #label>
        <span class="leading-none block">{{ label }}</span>
        <el-config-provider :locale="pickerLocale">
          <el-date-picker
            v-model="localValue"
            type="date"
            :placeholder="$t('pickDate')"
            format="dd.MM.yyyy"
        /></el-config-provider>
      </template>
    </el-form-item>
    <!-- url  TODO: validate this-->
    <el-form-item v-if="type === 'url'" class="block">
      <template #label>
        <span class="leading-none block">{{ label }}</span>
        <el-input v-model="localValue" type="url" minlength="2" />
      </template>
    </el-form-item>
  </div>
</template>


<script setup>
import { ref, watch, computed } from "vue";
import de from "element-plus/dist/locale/de.mjs";
import fr from "element-plus/dist/locale/fr.mjs";
import it from "element-plus/dist/locale/it.mjs";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();

const props = defineProps({
  type: {
    type: String,
    default: "text",
    validator: (prop) => ["text", "bool", "date", "url"].includes(prop),
  },
  value: {
    // null and undefined values will pass any type validation
    type: [String, Number, Boolean, Date],
    default: null,
    required: false,
  },
  label: {
    // null and undefined values will pass any type validation
    type: String,
    default: "-",
  },
});

const localValue = ref(props.value);

const emit = defineEmits(["input"]);

watch(localValue, (newValue) => {
  emit("input", newValue);
});

const pickerLocale = computed(() => {
  if (locale.value == "fr") {
    return fr;
  } else if (locale.value == "it") {
    return it;
  } else {
    return de;
  }
});
</script>

<style lang="scss" scoped></style>
