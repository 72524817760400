import { defineStore } from "pinia";
import { getCorpList } from "@/services/corp.js";

export const useCorpStore = defineStore('Corp', {
  state: () => {
    return {
      corps: null,
      mainCorp: null
    }
  },
  getters: {
    getCorpParentLevel: (state) => {
      return currentCorpId => {
        const foundCorp = state.corps.find(c => c.id === currentCorpId);
        if (!foundCorp) {
          return;
        }
        return foundCorp.level === "canton" ? "federal" : "canton";
      }
    }
  },
  actions: {
    setCorps({ corps, mainCorp }) {
      this.corps = corps;
      this.mainCorp = mainCorp;
    },
    /**
     * Retrieve *all* the corps of the system
     *
     * Retrieve all the corps in the system if they're not yet loaded, or if
     * override is set to true
     * @param {Boolean} override
     */
    async retrieveCorpList(override = false) {
      if (!override && this.corps !== null) {
        return;
      }
      const corps = await getCorpList();
      const mainCorp = corps.find(d => d.mainCorp);
      this.setCorps({ corps, mainCorp });
    }
  },
});
