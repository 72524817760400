<template>
  <nav class="nav-lang">
    <ul class="nav-lang__list flex">
      <li class="nav-lang__item">
        <button
          class="nav-lang__btn px-2"
          :class="{ 'is-active': activeLang === 'de' }"
          @click="changeLanguage('de')"
        >
          DE
        </button>
      </li>
      <li class="nav-lang__item">
        <button
          class="nav-lang__btn px-2"
          :class="{ 'is-active': activeLang === 'fr' }"
          @click="changeLanguage('fr')"
        >
          FR
        </button>
      </li>
      <li class="nav-lang__item">
        <button
          id="blue"
          class="nav-lang__btn px-2"
          :class="{ 'is-active': activeLang === 'it' }"
          @click="changeLanguage('it')"
        >
          IT
        </button>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { useLanguageStore } from "@/store/Language";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { locale } = useI18n();
const route = useRoute();
const router = useRouter();
const languageStore = useLanguageStore();

const activeLang = computed(() => languageStore.language);

const changeLanguage = (lang) => {
  locale.value = lang;
  languageStore.setLanguage(lang, route, router);
  if (route.name !== "home") {
    router.replace({ query: { lang: lang } });
  }
};
</script>

<style lang="postcss" scoped>
.nav-lang__item:not(:first-child)::before {
  content: "|";
  display: inline-block;
}

.nav-lang__btn {
  @apply font-light;

  &.is-active {
    @apply font-medium;
  }
}

/*
* TODO: This is a modifier for the parent
* the modifier will probably need to be
* bound with more text styles
* and needs a better name.
*/
.is-wide {
  & .nav-lang__btn {
    @apply tracking-xl;
  }
}
</style>
