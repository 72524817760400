import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export function setLanguageParameter() {
  const route = useRoute();
  const router = useRouter();
  const { locale } = useI18n();

  if (!route.query?.lang) {
    router.replace({ query: { lang: locale.value } });
  }
}
