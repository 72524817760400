import { defineStore } from "pinia";
import { getContactData } from "@/services/contacts.js";

export const useContactStore = defineStore('Contact', {
  state: () => {
    return {
      contactData: null
    }
  },
  actions: {
    setContactData(contactData) {
      this.contactData = contactData;
    },
    addContact(contact) {
      this.contactData.push(contact);
    },
    async retrieveContactData() {
      const result = await getContactData();
      this.setContactData(result);
    }
  }
});
