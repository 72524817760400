import { defineStore } from "pinia";
import { getGeoCategoryData } from "@/services/geocategory";
import { useLoadingStore } from "./Loading";
import { useGeoCategoryFilter, useCorpFilter } from "./Filters";

export const useGeoCategoryStore = defineStore('GeoCategory', {
  state: () => {
    return {
      geoCategoryData: null
    }
  },

  actions: {
    setGeoCategoryData(geoCategoryData) {
      this.geoCategoryData = geoCategoryData;
    },
    async retrieveGeoCategoryData() {
      const loadingStore = useLoadingStore();
      const geoCategoryFilterStore = useGeoCategoryFilter();
      const corpFilterStore = useCorpFilter();

      loadingStore.setLoading(true);
      const geoCategoryData = await getGeoCategoryData(corpFilterStore.corpFilter);
      geoCategoryFilterStore.assertIsValid(geoCategoryData)
      this.setGeoCategoryData(geoCategoryData);
      loadingStore.setLoading(false);
    }
  }
});
