/**
 * Legislative entry for search suggestion model.
 * @returns {object} JSON object containing:
 * id {number}
 * identifier {string}
 * title {string}
 * value {string}
 */
export function LegislativeEntrySearchSuggestion(legislativeEntryData) {
  this.id = legislativeEntryData.id;
  this.identifier = legislativeEntryData.identifier;
  this.title = legislativeEntryData.title;
  this.value = `${this.identifier} ${this.title}`;
}
