<template>
  <el-form v-if="row" :model="row" :rules="rules" label-position="top">
    <div class="catalog-grid catalog-grid--technical-entries">
      <div></div>

      <div style="grid-column-end: span 3">
        <form-field :label="$t('title')" :prop="'description' + language">
          <el-input
            v-model="row['description' + language]"
            type="textarea"
            rows="1"
          />
        </form-field>
      </div>

      <div>
        <div class="border-gray-400 seperator-x">
          <h4 class="text-meta">{{ $t("metaData") }}</h4>
          <ul>
            <li
              v-for="(url, i) in row.metadataUrls"
              :key="`meta-url${i}${row.id}-${position}`"
            >
              <form-field :label="$t('metaData')">
                <el-input v-model="row.metadataUrls[i].url" type="url" />
              </form-field>
              <form-field :label="$t('metaDataLabel')">
                <el-input v-model="row.metadataUrls[i].urlLabel" type="text" />
              </form-field>
              <el-button
                size="small"
                class="mt-4 has-icon"
                @click="removeMetadata(i)"
              >
                <SvgIcon name="delete-forever" />
                {{ $t("removeMetadata") }}
              </el-button>
            </li>
          </ul>

          <el-button size="small" class="mt-8 has-icon" @click="addMetadata">
            <SvgIcon name="add-circle" />
            {{ $t("addMetaData") }}
          </el-button>
        </div>
      </div>

      <div>
        <div class="border-gray-400 seperator-x">
          <form-field :label="$t('dataModelUrlLabel')">
            <el-input v-model="row['dataModelUrlLabel' + language]" />
          </form-field>
          <form-field
            :label="$t('dataModel')"
            :prop="'dataModelUrl' + language"
          >
            <el-input v-model="row['dataModelUrl' + language]" type="url" />
          </form-field>

          <form-field :label="$t('dataModelChanges')">
            <el-input
              v-model="row['dataModelChanges' + language]"
              type="textarea"
              rows="1"
            />
          </form-field>
        </div>
      </div>

      <div>
        <div class="border-gray-400 seperator-x">
          <form-field :label="$t('modelDocumentationLabel')">
            <el-input
              v-model="row['modelDocumentationUrlLabel' + language]"
              type="text"
            />
          </form-field>
          <form-field
            :label="$t('modelDocumentation')"
            :prop="'modelDocumentationUrl' + language"
          >
            <el-input
              v-model="row['modelDocumentationUrl' + language]"
              type="url"
            />
          </form-field>
        </div>
      </div>

      <div>
        <div class="border-gray-400 seperator-x">
          <form-field :label="$t('geocategory')">
            <el-select v-model="row.geoCategoryId" clearable filterable>
              <el-option
                v-for="geocategory in geoCategoryData"
                :key="`cte-${geocategory.id}${row.id}-${position}`"
                :label="` ${geocategory.notation} - ${geocategory.name}`"
                :value="geocategory.id"
              />
            </el-select>
          </form-field>
          <form-field :label="$t('contactPerson')">
            <el-select v-model="row.contactPersonId" clearable filterable>
              <el-option
                v-for="contactPerson in contactPersonStore.contactPersons"
                :key="`cte-${contactPerson.id}${row.id}-${position}`"
                :label="contactPerson.fullName"
                :value="contactPerson.id"
              />
            </el-select>
          </form-field>
        </div>
      </div>
    </div>

    <!-- ADDITONAL FIELDS -->
    <div class="catalog-grid catalog-grid--technical-entries">
      <div>&nbsp;</div>

      <div
        v-for="(field, index) in row.customFields"
        :key="'customfieldedit' + index"
      >
        <div class="border-gray-400 seperator-x">
          <custom-field
            v-model="field.values['value' + language]"
            :type="field.type"
            :label="field.label"
          />
        </div>
      </div>
    </div>

    <!-- ACTIONS BAR -->
    <div class="flex justify-between pr-8 mt-8">
      <language-display :language="language" />

      <div class="flex flex-row">
        <el-dropdown
          split-button
          class="mx-2"
          size="small"
          trigger="hover"
          @command="deleteRow"
          @click="$emit('cancel-editing')"
        >
          <span class="flex items-center">
            <SvgIcon name="cancel" class="mr-2 w-auto h-auto" />
            {{ $t("abort") }}
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :disabled="!isDraft(row)" :command="row">
                <SvgIcon
                  class="inline-block w-auto h-auto"
                  name="delete-forever"
                />
                {{ $t("quitAndDelete") }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <el-button
          size="small"
          class="has-icon"
          :disabled="!isValidForSave || isDataUnchanged || hasEndDate(row)"
          @click="doSave()"
        >
          <SvgIcon name="check-circle" />
          {{ $t("save") }}
        </el-button>
      </div>
    </div>

    <!-- PUBLISH BAR -->
    <div class="py-3 mt-8 border-t border-gray-400">
      <div class="flex items-end justify-end pr-8">
        <form-field :label="$t('suspendDate')" class="mt-0 w-64">
          <el-config-provider :locale="pickerLocale">
            <el-date-picker
              v-model="suspendDate"
              type="date"
              class="text-gray-300"
              :placeholder="$t('datePicker.pickDate')"
              :disabled="hasEndDate(row) || !isPublished(row)"
              format="DD.MM.YYYY"
            ></el-date-picker
          ></el-config-provider>
        </form-field>
        <el-button
          size="small"
          class="has-icon ml-4 mb-2"
          :disabled="hasEndDate(row) || !isPublished(row)"
          @click="suspendRow()"
        >
          <SvgIcon name="details" />
          {{ $t("cancel") }}
        </el-button>
        <div class="mt-0 ml-10 w-64">
          <input
            v-if="row.historyReference && row.status !== 'Published'"
            id="change-datamodel"
            v-model="showDataModelInput"
            type="checkbox"
          />
          <label
            v-if="row.historyReference && row.status !== 'Published'"
            for="change-datamodel"
            class="ml-2"
            >{{ $t("changeOldDataModelLink") }}
          </label>
          <form-field
            v-if="showDataModelInput"
            :label="$t('linkOldDataModelDe')"
            class="mt-0 mr-5 w-62"
          >
            <el-input v-model="row.oldReplacedDataModelUrlDe"></el-input>
          </form-field>
          <form-field
            v-if="showDataModelInput"
            :label="$t('linkOldDataModelFr')"
            class="mt-0 mt-4 w-62"
          >
            <el-input v-model="row.oldReplacedDataModelUrlFr"></el-input>
          </form-field>
          <form-field
            v-if="showDataModelInput"
            :label="$t('linkOldDataModelIt')"
            class="mt-0 mt-4 mb-6 w-62"
          >
            <el-input v-model="row.oldReplacedDataModelUrlIt"></el-input>
          </form-field>
          <form-field
            v-if="row.status !== 'Published'"
            :label="$t('pubDate')"
            class="mt-0"
          >
            <el-config-provider :locale="pickerLocale">
              <el-date-picker
                v-model="row.startDate"
                type="date"
                class="mr-2"
                :placeholder="$t('datePicker.pickDate')"
                format="DD.MM.YYYY"
              ></el-date-picker
            ></el-config-provider>
          </form-field>
        </div>
        <el-button
          v-if="row.status !== 'Published'"
          size="small"
          class="has-icon ml-4 mb-2"
          :disabled="!isValidForPublish"
          :title="publishButtonTitle"
          @click="doPublish"
        >
          <SvgIcon name="check-circle" />
          {{ $t("publish") }}
        </el-button>
      </div>
    </div>
  </el-form>
</template>

<script setup>
import CustomField from "./legalEdit/CustomField.vue";
import LanguageDisplay from "@/components/LanguageDisplay.vue";
import { cloneDeep } from "lodash";
import FormField from "@/components/form/FormField.vue";
import { confirm } from "@/composables/confirm.js";
import { notifyError } from "@/composables/notification.js";
import { TechnicalEntry } from "@/models/TechnicalEntry.model";
import { serialize as serializeTechnicalEntry } from "@/serializers/TechnicalEntry.js";
import {
  hasEndDate,
  isPublished,
  isDraft,
  isEqualEditObject,
} from "@/services/utils.js";

import {
  suspendTechnicalEntry,
  deleteTechnicalEntry,
} from "@/services/technical-entry.js";
import {
  formatToStandardTextDate,
  parseDateFromServer,
} from "../../services/utils";
import { computed, onBeforeMount, ref, h } from "vue";
import { useI18n } from "vue-i18n";
import { useGeoCategoryStore } from "@/store/GeoCategory";
import { useLanguageStore } from "@/store/Language";
import { useDateFilter } from "@/store/Filters";
import { useLegislativeCatalogStore } from "@/store/LegislativeCatalog";
import { useContactPersonStore } from "@/store/ContactPersons";
import { ElMessageBox, ElNotification } from "element-plus";
import de from "element-plus/dist/locale/de.mjs";
import fr from "element-plus/dist/locale/fr.mjs";
import it from "element-plus/dist/locale/it.mjs";

const geoCategoryStore = useGeoCategoryStore();
const languageStore = useLanguageStore();
const dateFilter = useDateFilter();
const legislativeCatalogStore = useLegislativeCatalogStore();
const contactPersonStore = useContactPersonStore();
const { t, locale } = useI18n();

const props = defineProps({
  baseRow: {
    type: Object,
    required: true,
  },
  parentRow: {
    type: Object,
    required: true,
  },
  position: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["cancel-editing"]);

const showDataModelInput = ref(false);
const suspendDate = ref(new Date());
const row = ref("");
const language = ref("De");
const originRow = ref("");

const rules = ref({
  description: [
    {
      required: true,
      message: t("FormValidation.notEmpty"),
      trigger: "blur",
    },
  ],
  dataModelUrl: [
    {
      type: "url",
      message: t("FormValidation.notUrl"),
      trigger: "blur",
    },
  ],
  modelDocumentationUrl: [
    {
      type: "url",
      message: t("FormValidation.notUrl"),
      trigger: "blur",
    },
  ],
  descriptionDe: [
    {
      required: true,
      message: t("FormValidation.notEmpty"),
      trigger: "blur",
    },
  ],
  dataModelUrlDe: [
    {
      type: "url",
      message: t("FormValidation.notUrl"),
      trigger: "blur",
    },
  ],
  modelDocumentationUrlDe: [
    {
      type: "url",
      message: t("FormValidation.notUrl"),
      trigger: "blur",
    },
  ],
  descriptionFr: [
    {
      required: true,
      message: t("FormValidation.notEmpty"),
      trigger: "blur",
    },
  ],
  dataModelUrlFr: [
    {
      type: "url",
      message: t("FormValidation.notUrl"),
      trigger: "blur",
    },
  ],
  modelDocumentationUrlFr: [
    {
      type: "url",
      message: t("FormValidation.notUrl"),
      trigger: "blur",
    },
  ],
  descriptionIt: [
    {
      required: true,
      message: t("FormValidation.notEmpty"),
      trigger: "blur",
    },
  ],
  dataModelUrlIt: [
    {
      type: "url",
      message: t("FormValidation.notUrl"),
      trigger: "blur",
    },
  ],
  modelDocumentationUrlIt: [
    {
      type: "url",
      message: t("FormValidation.notUrl"),
      trigger: "blur",
    },
  ],
});

const geoCategoryData = computed(() => geoCategoryStore.geoCategoryData || []);

const isDataUnchanged = computed(() => {
  return isEqualEditObject(originRow.value, row.value);
});

const isValidForSave = computed(() => {
  return (
    (row.value["description" + language.value] !== null &&
      row.value["description" + language.value] !== "") ||
    (row.value.description !== null && row.value.description !== "")
  );
});

const isValidForPublish = computed(() => {
  function isValid(field) {
    return field !== null && field !== "";
  }
  return [
    isValid(row.value["description" + language.value]),
    isValid(row.value.geoCategory),
    isValid(row.value.startDate),
    row.value.status === "Draft",
  ].every((item) => {
    return item;
  });
});

const pickerLocale = computed(() => {
  if (locale.value == "fr") {
    return fr;
  } else if (locale.value == "it") {
    return it;
  } else {
    return de;
  }
});

const publishButtonTitle = computed(() => {
  return isValidForPublish.value ? "" : t("technicalEntryPublishValidation");
});

const removeMetadata = (metadataIndex) => {
  row.value.metadataUrls.splice(metadataIndex, 1);
};

const addMetadata = () => {
  row.value.metadataUrls.push({ url: "", label: "" });
};

const suspendRow = () => {
  let suspendAction = () => {
    suspendTechnicalEntry(row.value.id, suspendDate.value).then(
      (response) => {
        let legalEntry = props.parentRow;
        let technicalEntries = legalEntry.technicalEntries;
        let foundIndex = technicalEntries.findIndex(
          (element) => element.id === row.value.id
        );
        if (dateFilter.dateUntil > new Date(response.data.endDate)) {
          technicalEntries.splice(foundIndex, 1);
        } else {
          const data = row.value
          data.endDate = response.data
          technicalEntries.splice(
            foundIndex,
            1,
            new TechnicalEntry(data)
          );
        }

        legislativeCatalogStore.replaceLegalRow(legalEntry);

        emit("cancel-editing");
        //display the successmessage in form of a notification
        ElNotification({
          title: t("cancel"),
          message: h("span", { style: "color: teal" }, t("cancelSuccess")),
        });
      },
      () => {
        notifyError(t("genericError"));
      }
    );
  };
  // promt the user
  confirm(t, t("irreversible"), t("cancel"), suspendAction);
};

const deleteCallback = (deletedRowId) => {
  //display the successmessage in form of a notification
  ElNotification({
    title: t("cancel"),
    message: h("span", { style: "color: teal" }, t("cancelSuccess")),
  });
  // refresh data
  let legalEntry = props.parentRow;
  let technicalEntries = legalEntry.technicalEntries;
  let foundIndex = technicalEntries.findIndex(
    (element) => element.id === deletedRowId
  );
  technicalEntries.splice(foundIndex, 1);
  legislativeCatalogStore.replaceLegalRow(legalEntry);
};

const deleteRow = (row) => {
  // promt the user
  let itemId = row.id;
  ElMessageBox.confirm(t("irreversible"), t("deleteAction"), {
    confirmButtonText: t("yes"),
    cancelButtonText: t("no"),
    type: "warning",
  }).then(() => {
    // suspend it on yes
    deleteTechnicalEntry(itemId).then(
      () => {
        deleteCallback(itemId);
      },
      (error) => {
        notifyError(error);
      }
    );
  });
};

/**
 * Handle publish button click
 *
 * Save the data, then call the publish endpoint to publish the record
 */
const doPublish = async () => {
  try {
    let response = await saveRecord(true);

    const formattedDate = formatToStandardTextDate(
      parseDateFromServer(row.value.startDate)
    );
    const data = {
      id: row.value.id,
      payload: {
        startDate: formattedDate,
      },
    };
    if (showDataModelInput.value) {
      data["payload"]["oldReplacedDataModelUrlDe"] =
        row.value.oldReplacedDataModelUrlDe;
      data["payload"]["oldReplacedDataModelUrlFr"] =
        row.value.oldReplacedDataModelUrlFr;
      data["payload"]["oldReplacedDataModelUrlIt"] =
        row.value.oldReplacedDataModelUrlIt;
    }
    await legislativeCatalogStore.publishTechnicalRecord(data);

    emit("cancel-editing");

    // we get the data from the save request and not from the publish request,
    // because the publish api does not return the full object. Therefore we
    // need to update the status manually here, as a little nice hack.
    response.data.status = "Published";

    let legalEntry = props.parentRow;
    let new_entry = new TechnicalEntry(response.data);
    let technicalEntries = legalEntry.technicalEntries;

    // if there is a published version of our entry, we need to remove it,
    // as it is now archived, due to the old draft being published
    let publishedIndex = technicalEntries.findIndex(
      (el) => el.label === new_entry.label && el.status === "Published"
    );
    if (publishedIndex > -1) {
      technicalEntries.splice(publishedIndex, 1);
    }

    // replace the published entry with itself, so the status is updated
    let foundIndex = technicalEntries.findIndex(
      (element) => element.id === row.value.id
    );

    // add the unserialized row with all values we need
    technicalEntries.splice(foundIndex, 1, new_entry);

    legislativeCatalogStore.replaceLegalRow(legalEntry);

    notifySuccess(t("technicalEntryPublishSuccess"));
  } catch (error) {
    ElNotification.error({
      title: t("saveError"),
      message: error.response.data,
    });
  }
};

/**
 * Save the current record
 */
const saveRecord = async (allLang = false) => {
  const saveData = serializeTechnicalEntry(row.value);
  const id = row.value.id;

  return await legislativeCatalogStore.saveTechnicalRecord({
    id,
    data: saveData,
    allLang,
  });
};

/**
 * Handle the save button click
 *
 * Save the data, then show some success message
 */
const doSave = async () => {
  try {
    let saved = await saveRecord();

    let legalEntry = props.parentRow;
    // update the legalEntry object
    let technicalEntries = legalEntry.technicalEntries;
    let new_entry = new TechnicalEntry(saved.data);
    let foundIndex = technicalEntries.findIndex(
      (element) => element.id === row.value.id
    );

    // add the unserialized row with all values we need
    if (new_entry.status === row.value.status) {
      technicalEntries.splice(foundIndex, 1, new_entry);
    } else {
      technicalEntries.splice(foundIndex + 1, 0, new_entry);
    }
    legislativeCatalogStore.replaceLegalRow(legalEntry);
    emit("cancel-editing");
    notifySuccess(t("technicalEntrySuccess"));
  } catch (error) {
    const emptyField =
      error.response.data["nonFieldErrors"][0] || error.response.data;
    const errorMsg = t("FormValidation.notEmpty") + ": " + t(emptyField);
    ElNotification.error({
      title: t("saveError"),
      message: errorMsg,
    });
  }
};

const notifySuccess = (message) => {
  ElNotification({
    title: t("technicalEntryDataset") + " " + row.value.label,
    message: h("span", { style: "color: teal" }, message),
  });
};

onBeforeMount(async () => {
  row.value = cloneDeep(props.baseRow);
  originRow.value = cloneDeep(props.baseRow);
  // first letter needs to be uppercased
  let lang =
    row.value.language ||
    languageStore.getLanguage ||
    localStorage.getItem("lang") ||
    "De";
  language.value = lang.charAt(0).toUpperCase() + lang.slice(1);
});
</script>

<style lang="postcss" scoped></style>
