<template>
  <nav class="nav-mobile" role="navigation">
    <ul>
      <li>
        <router-link class="nav-mobile__link" to="/">
          <site-logo class="mx-auto" />
        </router-link>
      </li>
      <li>
        <router-link class="nav-mobile__link" to="/">
          {{ $t("views.catalog") }}
        </router-link>
      </li>
      <li>
        <router-link class="nav-mobile__link" to="/contacts">
          {{ $t("views.contacts") }}
        </router-link>
      </li>
      <li v-if="userStore.isLoggedIn">
        <router-link class="nav-mobile__link" to="/administration">
          {{ $t("views.admin") }}
        </router-link>
      </li>
    </ul>
    <nav-lang class="flex justify-center mt-8" />
  </nav>
</template>

<script setup>
import NavLang from "@/components/NavLang.vue";
import SiteLogo from "@/components/SiteLogo.vue";
import { useUserStore } from "@/store/User";

const userStore = useUserStore();

</script>

<style lang="postcss" scoped>
.nav-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - theme(spacing.16));
}

.nav-mobile__link {
  @apply block py-1;
  @apply text-xl text-black text-center;
  @apply font-regular tracking-2xl;

  &.router-link-exact-active {
    @apply font-medium;
    @apply border-orange-500;
  }
}
</style>
