import { defineStore } from "pinia";

export const useLoadingStore = defineStore('LoadingState', {
  state: () => {
    return {
      loading: true,
      loadingCount: 0
    }
  },
  actions: {
    setLoading(loading) {
      this.loading = loading;
      if (this.loading) {
        this.loadingCount++;
        this.loading = true;
      } else if (this.loadingCount > 0) {
        this.loadingCount--;
        this.loading = this.loadingCount > 0;
      } else {
        this.loading = false;
        this.loadingCount = 0;
      }
    }
  }
});
