// MODEL
/**
 * custom field model
 * @returns {object} JSON object containing
 * labels, values and type of additional input fields
 * possible types: ‘text’ ‘date’ ‘bool’ 'url’
 *
 */
export function CustomField(d) {
  this.id = d.id;
  this.values = d.values || {
    id: null,
    customFieldId: null,
    value: null,
    valueDe: null,
    valueFr: null,
    valueIt: null,
    valueRm: null
  };
  this.label = d.label || "";
  this.labelDe = d.labelDe || null;
  this.labelFr = d.labelFr || null;
  this.labelIt = d.labelIt || null;
  this.labelRm = d.labelRm || null;
  this.type = d.type || "text";

  // all fields are text
  // this is a worst case fallback for dates
  if (this.values.value === "Invalid Date") this.values.value = "";
  if (this.values.valueDe === "Invalid Date") this.values.valueDe = "";
  if (this.values.valueFr === "Invalid Date") this.values.valueFr = "";
  if (this.values.valueIt === "Invalid Date") this.values.valueIt = "";
  if (this.values.valueRm === "Invalid Date") this.values.valueRm = "";
}
