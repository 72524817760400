<template>
  <component :is="iconPath" :key="name" :class="className" />
</template>

<script setup>
import { computed, defineAsyncComponent } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: null,
  },
});

const iconPath = computed(() =>
  defineAsyncComponent(() => import(`../assets/icons/${props.name}.svg`))
);

const className = computed(() => `svg-icon svg-icon--${props.name}`);
</script>
