import api from "./api";
import { useUserStore } from "@/store/User";

/**
 * Login to the backend
 *
 * Exceptions are intentionally not handled and passed to the caller
 * @param {string} username
 * @param {string} password
 * @returns {string} JWT Token
 */
const login = async (username, password) => {
  const response = await api.post("token/", {
    email: username,
    password
  });
  return {
    token: response.data.access,
    refresh: response.data.refresh,
    firstName: response.data.firstName,
    lastName: response.data.lastName,
    corp: response.data.corp,
    id: response.data.id,
    takeoverCorp: response.data.takeoverCorp,
    takeoverCorpDisplay: response.data.takeoverCorpDisplay,
    corpLevel: response.data.corpLevel,
    crudCorpsIds: response.data.crudCorpsIds
  };
};

export const refreshToken = async token => {
  const response = await api.post("token/refresh/", { refresh: token });

  return response.data.access;
};

export const refreshData = async () => {
  const response = await api.get("token/refresh-data/");
  return {
    firstName: response.data.firstName,
    lastName: response.data.lastName,
    corp: response.data.corp,
    corpLevel: response.data.corpLevel,
    id: response.data.id,
    takeoverCorp: response.data.takeoverCorp,
    takeoverCorpDisplay: response.data.takeoverCorpDisplay,
    crudCorpsIds: response.data.crudCorpsIds
  };
};

export const setTakeOverCorp = async corp => {
  const userStore = useUserStore();
  let userId = userStore.id;
  if (userId) {
    return api.patch(`users/${userId}/`, {
      takeoverCorp: corp
    });
  }
};

export { login };
