import {
  getContactPersons,
  createNewContactPerson,
  saveContactPerson,
  deleteContactPerson
} from "@/services/contactpersons.js";
import { filter } from "lodash";
import { defineStore } from "pinia";

export const useContactPersonStore = defineStore('ContactPerson', {
  state: () => {
    return {
      contactPersons: null
    };
  },
  actions: {
    setContactData(contactData) {
      this.contactPersons = contactData;
    },
    addContact(contact) {
      this.contactPersons.push(contact);
    },
    removeContact(id) {
      this.contactPersons = filter(
        this.contactPersons,
        entry => entry.id != id
      );
    },
    async retrieveContactPersonsData() {
      const result = await getContactPersons();
      this.setContactData(result);
    },
    async addContactPerson() {
      const contact = await createNewContactPerson();
      this.addContact(contact);
    },
    async saveRecord({ pk, data }) {
      return await saveContactPerson(pk, data);
    },
    async removeContactPerson(id) {
      const removeContactPersonPromise = new Promise((resolve, reject) => {
        deleteContactPerson(id)
          .then(() => {
            setTimeout(() => {
              this.removeContact(id);
            }, 0);

            resolve();
          })
          .catch(err => reject(err.response.data));
      });
      return removeContactPersonPromise;
    }
  }
});
