<template>
  <el-table
    ref="tableData"
    :key="'table' + language"
    :empty-text="$t('empty')"
    :data="tableDataToShow"
    row-key="id"
    :row-class-name="rowClass"
    class="is-clickable is-orange"
    @expand-change="handleExpand"
    @row-click="handleExpandClick"
  >
    <template #empty>
      <svg-loader v-if="loading" />
    </template>

    <!-- edit col -->
    <el-table-column type="expand" width="45">
      <template #default="props">
        <div v-if="isExpanded(props.row.id)">
          <transition name="reveal">
            <catalog-legal-entry-edit
              v-if="props.row.isEditing"
              :base-row="props.row"
              class="form-edit-offset"
              @leave-editmode="leaveEditMode(props.row)"
              @published="handlePublish(props.row)"
              @saved="handleSave(props.row)"
            />
          </transition>
          <div>
            <div v-if="!props.row.isEditing" class="catalog-grid offset-l">
              <div>
                <div class="mt-0 seperator-x">
                  <catalog-attribute :title="$t('legalBasis')">
                    <dl v-if="props.row.legalBases.length">
                      <div
                        v-for="(item, i) in props.row.legalBases"
                        :key="i + props.row.id"
                        class="flex flex-col mt-2 md:flex-row"
                      >
                        <dt class="md:w-32 legalBaseLink">
                          <a :href="item.url" :title="item.name">{{
                            item.code
                          }}</a>
                        </dt>
                        <dd>
                          {{
                            item["example" + languageUppercase] || item.example
                          }}
                        </dd>
                      </div>
                    </dl>
                    <p v-else class="text-gray-750">{{ $t("empty") }}</p>
                  </catalog-attribute>
                </div>
              </div>

              <div>
                <catalog-attribute
                  :title="$t('access')"
                  class="mt-0 seperator-x"
                >
                  {{ props.row.access === "public" ? $t("accessLabel.public") : "" }}
                  {{
                    props.row.access === "public_restricted"
                      ? $t("accessLabel.public_restricted")
                      : ""
                  }}
                  {{ props.row.access === "private" ? $t("accessLabel.private") : "" }}
                </catalog-attribute>

                <catalog-attribute
                  :title="$t('downloadService')"
                  class="mt-4"
                  >{{
                    props.row.downloadService ? $t("yes") : $t("no")
                  }}</catalog-attribute
                >
              </div>

              <div>
                <catalog-attribute
                  :title="$t('oereb')"
                  class="mt-0 seperator-x"
                  >{{
                    props.row.oereb ? $t("yes") : $t("no")
                  }}</catalog-attribute
                >
                <catalog-attribute :title="$t('geoReference')" class="mt-4">{{
                  props.row.geoReference ? $t("yes") : $t("no")
                }}</catalog-attribute>
              </div>

              <div class>
                <catalog-attribute
                  :title="$t('pubDate')"
                  class="mt-0 mr-6 seperator-x"
                >
                  <div v-if="props.row.startDate" class="flex">
                    <SvgIcon name="calendar"></SvgIcon>
                    <span class="inline-block ml-2">{{
                      formatDateToDDMMYYYY(props.row.startDate)
                    }}</span>
                  </div>
                  <span v-else>&mdash;</span>
                </catalog-attribute>
                <catalog-attribute
                  v-if="props.row.isHistorised"
                  :title="$t('endDate')"
                  class="mt-0"
                >
                  <div v-if="props.row.endDate" class="flex">
                    <SvgIcon name="calendar"></SvgIcon>
                    <span class="inline-block ml-2">{{
                      formatDateToDDMMYYYY(props.row.endDate)
                    }}</span>
                  </div>
                  <span v-else>&mdash;</span>
                </catalog-attribute>
              </div>

              <div
                v-for="(field, customFieldIndex) in props.row.customFields"
                :key="'customField' + customFieldIndex"
              >
                <div class="mt-0 seperator-x">
                  <catalog-attribute :title="field.label" class="mt-0">
                    <div v-if="field.type === 'date'" class="flex">
                      <span class="inline-block">{{
                        formatDateToDDMMYYYY(field.values.value)
                      }}</span>
                    </div>
                    <div v-else-if="field.type !== 'bool'" class="flex">
                      <span class="inline-block">{{
                        field.values["value" + languageUppercase] ||
                        field.values.value
                      }}</span>
                    </div>
                    <div v-else class="flex">
                      <span
                        v-if="field.values.value === 'False'"
                        class="inline-block"
                        >{{ $t("no") }}</span
                      >
                      <span
                        v-else-if="field.values.value === 'True'"
                        class="inline-block"
                        >{{ $t("yes") }}</span
                      >
                      <span v-else class="inline-block">-</span>
                    </div>
                  </catalog-attribute>
                </div>
              </div>
            </div>
            <div
              class="flex justify-end pr-8 mb-3"
              role="button"
              :title="directLinkPrefix + props.row.id + '/?lang=' + locale"
              @click="copy(props.row.id)"
            >
              <SvgIcon name="copy-link" class="mr-2" />
              {{ $t("copyDirectLink") }}
            </div>
            <div v-if="!props.row.isEditing" class="pr-8 mt-2 offset-l">
              <div class="flex justify-end py-3 border-t">
                <div class="pr-8">
                  <el-button
                    v-if="
                      editModeIsEnabled(props.row) &&
                      (props.row.differsFromParent ||
                        props.row.softDiffersFromParent)
                    "
                    size="small"
                    :class="getDiffersFromParentButtonClasses(props.row)"
                    @click.prevent.stop="
                      actionRetrievePublishedParentToCompare(props.row)
                    "
                  >
                    <span class="flex items-center">
                      <SvgIcon name="wand-magic-sparkles" class="mr-2" />
                      <span>{{ $t("fixFromParent") }}</span>
                    </span>
                  </el-button>
                </div>
                <div class="pr-8">
                  <el-dropdown
                    v-if="editModeIsEnabled(props.row)"
                    split-button
                    size="small"
                    trigger="click"
                    class="align-middle"
                    @command="handleEditClick"
                    @click="handleEditClick({ lang: language, row: props.row })"
                  >
                    <span class="flex items-center">
                      <SvgIcon name="edit" class="mr-2 !h-auto !w-auto" />
                      <span>{{ $t("edit") }}</span>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          :command="{ lang: 'de', row: props.row }"
                          >{{ $t("editGerman") }}</el-dropdown-item
                        >
                        <el-dropdown-item
                          :command="{ lang: 'fr', row: props.row }"
                          >{{ $t("editFrench") }}</el-dropdown-item
                        >
                        <el-dropdown-item
                          :command="{ lang: 'it', row: props.row }"
                          >{{ $t("editItalian") }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </div>
            </div>

            <!--
          TECHNICAL ENTRIES
        -->
            <div class="py-2 bg-gray-300" v-if="isLoggedIn || props.row.technicalEntries.length > 0">
              <div class="pr-8 offset-l">
                <div class="flex items-center justify-between pr-8 ml-2">
                  <h2 class="text-base font-medium text-black">
                    {{ $t("technicalRecords") }}
                    <el-tag
                      v-if="
                        hasWritePermission(props.row) &&
                        props.row.decoupledFromParent
                      "
                      size="small"
                      class="mr-2 decoupledFromParent"
                      :disable-transitions="true"
                      >{{ $t(getCorpParentLevelDisplay()) }}&nbsp;{{
                        $t("decoupledFromParent")
                      }}
                    </el-tag>
                  </h2>
                  <el-button
                    v-if="checkIsToday() && editModeIsEnabled(props.row)"
                    size="small"
                    class="my-3 ml-2 has-icon"
                    @click.prevent.stop="createTechnicalEntry(props.row)"
                  >
                    <SvgIcon name="add-circle" />
                    {{ $t("addTechnicalRecord") }}
                  </el-button>
                </div>
              </div>

              <el-collapse
                v-if="props.row.technicalEntries"
                v-model="collapseTechnical"
                class="border-0"
              >
                <el-collapse-item
                  v-for="(child, i) in props.row.technicalEntries"
                  :key="props.row + child.id"
                  :name="i"
                  class="pl-2 mr-8 offset-l"
                >
                  <template #title>
                    <div class="w-full catalog-grid catalog-grid--collapse">
                      <div class="flex items-center">
                        <span class="px-2 whitespace-no-wrap">
                          <span>{{ child.partialLabel }}</span>
                          <span class="text-orange-400">{{
                            child.labelDelegationAndNumber
                          }}</span>
                        </span>
                        <el-tag
                          v-if="
                            child.status !== 'Published' && !child.hideLabel
                          "
                          :type="getTagType(child.status)"
                          :class="child.status"
                          size="small"
                          :disable-transitions="true"
                          >{{ $t(child.status) }}
                        </el-tag>
                        <div
                          class="flex items-center justify-between w-full px-2"
                        >
                          <div class="mr-8">
                            <span>{{
                              child["description" + languageUppercase] ||
                              child.description
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center justify-center w-full py-1">
                        <el-popover
                          v-if="child.contactPerson && !child.isHistorised"
                          placement="left"
                          class="flex justify-end mx-2"
                        >
                          <ul class="leading-relaxed">
                            <li>
                              {{ child.contactPerson.firstName }}
                              {{ child.contactPerson.lastName }}
                            </li>
                            <li>
                              <a
                                :href="`mailto:${child.contactPerson.email}`"
                                >{{ child.contactPerson.email }}</a
                              >
                            </li>
                            <li>
                              <a :href="`tel:${child.contactPerson.phone}`">{{
                                child.contactPerson.phone
                              }}</a>
                            </li>
                            <li>{{ child.contactPerson.authority || "" }}</li>
                          </ul>
                          <template #reference>
                            <button @click.prevent="stopPropagation()">
                              <SvgIcon name="assignment-ind" />
                            </button>
                          </template>
                        </el-popover>
                      </div>
                    </div>
                  </template>

                  <transition name="reveal">
                    <catalog-technical-entry-edit
                      v-if="editingTechRows.indexOf(child.id) !== -1"
                      :item="child"
                      :base-row="child"
                      :parent-row="props.row"
                      :position="i"
                      @cancel-editing="cancelTechEdit(child)"
                    />
                  </transition>

                  <catalog-technical-entry
                    v-if="editingTechRows.indexOf(child.id)"
                    :item="child"
                  >
                    <div class="flex justify-end mr-8">
                      <el-dropdown
                        v-if="
                          editModeIsEnabled(props.row) &&
                          techEditModeIsEnabled(
                            props.row.technicalEntries,
                            child
                          ) &&
                          !child.isHistorised
                        "
                        split-button
                        size="small"
                        trigger="click"
                        @command="handleTechEditClick"
                        @click.prevent.stop="
                          handleTechEditClick({ lang: language, row: child })
                        "
                      >
                        <span class="flex items-center">
                          <SvgIcon name="edit" class="mr-2 !h-auto !w-auto" />
                          <span>{{ $t("edit") }}</span>
                        </span>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item
                              :command="{ lang: 'de', row: child }"
                              >{{ $t("editGerman") }}
                            </el-dropdown-item>
                            <el-dropdown-item
                              :command="{ lang: 'fr', row: child }"
                              >{{ $t("editFrench") }}
                            </el-dropdown-item>
                            <el-dropdown-item
                              :command="{ lang: 'it', row: child }"
                              >{{ $t("editItalian") }}
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </div>
                  </catalog-technical-entry>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </template>
    </el-table-column>

    <!-- ID col -->
    <el-table-column width="100">
      <template #header>
        <span class="flex items-center w-full">{{ $t("id") }}</span>
      </template>
      <template #default="scope">
        <span class="inline-block mr-2">
          <span>{{ removeCH(scope.row.partialIdentifier) }}</span>
          <span class="text-orange-400">{{
            scope.row.identifierDelegation
          }}</span>
        </span>
      </template>
    </el-table-column>

    <!-- Bezeichnung col -->
    <el-table-column min-width="220">
      <template #header>
        <span class="flex items-center w-full">{{ $t("title") }}</span>
      </template>
      <template #default="scope">
        <span class="inline-block mr-2">{{
          scope.row["title" + languageUppercase] || scope.row.title
        }}</span>
        <el-tag
          v-if="scope.row.isParent"
          size="small"
          class="mr-2 parent"
          :disable-transitions="true"
        >
          {{
            scope.row.legislativeCorp.level
              ? $t(scope.row.legislativeCorp.level)
              : scope.row.legislativeCorp
          }}
        </el-tag>
        <el-tag
          v-if="scope.row.status !== 'Published' && !scope.row.hideLabel"
          size="small"
          class="mr-2"
          :type="getTagType(scope.row.status)"
          :disable-transitions="true"
          >{{ $t(scope.row.status) }}
        </el-tag>
        <el-tag
          v-if="hasWritePermission(scope.row) && scope.row.differsFromParent"
          size="small"
          class="mr-2 differs"
          :title="
            $t('differsFromParentMouseOver', {
              parent: getI18nCorpParentLevelDisplay(),
            })
          "
          :disable-transitions="true"
        >
          {{ $t("differsFromParent") }}
        </el-tag>
      </template>
    </el-table-column>

    <!-- Fachstelle col -->
    <el-table-column width="220">
      <template #header>
        <span class="flex items-center w-full" :title="$t('unitDescription')">{{
          $t("unit")
        }}</span>
      </template>
      <template #default="scope">
        <span>
          {{ buildLegislativeCorp(scope.row.legislativeCorp) }}
        </span>
        <span> / </span>
        <span v-if="!scope.row.legislativeAuthorities.length">—</span>
        <a
          v-for="(item, i) in scope.row.legislativeAuthorities"
          :key="'legislativeurl' + i"
          :href="item['url' + languageUppercase] || item.url"
          target="_blank"
          class="mr-2"
        >
          {{ item["name" + languageUppercase] || item.name }}
          {{ item["abbr" + languageUppercase] || item.abbr }}
          <span
            v-if="
              scope.row.legislativeAuthorities.length > 1 &&
              scope.row.legislativeAuthorities.length != i + 1
            "
            >,
          </span>
        </a>
      </template>
    </el-table-column>

    <!-- Delegation col -->
    <el-table-column width="220">
      <template #header>
        <span
          class="flex items-center w-full"
          :title="$t('delegationDescription')"
          >{{ $t("delegation") }}</span
        >
      </template>
      <template #default="scope">
        <span
          :title="scope.row.delegation === 'none' ? $t('delegationEmpty') : ''"
          >{{ translateDelegation(scope.row.delegation) }}</span
        >
      </template>
    </el-table-column>

    <!-- Zuständige stelle col -->
    <el-table-column width="220">
      <template #header>
        <span
          class="flex items-center w-full"
          :title="$t('responsibilityDescription')"
        >
          {{ $t("responsibility") }}
        </span>
      </template>
      <template #default="scope">
        <a
          v-for="(item, i) in scope.row.executiveAuthorities"
          :key="'executiveurl' + i"
          :href="item['url' + languageUppercase] || item.url"
          target="_blank"
          class="mr-2"
        >
          {{ item["name" + languageUppercase] || item.name }}
          {{ item["abbr" + languageUppercase] || item.abbr }}
          <span
            v-if="
              scope.row.executiveAuthorities.length > 1 &&
              scope.row.executiveAuthorities.length != i + 1
            "
            >,
          </span>
        </a>
        <span v-if="!scope.row.executiveAuthorities.length">—</span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script setup>
import { ref, computed, onMounted, watch, nextTick } from "vue";
import { formatDateToDDMMYYYY } from "@/composables/customFormatDate.js";
import SvgLoader from "@/components/SvgLoader.vue";

import CatalogAttribute from "@/components/catalog/CatalogAttribute.vue";
import CatalogTechnicalEntry from "@/components/catalog/CatalogTechnicalEntry.vue";
import CatalogTechnicalEntryEdit from "@/components/catalog/CatalogTechnicalEntryEdit.vue";
import CatalogLegalEntryEdit from "@/components/catalog/CatalogLegalEntryEdit.vue";

import { getLegislativeDetail } from "@/services/legislative-entry";
import { isToday } from "date-fns";
import { LegislativeEntryLight } from "../../models/LegislativeEntryLight.model";

import {
  useFiltersFacade,
  useParentFilter,
  useCorpFilter,
  useDateFilter,
} from "@/store/Filters";
import { useLegislativeCatalogStore } from "@/store/LegislativeCatalog";
import { useCorpStore } from "@/store/Corp";
import { useLoadingStore } from "@/store/Loading";
import { useUserStore } from "@/store/User";
import { useLanguageStore } from "@/store/Language";

import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { ElNotification } from "element-plus";

// stores
const filtersFacadeStore = useFiltersFacade();
const legislativeCatalogStore = useLegislativeCatalogStore();
const corpStore = useCorpStore();
const parentFilterStore = useParentFilter();
const corpFilterStore = useCorpFilter();
const dateFilterStore = useDateFilter();
const loadingStore = useLoadingStore();
const userStore = useUserStore();
const languageStore = useLanguageStore();
const route = useRoute();
const { t, locale } = useI18n();

// Data
const expanded = ref([]);
const collapseTechnical = ref([]);
const editingTechRows = ref([]);
const tableData = ref(null);

// Computed
const language = computed(() => languageStore.language);
const loading = computed(() => loadingStore.loading);

const isLoggedIn = computed(() => {
  return userStore.isLoggedIn;
});

const tableDataToShow = computed(() => {
  if (parentFilterStore.showParent) {
    return legislativeCatalogStore.unpackedListWithParents;
  }
  // Be sure to never show the parent, event if we receive it (after edition for instance)
  return legislativeCatalogStore.unpackedListWithParents.filter(
    (entry) => !entry.isParent
  );
});

const userCorp = computed(() => userStore.corp);

const mappedUserCorp = computed(() => {
  let matchedUserCorp = corpStore.corps.filter(
    (corp) => userCorp.value.toString() === corp.id.toString()
  );
  return matchedUserCorp[0].id;
});

const languageUppercase = computed(() => {
  return (
    languageStore.language.charAt(0).toUpperCase() +
    languageStore.language.slice(1)
  );
});

const directLinkPrefix = computed(() => {
  return window.origin + "/detail/";
});

// Methods
const getTagType = (status) => {
  if (status === "Draft") {
    return "warning";
  } else if (status === "Archived") {
    return "primary";
  } else if (status === "Feedback") {
    return "success";
  } else {
    return "";
  }
};

const removeCH = (value) => {
  if (!value) return "";
  return value.replace("-CH", "");
};

const expand = (row) => {
  expanded.value = [...expanded.value, row.id];
};

const collapse = (row) => {
  const index = expanded.value.findIndex((id) => id === row.id);
  expanded.value.splice(index, 1);
};

const isExpanded = (rowId) => {
  return expanded.value.includes(rowId);
};

const actionClearMustExpand = () => {
  legislativeCatalogStore.clearMustExpand();
};

const actionClearMustCollapse = () => {
  legislativeCatalogStore.clearMustCollapse();
};

const buildLegislativeCorp = (i) => {
  // example: Kanton(level) Argau(the name in the right language)
  let levelPrefix = t(`level.${i.level}`) || "";
  if (i.level === "federal") levelPrefix = "";
  let legCorp = i["name" + languageUppercase.value] || i.name;
  return `${levelPrefix} ${legCorp}`;
};

const checkIsToday = () => {
  return isToday(dateFilterStore.dateUntil);
};

/** Check if the user has enough permissions to edit the row */
const hasWritePermission = (currentRow) => {
  // if we arent logged in we cant edit
  if (!isLoggedIn.value) return false;
  // if we are in reviewmode we cant edit
  if (route.params.reviewid) return false;
  // if user can't write in this corporation (read only), we can't edit
  if (!userStore.crudCorpsIds.includes(currentRow.executiveCorp.id)) {
    return false;
  }
  // Finally, returns true if the user has rights on this row.
  return mappedUserCorp.value === currentRow.legislativeCorp.id;
};

/** Check if the row can be edited. */
const entryIsEditable = (currentRow) => {
  // if we are historised we cant edit
  if (currentRow.isHistorised) return false;
  // if the user corp is not same as the rowcorp we cant edit

  // if current row is not published we always edit
  const rowIsPublished = currentRow.status === "Published";
  if (!rowIsPublished) return true;

  const currentIdentifier = currentRow.identifier;
  // Check if there is a another element with same identifier but in draft status.
  const identifierAlreadyExistsInDraft =
    legislativeCatalogStore.unpackedListWithParents.some((row) => {
      return (
        row.identifier === currentIdentifier &&
        row.status !== "Published" &&
        row.status !== "Archived"
      );
    });
  // If there are not equivalent draft entry, allows the edition if the dateUntil is "today".
  return !identifierAlreadyExistsInDraft && checkIsToday();
};

const editModeIsEnabled = (currentRow) => {
  return hasWritePermission(currentRow) && entryIsEditable(currentRow);
};

const techEditModeIsEnabled = (entries, current) => {
  // if current entry is not published it can be edited anyways
  if (current.status !== "Published") return true;

  // use some to return true or false if there is a another element with same label and it is not published
  const identifierAlreadyExistsInDraft = entries.some((row) => {
    return (
      row.labelNumber === current.labelNumber &&
      row.status !== "Published" &&
      row.status !== "Archived"
    );
  });
  return !identifierAlreadyExistsInDraft;
};

const rowClass = (row) => {
  return expanded.value.includes(row.row.id) ? "expanded" : "";
};

const leaveEditMode = (row) => {
  row.isEditing = false;
};

const handleSave = async (row) => {
  await loadRowDetails(row);
  tableData.value.toggleRowExpansion(row, false);
  collapse(row);
};

const handlePublish = async (row) => {
  await loadRowDetails(row);
  tableData.value.toggleRowExpansion(row, false);
  collapse(row);
};

const loadRowDetails = async (row) => {
  // get data
  const legalEntryDetail = await getLegislativeDetail(
    row.id,
    filtersFacadeStore.filtersValues
  );
  // If it's parent keep a copy to don't break the workflow
  // On pushing new entry in the legislativeList (and
  // recompute the unpackedListWithParents list).
  const isParent = legislativeCatalogStore.legislativeList.some((entry) => {
    if (!entry.parent) return false;
    return entry.parent.id === legalEntryDetail.id;
  });
  if (isParent) {
    legislativeCatalogStore.addDetailedParentEntry(legalEntryDetail);
  } else {
    // Replace row data in not-parent elements
    const index = legislativeCatalogStore.legislativeList.findIndex(
      (item) => item.id === row.id
    );
    legislativeCatalogStore.legislativeList.splice(index, 1, legalEntryDetail);
  }
  legislativeCatalogStore.updateUnpackedListWithParents();
  // return row
  return legalEntryDetail;
};

const downgradeRowToLightElement = async (row) => {
  // Replace the parent row in data and downgrade it to a "light entry"
  const index = legislativeCatalogStore.unpackedListWithParents.findIndex(
    (item) => item.id === row.id
  );
  const entry = legislativeCatalogStore.unpackedListWithParents[index];
  if (entry.isParent || entry.isGrandParent) {
    legislativeCatalogStore.removeDetailedParentEntry(entry);
  }
  legislativeCatalogStore.unpackedListWithParents.splice(
    index,
    1,
    new LegislativeEntryLight(row)
  );
};

const handleExpandClick = async (row) => {
  tableData.value.toggleRowExpansion(row);
};

const handleExpand = async (row) => {
  if (isExpanded(row.id)) {
    // elem is expanded and will be closed.
    tableData.value.toggleRowExpansion(row, false);
    await downgradeRowToLightElement(row);
    collapse(row);
    return;
  }
  // elem is collapsed and will be expanded.
  await loadRowDetails(row);
  const detailedRow = legislativeCatalogStore.unpackedListWithParents.find(
    (entry) => entry.id === row.id
  );
  // as the api does not get the information from which table
  // an entry was opened, the api will always set isParent
  // to undefined for the detail view of an entry. This is fine
  // but to show the correct labels in the table we have to
  // inherit it here from the overview to the detail entry
  detailedRow.isParent = row.isParent;
  detailedRow.isGrandParent = row.isGrandParent;
  tableData.value.toggleRowExpansion(detailedRow, true);
  expand(row);
}

const handleEditClick = (payload) => {
  payload.row.lang = payload.lang;
  payload.row.isEditing = !payload.row.isEditing;
};

const handleTechEditClick = (payload) => {
  let row = payload.row;
  payload.row.language = payload.lang;
  const index = editingTechRows.value.indexOf(row.id);
  index > -1
    ? editingTechRows.value.splice(index, 1)
    : editingTechRows.value.push(row.id);
};

const cancelTechEdit = (row) => {
  const index = editingTechRows.value.indexOf(row.id);
  index > -1
    ? editingTechRows.value.splice(index, 1)
    : editingTechRows.value.push(row.id);
};

const stopPropagation = () => {
  event.stopPropagation();
};

const createTechnicalEntry = (row) => {
  legislativeCatalogStore.addTechnicalEntry(row.id);
};

const translateDelegation = (delegation) => {
  if (delegation === "plant_operator") {
    return t("delegationOptions.plantOperator");
  } else if (delegation) {
    return t(`delegationOptions.${delegation}`);
  } else {
    return "";
  }
};

const copy = async (id) => {
  // create the detail link out of the current row
  const link = directLinkPrefix.value + id + "/?lang=" + locale.value;
  if (!window.isSecureContext) {
    console.error("Can't copy link on http. Link is: ", link);
    return;
  }
  await navigator.clipboard.writeText(link);
  ElNotification({
    title: t("directLinkCopied"),
  });
};

const actionRetrievePublishedParentToCompare = (legislativeEntry) => {
  legislativeCatalogStore.setPublishedParentToCompare(legislativeEntry);
};

const getCorpParentLevelDisplay = () => {
  return corpStore.getCorpParentLevel(corpFilterStore.corpFilter);
};

const getI18nCorpParentLevelDisplay = () => {
  return t(getCorpParentLevelDisplay());
};

const getDiffersFromParentButtonClasses = (row) => {
  const classes = "align-middle border-2";
  if (row.differsFromParent) {
    return `${classes} border-rose-200 hover:border-rose-200`;
  }
  return classes;
};

// Mounted
onMounted(() => {
  legislativeCatalogStore.clearReviewId();
  legislativeCatalogStore.clearCorpBfs();
  legislativeCatalogStore.clearDetailId();
  legislativeCatalogStore.clearLegislativeEntryId();
  legislativeCatalogStore.clearNewest();

  let reviewId = route.params.reviewid;
  if (reviewId) {
    legislativeCatalogStore.setReviewId(reviewId);
    legislativeCatalogStore.retrieveLegislativeList();
  }
});

// Watchers
watch(
  () => legislativeCatalogStore.mustExpand,
  (ids) => {
    if (!ids.length) {
      return;
    }
    // let vue settle and render the DOM
    nextTick(() => {
      ids.forEach((id) => {
        const row = legislativeCatalogStore.unpackedListWithParents.find(
          (row) => row.id === id
        );
        tableData.value.toggleRowExpansion(row, true);
        expand(row);
      });
      actionClearMustExpand();
    });
  },
  { immediate: true }
);

watch(
  () => legislativeCatalogStore.mustCollapse,
  (ids) => {
    if (!ids.length) {
      return;
    }
    // let vue settle and render the DOM
    nextTick(() => {
      ids.forEach((id) => {
        const row = legislativeCatalogStore.unpackedListWithParents.find(
          (row) => row.id === id
        );
        tableData.value.toggleRowExpansion(row, false);
        collapse(row);
      });
      actionClearMustCollapse();
    });
  },
  { immediate: true }
);
</script>

<style lang="postcss" scoped>
.legalBaseLink {
  min-width: 8rem;
}
/*this solution is very hacky, thanks element-ui: i overlap the icon with another element to steal the native click*/
.el-table__expand-column:after {
  content: "";
  display: block;
  width: 50px;
  height: 100%;
  position: absolute;
  top: 0;
}
</style>
