/**
 * Not sure if this Should be a nested model or something
 * But until we have the need, I will implement it here
 * @param {Array} legalBases List of legal bases
 */
export function deserializeLegalBases(legalBases) {
  if (!legalBases) {
    return [];
  }
  return legalBases.map(lbase => {
    let cleaned = {};
    cleaned.isEditing = false;
    cleaned.abbr = lbase.abbr;
    cleaned.abbrDe = lbase.abbrDe;
    cleaned.abbrIt = lbase.abbrIt;
    cleaned.abbrFr = lbase.abbrFr;
    cleaned.abbrRm = lbase.abbrRm;
    cleaned.code = lbase.code;
    cleaned.codeDe = lbase.codeDe;
    cleaned.codeIt = lbase.codeIt;
    cleaned.codeFr = lbase.codeFr;
    cleaned.codeRm = lbase.codeRm;
    cleaned.example = lbase.example;
    cleaned.exampleDe = lbase.exampleDe;
    cleaned.exampleIt = lbase.exampleIt;
    cleaned.exampleFr = lbase.exampleFr;
    cleaned.exampleRm = lbase.exampleRm;
    cleaned.name = lbase.name;
    cleaned.nameDe = lbase.nameDe;
    cleaned.nameIt = lbase.nameIt;
    cleaned.nameFr = lbase.nameFr;
    cleaned.nameRm = lbase.nameRm;
    cleaned.url = lbase.url;
    cleaned.urlDe = lbase.urlDe;
    cleaned.urlIt = lbase.urlIt;
    cleaned.urlFr = lbase.urlFr;
    cleaned.urlRm = lbase.urlRm;
    cleaned.corp = lbase.corp;
    cleaned.id = lbase.id;
    cleaned.legislativeEntryLegalBasisId = lbase.legislativeEntryLegalBasisId;
    return cleaned;
  });
}
