<template>
  <Default class="view-contacts">
    <div class="container">
      <h1>{{ $t("views.contactsHeadline") }}</h1>
      <div class="mb-12" v-html="$t('contactInfo')"></div>
      <div class="grid">
        <div v-for="contact in contactStore.contactData" :key="contact.id">
          <el-card shadow="hover" class="h-full">
            <h3 v-if="contact.corpDisplay" class="mb-0 text-xl">
              {{ contact.corpDisplay }}
            </h3>
            <h4 v-if="contact.authority" class="mt-2 text-base font-light">
              {{ contact.authority }}
            </h4>
            <ul class="mt-8 leading-looser">
              <li class="flex gap-1">
                <span v-if="contact.firstName">{{ contact.firstName }} </span>
                <span v-if="contact.lastName">{{ contact.lastName }}</span>
              </li>
              <li v-if="contact.phone">
                <a :href="`tel:${contact.phone}`"> {{ contact.phone }}</a>
              </li>
              <li v-if="contact.email">
                <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
              </li>
            </ul>
          </el-card>
        </div>
      </div>
    </div>
  </Default>
</template>
<script setup>
import Default from "@/layouts/Default.vue";
import { useContactStore } from "@/store/Contacts";
import { onMounted } from "vue";
import { setLanguageParameter } from "@/composables/language.js";

const contactStore = useContactStore();

onMounted(() => {
  setLanguageParameter();
});
</script>
<style lang="postcss" scoped>
.grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}
</style>
