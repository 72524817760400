import { LegislativeEntryLight } from "@/models/LegislativeEntryLight.model";
import { TechnicalEntry } from "@/models/TechnicalEntry.model";
import { deserializeLegalBases } from "../serializers/LegalBases";
import { CustomField } from "./CustomField.model";
import { cloneDeep } from "lodash";

/**
 * Legislative entry base model.
 * @returns {object} JSON objects containing LegislativeEntryLight fields and:
 * customFields {array}
 * labelNumber {number}
 * historyReference {object}
 * isSplittable {boolean}
 * delegationChoices {object}
 * downloadService {boolean}
 * startDate {string}
 * endDate {string}
 * access {string}
 * oereb {boolean}
 * geoReference {boolean}
 * legalBases {array}
 * technicalEntries {array}
 */
export function LegislativeEntry(legislativeEntryData) {
  const legislativeEntryCopy = cloneDeep(legislativeEntryData);
  // Inherits properties from LegislativeEntryLight.
  LegislativeEntryLight.call(this, legislativeEntryData);

  if (this.isHistorised) {
    // This is an ugly bugfix. It seems that the legislativeCorp is not fully serialized in
    // the histodata, so we overtake the data from the entry instead of he historized data
    legislativeEntryData = legislativeEntryData.histoData;
  }

  // Extends LegislativeEntryLight
  this.customFieldValues = legislativeEntryData.customFields || []

  if (
    legislativeEntryData.customFields &&
    legislativeEntryData.customFields.length
  ) {
    this.customFields = legislativeEntryData.customFields.map(field => {
      field.values = legislativeEntryData.customFieldValues.find(
        d => d.customFieldId === field.id
      ) || {
        id: null,
        customFieldId: field.id,
        value: "",
        valueDe: "",
        valueFr: "",
        valueIt: "",
        valueRm: ""
      };
      return new CustomField(field);
    });
  } else {
    this.customFields = [];
  }
  this.labelNumber = legislativeEntryData.labelNumber || "";
  this.historyReference = legislativeEntryData.historyReference || null;
  this.isSplittable = legislativeEntryData.isSplittable || false;
  this.delegationChoices = legislativeEntryData.delegationChoices;
  this.downloadService = legislativeEntryData.downloadService || false;
  this.startDate = legislativeEntryData.startDate || null;
  this.endDate = legislativeEntryData.endDate || null;
  this.access = legislativeEntryData.access || "";
  this.oereb = legislativeEntryData.oereb || false;
  this.geoReference = legislativeEntryData.geoReference || false;
  this.legalBases = deserializeLegalBases(legislativeEntryData.legalBases);
  const techEntries = legislativeEntryCopy.technicalEntries || [];
  this.technicalEntries =
    techEntries.map(data => {
      return new TechnicalEntry(data);
    }) || [];
}

// Inherits proto from LegislativeEntryLight.
LegislativeEntry.prototype = Object.create(LegislativeEntryLight.prototype);
