import {
  getParentTechnical,
  getTechnicals
} from "../../store/legislativeCatalogUtils";
import { LANG_SUFFIXES } from "../../store/Language";

/** Critical legislative key-value properties */
export const legislativeSimpleProps = [
  "access",
  "oereb",
  "downloadService",
  "geoReference"
];

/** Has changes (checked in some properties) between legislative entry and the given parent. */
export const hasLegislativeChanges = (
  legislativeEntry,
  parentLegislativeEntry
) => {
  return legislativeSimpleProps.some(
    prop => legislativeEntry[prop] !== parentLegislativeEntry[prop]
  );
};

/**
 * @returns a list of "Changes" objects between technical entries of the given legislative entry and the
 * given parent legislative entry.
 * Changes are based on some predetermined field.
 * A Change object is an object as defined in the createChangeItem function.
 */
export const getTechnicalWithChanges = (
  legislativeEntry,
  parentLegislativeEntry,
  language
) => {
  return getTechnicals(legislativeEntry)
    .map(techEntry => {
      const parentTechEntry = getParentTechnical(
        parentLegislativeEntry,
        techEntry
      );
      if (!parentTechEntry) {
        return null;
      }
      const langSuffix = LANG_SUFFIXES.find(
        lang => lang.toLowerCase() === language.toLowerCase()
      );
      const changes = getTechnicalChanges(
        techEntry,
        parentTechEntry,
        langSuffix
      );
      return changes.length ? { technicalEntry: techEntry, changes } : null;
    })
    .filter(changeItem => changeItem !== null);
};

/**
 * @returns A "Change" object with {{
 * columnName: string. The name of the column (to display the title of the change).
 * textValue: string. The text to display to explain the technical entry value.
 * parentTextValue: string. The text to display to explain the parent technical entry value.
 * restoreFromParentFn: (TechnicalEntry) => void. The function to apply to update the Technical entry with the parent Technical Entry value.
 * isSoftChange: boolean. Is it a not mandatory column.
 * needTranslation: boolean. Should the textValue and parentTextValue be translated.
 * checked: boolean. Always true, to let the user check or not this change to apply it ot not.
 * }}
 */
const createChangeItem = (
  columnName,
  textValue,
  parentTextValue,
  restoreFromParentFn,
  isSoftChange,
  needTranslation = false
) => {
  return {
    columnName,
    textValue,
    parentTextValue,
    restoreFromParentFn,
    isSoftChange,
    needTranslation,
    checked: true
  };
};

/**
 * Create a "Change" on a multi languages flat field.
 */
const createMultiLangTechnicalChange = (
  techEntry,
  parentTechEntry,
  baseColumn,
  isSoftChange,
  langSuffix
) => {
  const hasChanges = objectHasDiff(techEntry, parentTechEntry, [baseColumn]);
  if (!hasChanges) {
    return null;
  }
  const displayColumn = `${baseColumn}${langSuffix}`;
  return createChangeItem(
    baseColumn,
    techEntry[baseColumn] || "-",
    parentTechEntry[displayColumn] || "-",
    technicalEntry =>
      LANG_SUFFIXES.forEach(lang => {
        const column = `${baseColumn}${lang}`;
        technicalEntry[column] = parentTechEntry[column];
      }),
    isSoftChange
  );
};

/**
 * @returns true if the given item1 differs from the given item2 on at least one of the given compareColumn.
 * False otherwise.
 * Check all languages if a column looks multi languages.
 */
const objectHasDiff = (item1, item2, compareColumns) => {
  return compareColumns.some(baseColumn => {
    const multiLang = Object.keys(item1).includes(
      `${baseColumn}${LANG_SUFFIXES[0]}`
    );
    const columns = multiLang
      ? LANG_SUFFIXES.map(lang => `${baseColumn}${lang}`)
      : [baseColumn];
    return columns.some(column => item1[column] !== item2[column]);
  });
};

/**
 * Compares the entry based on the given baseColumn.
 * @returns True if the first list of items have differences regarding the second.
 */
const hasMultiItemsTechnicalChange = (
  techEntry,
  parentTechEntry,
  baseColumn,
  compareColumns
) => {
  const items1 = techEntry[baseColumn] || [];
  const items2 = [...parentTechEntry[baseColumn]] || [];
  // Not the same length ? Create a change item.
  if (items1.length !== items2.length) {
    return true;
  }
  // Check for deep diff
  items1.forEach(item1 => {
    const matchIndex = items2.findIndex(item2 => {
      return !objectHasDiff(item1, item2, compareColumns);
    });
    if (matchIndex >= 0) {
      items2.splice(matchIndex, 1);
    }
  });
  // Still an item1 in the item2 List ? That means there is a difference. Create a change.
  return items2.length;
};

/**
 * @returns A list of technical "Changes" (see createChangeItem).
 */
export const getTechnicalChanges = (techEntry, parentTechEntry, langSuffix) => {
  const changes = [];
  if (techEntry.geoCategory.id !== parentTechEntry.geoCategory.id) {
    changes.push(
      createChangeItem(
        "geocategory",
        `${techEntry.geoCategory.notation || ""} - ${techEntry.geoCategory
          .name || ""}`,
        `${parentTechEntry.geoCategory.notation || ""} - ${parentTechEntry
          .geoCategory.name || ""}`,
        technicalEntry =>
          (technicalEntry.geoCategoryId = parentTechEntry.geoCategory.id),
        false
      )
    );
  }
  const descriptionChange = createMultiLangTechnicalChange(
    techEntry,
    parentTechEntry,
    "description",
    true,
    langSuffix
  );
  if (descriptionChange) {
    changes.push(descriptionChange);
  }
  const dataModelUrlChange = createMultiLangTechnicalChange(
    techEntry,
    parentTechEntry,
    "dataModelUrl",
    true,
    langSuffix
  );
  if (dataModelUrlChange) {
    changes.push(dataModelUrlChange);
  }
  const modelDocumentationUrlChange = createMultiLangTechnicalChange(
    techEntry,
    parentTechEntry,
    "modelDocumentationUrl",
    true,
    langSuffix
  );
  if (modelDocumentationUrlChange) {
    changes.push(modelDocumentationUrlChange);
  }
  const metadataUrlsChange = hasMultiItemsTechnicalChange(
    techEntry,
    parentTechEntry,
    "metadataUrls",
    ["url", "urlLabel"],
    true
  );
  if (metadataUrlsChange) {
    changes.push(
      createChangeItem(
        "metaData",
        "metadataUrlsDecoupled",
        "metadataUrlsFix",
        technicalEntry => {
          technicalEntry.metadataUrls = parentTechEntry.metadataUrls;
        },
        true,
        true
      )
    );
  }
  return changes;
};
