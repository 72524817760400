import { map } from "lodash";
export function serializerLegalBases(legalBases) {
  const mapped = map(legalBases, value => {
    return {
      id: value.id,
      // this isn't needed in my opinion, but right now the backend crashes
      legislativeEntryLegalBasisId: value.legislativeEntryLegalBasisId || null,
      example: value.example || "",
      exampleDe: value.exampleDe || "",
      exampleFr: value.exampleFr || "",
      exampleIt: value.exampleIt || "",
      exampleRm: value.exampleRm || ""
    };
  });
  return mapped;
}
